.formcreateprod_fr-index-index,
.formcreateprod-index-index {
    .sub-h1 {
        display: block;
        font-size: 32px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 42px;
        @media (max-width: $screen__m) {
            font-size: 22px;
            line-height: 32px;
        }
    }

    h1 {
        font-size: 46px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 56px;

        @media (max-width: $screen__m) {
            font-size: 30px;
            line-height: 46px;
        }
    }

    .page-title {
        font-weight: 500;
    }

    .block-info {
        padding: 50px 0;
        .cadre {
            border: 1px solid #ccc;
            padding: 33px 28px;
            height: 50rem;

            .icon {
                font-family: 'Font Awesome 5 Pro';
                font-weight: 400;
                line-height: normal;
                color: #00bb31;
                font-size: 6rem;

                &.user-plus {
                    &:before {
                        content: "\f234";
                    }
                }

                &.info {
                    font-size: 7rem;
                    &:before {
                        content: "\f30f";
                    }
                }
            }

            @media (max-width: $screen__m) {
                height: auto;
                margin: 10px 0;
            }
        }

        .btn-go-form {
            background-image: none;
            background: #00bb31;
            border: 1px solid #00bb31;
            color: #fff;
            cursor: pointer;
            display: inline-block;
            font-family: 'Font Awesome 5 Pro';
            font-weight: normal;
            padding: 9px 9px;
            border-radius: 10px;
            height: 50px;
            width: 110px;
            font-size: 2.5rem;
            box-sizing: border-box;
            vertical-align: middle;
            text-align: center;

            &:before{
                content: "\f063";
                top: 50%;
                transform: translateY(-50%);
                position: relative;
                display: block;
            }
            &:hover {
                text-decoration: none;
            }
        }

        .line {
            box-sizing: border-box;
            height: 1px;
            max-width: 318px;
            border: 1px solid #D3D3D3;
            display: block;
            margin-bottom: 40px;

        }

        h2 {
            font-weight: 500;
            letter-spacing: 0;
            line-height: 42px;
            @media (max-width: $screen__m) {
                font-size: 22px;
                line-height: 32px;
            }
        }

        h3 {
            font-weight: 500;
            letter-spacing: 0;
            line-height: 30px;
            margin-bottom: 40px;
            @media (max-width: $screen__m) {
                font-size: 12px;
                line-height: 20px;
            }
        }

        .contact {
            .icon {
                display: inline-block;
                font-size: 2.5rem;
                margin-right: 15px;
                vertical-align: middle;

                &.phone:before {
                    content: "\f879";
                }
                &.email:before {
                    content: "\f0e0";
                }
            }
            a {
                color: #1B1B1B;
                font-size: 22px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 30px;
                vertical-align: middle;
            }
        }

    }
}
