.home{
    &__search{
        background-color: $secondary__color;
        padding: $indent__l 0px;
        &__container{
            p{
                font-family: $heading__font-family__base;
                font-weight: $font-weight__heavier;
                color: $color-white;
                @include lib-font-size(20);
                width: 75%;
            }
        }
        &__form{
            display:grid;
            row-gap: $indent__s;
        }
    }
}
select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtYW5nbGUtZG93biBmYS13LTEwIGZhLTJ4Ij48cGF0aCBmaWxsPSIjMjVCMjRBIiBkPSJNMTQzIDM1Mi4zTDcgMjE2LjNjLTkuNC05LjQtOS40LTI0LjYgMC0zMy45bDIyLjYtMjIuNmM5LjQtOS40IDI0LjYtOS40IDMzLjkgMGw5Ni40IDk2LjQgOTYuNC05Ni40YzkuNC05LjQgMjQuNi05LjQgMzMuOSAwbDIyLjYgMjIuNmM5LjQgOS40IDkuNCAyNC42IDAgMzMuOWwtMTM2IDEzNmMtOS4yIDkuNC0yNC40IDkuNC0zMy44IDB6IiBjbGFzcz0iIj48L3BhdGg+PC9zdmc+);
    background-repeat: no-repeat;
    background-position: center right 2rem;
    background-size: 1rem;
    color: $primary__color;
}
.toolbar.toolbar-products{
    select.limiter-options{
        background-position: center right 0rem;
        margin: 0 $indent__s 0 $indent__xs;
        padding: 0 $indent__m 0 $indent__xs;
    }
}

//
//  tablette
//  _____________________________________________

@include min-screen($screen__m) {
    .home{
        &__search{
            &__container{
                p{
                    width: 90%;
                    margin-bottom: 0;
                    @include lib-font-size(22);
                }
            }
            &__form{
                row-gap: 0;
                column-gap: $indent__s;
                grid-template-columns: 1fr 1fr 1fr;
                select{
                    margin-bottom: 0;
                    margin-right:$indent__s;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    .home{
        &__search{
            padding: $indent__xl 0px;
            &__container{
                display: grid;
                grid-template-columns: 25% 75%;
                column-gap: $indent__m;
                align-items: center;
            }
            &__form{
                column-gap: $indent__m;
                select , button{
                    height: 6rem;
                    @include lib-font-size(18);
                }
            }
        }
    }


}

// form global
form {
    .actions-toolbar{
        margin-left: 0px !important;
    }
    .fieldset>.field:not(.choice)>.control{
        width: 100%;
    }

    .fieldset{
        input{
            border-bottom: 1px solid $color-gray19 ;
            border-radius: 0px;
            color: $color-black;
            @include lib-font-size(18);
            &::placeholder{
                color: $color-gray;
                @include lib-font-size(18);
                font-family: $font-family-name__base;
                font-weight: 100;
            }
            &:focus{
                border-bottom: 1px solid $secondary__color;
                background: $color-gray95;
            }
        }
        >.field.choice{
            &:before{
                width: 0;
            }
        }
        .field:not(.choice)>.label {
            text-align: left;
            width: 100%;
        }
        select{
            border: 1px solid $color-gray19;
        }
    }


}

// action Button
.actions-toolbar{
    .primary{
        .action{
            padding: $indent__s  $indent__l !important;
            height: 5rem;
            margin-right: 30px;
        }
    }
    .secondary{
        a.action{
            margin-top: 0;
            background: $color-white;
            border: 2px solid $secondary__color;
            border-radius: $indent__s;
            color: $color-black;
            cursor: pointer;
            display: inline-block;
            font-family: "Montserrat",sans-serif;
            font-weight: 700;
            padding: $indent__s  $indent__l !important;
            height: $indent__xl;
            @include lib-font-size(18);
            box-sizing: border-box;
            vertical-align: middle;
            height: 5rem;

            &:hover{
                text-decoration: none;
            }
        }
    }

}
//form login
.customer-account-login{
    .login-container{
        .block-customer-login{
            border-right: 1px solid $color-gray76;
            @include max-screen($screen__m) {
                border-right: none;
            }
        }
        .block-title{
            @include lib-font-size(20);
            border-bottom: none;
        }
    }

    .form{
        &-login{
            .field{
                width: 90%;
                .secondary{
                    text-align: right;
                    a{
                        color:$color-black;
                        @include lib-font-size(12);
                        text-decoration: underline;
                    }
                }
                &.password{
                    margin: 0px;
                }
            }

            .fieldset{
                &:after{
                @include lib-font-size(12);
                color: $color-gray34;
                }
            }
        }
    }
}

// Form Account Create
.customer-account-create{
    .form.create.account{
        width: 60%;
        @include max-screen($screen__m) {
            width: 100%;
        }
        .field.choice:not(.newsletter){
            display: none;
        }
        .fieldset>.field.choice::before{
            float: none;
        }
        .fieldset{
            &.create.info{
                display: flex;
                margin: 0;
                @include max-screen($screen__m) {
                    display: block;
                }

                .field-name-firstname,
                .field-name-lastname{
                    width: 95%;
                    @include max-screen($screen__m) {
                        width: 100%;
                    }
                }
                .field-name-firstname{
                    .control{
                        width: 95%;
                        @include max-screen($screen__m) {
                            width: 100%;
                        }
                    }
                }

            }
        }
    }
}

// Form page contact
.contact-index-index,
.formreturnrequest-index-index{

    h1.page-title .base{
        font-weight: 600;
    }

    h2{
        font-size: 3.2rem;
        font-weight: 600;
    }

    .block-info{
        .cadre{
            border: 1px solid $color-gray80;
            padding: 33px 28px;
            height: 32rem;

            @include max-screen($screen__m) {
                margin: 1rem 0;
            }

            .icon{
                font-family: 'Font Awesome 5 Pro';
                font-weight: 400;
                font-size: 20px;
                line-height: normal;
                color: $secondary__color;
                font-size: 6rem;
                &.phone:before{
                    content: "\f095";
                }
                &.map:before{
                    content: "\f3c5";
                }
                &.clock:before{
                    content: "\f017";
                }
            }

            h2{
                font-size: 2.2rem;
                border-bottom: 1px solid $color-gray80;
                padding-bottom: 2rem;
                font-weight: 600;
            }
            p{
               font-size: 1.8rem;
               font-family: $font-family-name__base;
               font-weight: 600;

               span{
                   color: $secondary__color;
               }
            }

            a.phone,
            a.map{
                color: $secondary__color;
                text-decoration: underline;

                &:hover{
                    text-decoration: none;
                }

            }
        }
    }

    form{

        &.contact{
            width: 80%;
            @include max-screen($screen__m) {
                width: 100%;
            }

            .fieldset{
                margin: none;
                margin-right: -15px;
                margin-left: -15px;

                >.field{
                    margin: 0 0 4rem;
                }
            }

            h2{
                margin-top: 6rem;
                margin-bottom: 5rem;
            }

            .bloc_flex{
                display: flex;
                flex-wrap: wrap;
            }
            .field{

                &.phone{
                    padding-left: 0;
                    @include max-screen($screen__m) {
                        padding: 0;
                    }

                    .control{
                        padding-left: 0;
                    }
                }
                &.extension{
                    padding-right: 0;
                    @include max-screen($screen__m) {
                        padding: 2rem 0 0 0;
                    }

                    .control{
                        padding-right: 0;
                    }
                }

                &.sujet{
                    margin: 0 0 2rem;
                }

                textarea{
                    border: 1px solid $color-gray19;
                    border-radius: 0;

                    &::placeholder{
                        color: $color-gray;
                    }
                }

            }
        }

        &.returnr{

            @include max-screen($screen__m) {

                .fieldset{
                    margin: none;
                    margin-right: -15px;
                    margin-left: -15px;

                    >.field{
                        margin: 0 0 4rem;
                    }
                }

             }


        }

    }

}

