.minicart-items {
    .product-item-details {

        .product-item-name a {
            color: $primary__color;
            font-weight: bold;
        }

    }

}

.cart-container .form-cart {
    @include max-screen($screen__m) {
        width: 100%;
    }
    @include max-screen($screen__l) {
        width: 100%;
    }
}

.cart {
    &-view-container-summary {
        float: right;
        position: relative;
        width: 23%;
        @include max-screen($screen__m) {
            width: 100%;
        }
        @include max-screen($screen__l) {
            width: 100%;
        }

        .block.discount {
            border: 1px solid $color-gray76;
            display: inline-block;
            background: $color-white;
            margin-bottom: 26.4px;
            padding: 20px;

            .title {
                font-weight: bold;
                font-family: $font-family__montserrat;
                @include lib-font-size(18);
            }

            label {
                a {
                    font-family: $font-family__montserrat;
                    display: block;
                    text-decoration: underline;
                }

                span {
                    font-family: $font-family-name__base;
                    font-weight: normal;
                }
            }

            .control {
                display: flex;
                padding-top: 20px;

                input[type="text"] {
                    border: 1px solid $color-gray-middle4;
                    border-radius: 10px 0 0 10px;
                    width: 50%;
                    height: 5rem;

                    &::placeholder {
                        font-size: 14px !important;
                    }
                }

                button.primary {
                    padding: 10px 20px !important;
                    height: 5rem;
                    border-radius: 0 10px 10px 0;
                }

                .mage-error {
                    position: absolute;
                    display: contents;
                    bottom: 0;
                }
            }


        }
    }

    &-summary {
        width: 100%;
        border: 1px solid $color-gray76;

        select {
            background-position: center right 1rem;
            padding: $indent__s $indent__xl $indent__xs $indent__s;
        }

        .title {
            border: 0;
            margin-bottom: 2rem;
            font-weight: bold;
            font-family: $font-family__montserrat;
            @include lib-font-size(18);
        }

        .totals .mark strong {
            font-family: $font-family-name__base;
            font-weight: bold;
        }

        form#shipping-zip-form {
            .field[name="shippingAddress.country_id"] {
                display: none;
            }
        }

        .checkout-methods-items {
            .action.primary.checkout {
                @include lib-font-size(16);
            }
        }
    }

    &.actions {
        .action {

            &.continue {
                display: none;
            }

            &.update {
                @include max-screen($screen__m) {
                    width: 100%;
                }

                &:hover {
                    background: $color-white;
                    border: 1px solid $color-black;
                    color: $color-black;
                }

            }
        }
    }


    &.main.actions {
        .action.update {
            background: $color-white;
            border: 2px solid $secondary__color;
            color: $color-black;
            height: 50px;
            padding: 10px 30px;

            &:before {
                content: none;
            }
        }
    }

}

.cart.main.actions .action.update:hover {
    background: $secondary__color;
    color: $color-white;
}

.cart.table-wrapper .items thead + .item {
    border-top: 0;
}

.checkout-cart-index {
    .breadcrumbs,
    .page-title-wrapper {
        max-width: 100%;
        background-color: $primary__color;
        padding: 3.3rem;
        margin: 0;

        .items {
            box-sizing: border-box;
            margin: auto;
            max-width: 1280px;
            padding-left: 2rem;
            padding-right: 2rem;
            width: auto;
            color: $color-white;
            @include lib-font-size(16);

            @include max-screen($screen__m) {
                margin: 0 -20px;
                padding-left: 0;
                padding-right: 0;
            }

            a {
                color: $color-white;
            }
        }

        .page-title {
            box-sizing: border-box;
            margin: auto;
            max-width: 1280px;
            padding-left: 2rem;
            padding-right: 2rem;
            color: $color-white;
            @include lib-font-size(46);
            font-family: $font-family__montserrat;
            font-weight: bold;

            @include max-screen($screen__m) {
                margin: 0 -20px;
            }

        }
    }


    .cms-block-cart {
        background-color: $secondary__color;
        padding: 3.3rem 0;
        //margin-bottom: 4rem;
        @include max-screen($screen__m) {
            padding: 0.5rem 0;;
        }

        .content {
            box-sizing: border-box;
            margin: auto;
            max-width: 1280px;
            padding-left: 2rem;
            padding-right: 2rem;
            color: $color-white;

            @include max-screen($screen__m) {
                margin: 0 15px;
            }

            h2 {
                margin: 0;
                margin-bottom: 1.5rem;
            }

            .info-delivery {
                span {
                    padding: 1rem 0 1rem 1rem;
                    position: absolute;
                }

                &:before {
                    content: "\f06a";
                    font-size: 3rem;
                    color: $color-black;
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 700;
                    padding-right: 5px;
                }
            }

            a.btn-primary {
                @include lib-button-primary();
                background: $color-black;
                border-radius: 1rem;
                padding: 15px 50px;
                height: 5.5rem;
                margin-top: 2rem;
                @include max-screen($screen__m) {
                    padding: 15px 30px;
                    width: 100%;
                    text-align: center;
                }

                @include max-screen($screen__l) {
                    padding: 15px;
                    width: 100%;
                    text-align: center;
                    font-size: 1.4rem;
                }



                &:hover {
                    border: 2px solid $color-white;
                    background: $secondary__color;
                    color: $color-white;
                    text-decoration: none;
                }
            }
        }

        &__footer {
            margin: 5rem 0 0;

            .content {
                text-align: center;

                p {
                    margin-bottom: 0;
                }
            }

        }

    }

    .cms-block-cart-title {
        .content {
            box-sizing: border-box;
            margin: auto;
            max-width: 1280px;
            color: $primary__color;
            padding: 0;

            @include max-screen($screen__m) {
                margin: 0 15px;
            }

            h2 {
                @include lib-font-size(22);
                font-weight: 500;
                letter-spacing: 1px;

            }


        }
    }


    .btn-help {
        @include lib-font-size(18);
        padding: 22px 30px 20px;
        text-transform: uppercase;
        text-align: right;

        .fa-question-circle {
            @include lib-font-size(18);
            font-weight: 100;
        }

        .tooltip.content.help {
            text-transform: initial;
            border-radius: 5px;
            border: 2px solid $secondary__color;
            margin-top: -15px;
            left: -30px;

            @include max-screen($screen__m) {
                margin-top: -5px;
                left: 0;
            }

            &:after {
                left: 267px;
            }

            &:before {
                left: 267px;
                border-bottom-color: $secondary__color;
            }
        }

        @include max-screen($screen__m) {
            padding: 25px 5px 10px;
            text-align: left;
            order: -1;
            .tooltip.content.help {
                &:before {
                    left: 15px;
                    margin-left: 0;
                }

                &:after {
                    left: 16px;
                    margin-left: 0;
                }
            }
        }
    }


    .block.crosssell {

        padding-top: $indent__xl;
        margin-top: $indent__xl;


        .slick-track {
            margin-left: -42px;
            @include max-screen($screen__m) {
                margin-left: 1px;
            }
        }


        h2 {
            padding-bottom: $indent__m;
            font-weight: 500;
        }

        .product-item-slider {
            padding: 0 $indent__m;
            border: 1px solid $color-white;

            &:hover {
                border: 1px solid $secondary__color;
            }
        }

        .name {
            @include lib-font-size(16);
            margin-bottom: 0;
            color: $primary__color;
            font-weight: bold;
            font-family: $font-family-name__base;
        }

        button {
            &.slick-arrow {
                top: 30%;
            }
        }

        .product {
            &.category {
                @include lib-font-size(12);
                color: $color-gray;
                margin-bottom: 1.2rem;
                font-family: $font-family-name__base;
                font-weight: bold;
            }

            &.sous_title {
                @include lib-font-size(14);
                color: $primary__color;
                font-weight: lighter;
                font-family: $font-family-name__base;
            }

            &-item {
                @media (max-width: $screen__xs) {
                    margin: 0px !important;
                    margin-left: 22% !important;
                    max-width: 100%;

                    .product-image-container {
                        width: 210px !important;

                        img {
                            width: 100%;
                        }
                    }


                }
            }
        }
    }

    .cart.item {
        .price-old {
            text-decoration: line-through;
            font-weight: 600;
            color: $color-gray40;
        }
    }
}


#shopping-cart-table {


    .cart {

        .item-actions {
            .actions-toolbar {
                text-align: right;
                margin-bottom: 30px;
            }

            td {
                padding: 0px 11px 11px;
            }
        }

        .item-info {
            .col.item {
                padding: 0px 10px;
            }
        }
    }

    .action {
        background: $color-white;
        border: 0;
        color: $color-black;
        @include lib-font-size(14);
        text-decoration: underline;
        font-weight: 500;
        padding: 5px 15px;
        height: 23px;
        border-radius: 0;
        margin-bottom: 0;
        margin-right: 0;

        &-towishlist,
        &-edit {
            border-right: 1px solid $color-gray80;

            &:hover {
                background: $color-white;
                border: 0;
                color: $color-black;
                border-right: 1px solid $color-gray80;
            }
        }
    }

    input[type="number"] {
        border: 1px solid $color-gray76;
        padding: 0px 20px;
        width: 80px;
    }

    thead {
        .col {
            @include lib-font-size(14);
            font-weight: 500;
            font-family: $font-family__montserrat;

            &.item {
                width: 55%;
            }

            &.price,
            &.qty,
            &.subtotal {
                width: 15%;
            }
        }

        @include max-screen($screen__m) {
            display: none;
        }
    }

    tbody {
        .col {
            &.item {
                width: 100%;
                position: relative;
                @include max-screen($screen__m) {
                    display: flex;
                    .product-item-photo {
                        max-width: 140px;
                        padding: 10px;
                        position: initial;
                    }

                }
            }

            &.qty,
            &.price,
            &.subtotal {
                width: 10%;
                @include max-screen($screen__m) {
                    width: 33%;
                }
            }

            @include max-screen($screen__m) {
                &.qty:before,
                &.price:before,
                &.subtotal:before {
                    font-weight: normal;
                }
            }

        }

        .item-actions {
            position: absolute;
            bottom: 25px;
            right: 0px;
            @include max-screen($screen__m) {
                position: initial;
                .actions-toolbar {
                    margin-top: 10px;
                    margin-bottom: 5px !important;
                    text-align: left !important;
                }
            }
        }

        .product-image-container {
            width: 210px !important;
            height: auto;
        }

        .product-item-details {
            .category {
                display: none;
            }

            .sous_title,
            .suk,
            .item-options {
                @include lib-font-size(16);
                color: $primary__color;
                font-family: $font-family-name__base;
                padding: 5px 0;
            }

            .item-options {
                margin: 0;

                dt {
                    font-weight: normal;
                }
            }

            .product-item-name a {
                @include lib-font-size(22);
                color: $primary__color;
                font-family: $font-family__montserrat;
                font-weight: 500;
            }
        }
    }

}

// Mobile
@include max-screen($screen__m) {
    .cart-container {
        display: grid;

        .cart-summary {
            order: 2;
        }
    }

    .checkout.methods.items.checkout-methods-items {
        position: fixed;
        bottom: -2px;
        width: 100%;
        z-index: 99;
        background-color: $color-white;
        padding: 20px;
        left: 0;
        box-shadow: 4px 1px 6px $color-gray64;
    }
}


