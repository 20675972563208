.page-print{
    .order-details-items{
        .actions-toolbar{
            display: none;
        }   
    }
    .header{
        &__shipping{
            display: none;
        }
        &__menu{
            background-color: transparent;
        }
    }
}