//
//  Buttons variables
//  _____________________________________________

//
//  Buttons
//  ---------------------------------------------

//  Font style
$button__font-family                          : $heading__font-family__base !default;
$button__font-size                            : $font-size__base !default;
$button__font-weight                          : $font-weight__bold !default;
$button__line-height                          : $font-size__base + 2 !default;
$button__margin                               : 0 !default;
$button__padding                              : 9px 9px !default; // is set up to false when buttons don't depend on side paddings or have fixed width
$button__width                                : false !default; // is set up to false when buttons depend on side paddings or to fixed value when needed
$button__height                               : 40px !default; 

//  Display settings
$button__display                              : inline-block !default;
$button__cursor                               : pointer !default;
$button__border-radius                        : 10px !default;

$button__disabled__opacity                    : 0.5 !default;

//  Default                                   = secondary button
$button__color                                : $color-white !default;
$button__background                           : $color-black !default;
$button__border                               : 1px solid $color-black !default;
$button__gradient-color-start                 : false !default;
$button__gradient-color-end                   : false !default;

$button__hover__color                         : $color-white !default;
$button__hover__background                    : transparent !default;
$button__hover__border                        : 1px solid $color-white !default;
$button__hover__gradient-color-start          : false !default;
$button__hover__gradient-color-end            : false !default;

$button__active__color                        : $button__hover__color !default;
$button__active__background                   : $button__hover__background !default;
$button__active__border                       : 3px solid $color-white !default;
$button__active__gradient-color-start         : false !default;
$button__active__gradient-color-end           : false !default;

//  Primary button
$button-primary__line-height                  : false !default;
$button-primary__width                        : false !default;
$button-primary__height                       : $button__height !default;
$button-primary__margin                       : false !default;
$button-primary__padding                      : $button__padding !default;
$button-primary__gradient                     : false !default;
$button-primary__gradient-direction           : false !default;

$button-primary__background                   : $secondary__color !default;
$button-primary__border                       : 1px solid $secondary__color !default;
$button-primary__color                        : $color-white !default;
$button-primary__gradient-color-start         : false !default;
$button-primary__gradient-color-end           : false !default;

$button-primary__hover__background            : transparent !default;
$button-primary__hover__border                : 1px solid $secondary__color !default;
$button-primary__hover__color                 : $primary__color !default;
$button-primary__hover__gradient-color-start  : false !default;
$button-primary__hover__gradient-color-end    : false !default;

$button-primary__active__background           : $button-primary__hover__background !default;
$button-primary__active__border               : 3px solid $secondary__color !default;
$button-primary__active__color                : $button-primary__color !default;
$button-primary__active__gradient-color-start : false !default;
$button-primary__active__gradient-color-end   : false !default;

//  Gradient button
$button__gradient                             : false !default; // [true|false] - button has a gradient background
$button__gradient-direction                   : false !default; // button gradient direction if button has a gradient background

//  Secondary button
$button-secondary__line-height                  : false !default;
$button-secondary__width                        : false !default;
$button-secondary__height                       : $button__height !default;
$button-secondary__margin                       : false !default;
$button-secondary__padding                      : $button__padding !default;
$button-secondary__gradient                     : false !default;
$button-secondary__gradient-direction           : false !default;

$button-secondary__background                   : false !default;
$button-secondary__border                       : 1px solid $secondary__color !default;
$button-secondary__color                        : $color-white !default;
$button-secondary__gradient-color-start         : false !default;
$button-secondary__gradient-color-end           : false !default;

$button-secondary__hover__background            : $secondary__color!default;
$button-secondary__hover__border                : 1px solid $secondary__color !default;
$button-secondary__hover__color                 : $color-white !default;
$button-secondary__hover__gradient-color-start  : false !default;
$button-secondary__hover__gradient-color-end    : false !default;

$button-secondary__active__background           : $button-secondary__hover__background !default;
$button-secondary__active__border               : $button-secondary__hover__border !default;
$button-secondary__active__color                : $button-secondary__color !default;
$button-secondary__active__gradient-color-start : false !default;
$button-secondary__active__gradient-color-end   : false !default;

//  Button with icon
$button-icon__use                             : false !default;
$button-icon__content                         : $icon-settings !default;
$button-icon__font                            : $icon-font !default;
$button-icon__font-size                       : 22px !default;
$button-icon__line-height                     : $button-icon__font-size !default;
$button-icon__color                           : inherit !default;
$button-icon__margin                          : 0 !default;
$button-icon__vertical-align                  : top !default;
$button-icon__position                        : $icon__position !default;
$button-icon__text-hide                       : false !default;

$button-icon__hover__font-color               : inherit !default;
$button-icon__active__font-color              : inherit !default;

//  Large button
$button__font-size__l                         : $font-size__l !default;
$button__line-height__l                       : $font-size__l + 4 !default;
$button__padding__l                           : 14px 17px !default;

//  Small button
$button__font-size__s                         : 11px !default;
$button__line-height__s                       : $button__font-size__s + 1 !default;
$button__padding__s                           : $indent__xs 8px !default;
