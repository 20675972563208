// block in page home
.home{
    // block promo
    &__block-promo {

        &__row {
            display: grid;
            @include max-screen($screen__s) {
                display: block;
                margin-left: -20px;
                margin-right: -20px;
            }
        }

        &__item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                margin-top:-1em;
            }
            img{
                @include max-screen($screen__s) {
                    //filter: grayscale(1);
                    filter: blur(1px);
                    -webkit-filter: blur(1px);
                    opacity: 0.9;
                }
            }
            .text {
              position: absolute;
              width: 80%;
              @include max-screen($screen__s) {
                width: 100%;
                padding: 0px 25px;
                z-index: 1;
              }
              text-align: center;
                h2{
                    color:$color-white;
                    margin-bottom: $indent__s;
                    @include lib-font-size(32);
                    margin-bottom: 2rem;
                }
                p{
                    font-weight: $font-weight__bold;
                    color:$color-white;
                    margin-bottom: 5rem;
                    @include lib-font-size(18);
                    text-shadow: 1px 1px 1px $color-black;
                    a{
                        @include lib-button-secondary();
                        @include lib-css(border-radius, $button__border-radius);

                        background: transparent;
                        border: 1px solid $secondary__color;
                        padding: $indent__s  $indent__l;
                        height: 5rem;
                        @include lib-font-size(18);
                        letter-spacing: 1px;

                        &:hover{
                            text-decoration: none;
                        }

                    }
                    button{
                        background: transparent;
                        border: 1px solid $secondary__color;
                        padding: $indent__s  $indent__l;
                        height: 5rem;
                        @include lib-font-size(18);
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }

    // block home newlwtter
    &__newsletter{
        background-color: $secondary__color;
        padding: $indent__l 0;
        .content{
            display: grid;
            row-gap: $indent__m;
            .newsletter{
                &__content{
                    h2{
                        color: $color-white;
                        margin: 1.5rem 0 1rem;
                    }
                    p{
                        color: $color-white;
                        font-weight: 600;
                    }
                }
                &__form{
                    form{
                        display: grid;
                        grid-template-columns: 11fr 1fr;
                        input{
                            background-color: transparent;
                            border-radius: 0;
                            border-bottom: 1px solid $color-white;
                            color: $color-white;
                            &::placeholder{
                                color:$color-white;
                            }
                        }
                        button{
                            border:0;
                            border-radius: 0;
                            border-bottom: 1px solid $color-white;
                            background-color: transparent;
                            background-image: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhciIgZGF0YS1pY29uPSJhbmdsZS1yaWdodCIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWFuZ2xlLXJpZ2h0IGZhLXctNiIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOTIgNTEyIj48cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMTg3LjggMjY0LjVMNDEgNDEyLjVjLTQuNyA0LjctMTIuMyA0LjctMTcgMEw0LjIgMzkyLjdjLTQuNy00LjctNC43LTEyLjMgMC0xN0wxMjIuNyAyNTYgNC4yIDEzNi4zYy00LjctNC43LTQuNy0xMi4zIDAtMTdMMjQgOTkuNWM0LjctNC43IDEyLjMtNC43IDE3IDBsMTQ2LjggMTQ4YzQuNyA0LjcgNC43IDEyLjMgMCAxN3oiPjwvcGF0aD48L3N2Zz4=);
                            background-repeat: no-repeat;
                            background-position: center right 2rem;
                            background-size: 1rem;
                        }
                    }
                }
            }
        }
    }
    // block Instagram
    &__block_instagram{
        margin:$indent__xl auto;
        display: none;
        &__header{
            text-align: center;
            h2{
                font-weight: bold;
            }
            p{
                font-weight: bold;
            }
        }
        &__picture{
            display: grid;
            grid-template-columns: 1fr;
            column-gap: $indent__s;
            row-gap: $indent__s;
            p{
                margin:0;
                &:nth-child(3){
                    grid-column: 1 / 3;
                }
                img{
                    width: 100%;
                }
            }

            .mpinstagramfeed-container  {
                .mpinstagramfeed-photo  {
                    .mpinstagramfeed-post-url {
                        display: block;
                        line-height: 1;
                        font-size: 0;
                    }
                }
            }

            .mpinstagramfeed-photos {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 20px;
                grid-row-gap: 20px;

                .mpinstagramfeed-photos-0 { grid-area: 1 / 1 / 2 / 2; }
                .mpinstagramfeed-photos-1 { grid-area: 2 / 1 / 3 / 2; }
                .mpinstagramfeed-photos-2 { grid-area: 1 / 4 / 2 / 5; }
                .mpinstagramfeed-photos-3 { grid-area: 2 / 4 / 3 / 5; }
                .mpinstagramfeed-photos-4 { grid-area: 1 / 2 / 3 / 4; }
            }

            @include max-screen($screen__l) {

               .mpinstagramfeed-photos {
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(4, 1fr);


                    .mpinstagramfeed-photos-0 { grid-area: 1 / 1 / 2 / 2; }
                    .mpinstagramfeed-photos-1 { grid-area: 1 / 2 / 2 / 3; }
                    .mpinstagramfeed-photos-2 { grid-area: 2 / 1 / 4 / 3; }
                    .mpinstagramfeed-photos-3 { grid-area: 4 / 1 / 5 / 2; }
                    .mpinstagramfeed-photos-4 { grid-area: 4 / 2 / 5 / 3; }
                }
            }
        }

    }

    // block home categorie
    &__categorie {
        background-color: $color-black;
        background-color: $primary__color;
        padding: 80px 0px;

        @include max-screen($screen__l) {
            padding: 4rem 0;
        }

         .content{
            h2{
                color: $color-white;
                margin-bottom: 5rem;
                @include lib-font-size(32);
                @include max-screen($screen__l) {
                    margin-top: 0;
                    margin-bottom: 3rem;
                }
            }
            >div{
                @include max-screen($screen__l) {
                    margin-top: -1rem;
                }
                @include max-screen($screen__m) {
                    margin-top: -4rem;
                }
            }
            .block{
                &__category{
                    img{
                       // height: 25rem;
                       // width: 40rem;
                    }
                    p{
                        display: inline-block;
                        position: absolute;
                        z-index: 5;
                        color: $color-white;
                        font-size: 2.2rem;
                        bottom: 2rem;
                        left: 4rem;
                        font-family: $font-family__montserrat;
                        font-weight: 500;
                    }
                }
                &__link {
                    padding-top: 2rem;
                    ul{
                        padding: 0rem;
                        li{
                            list-style: none;
                            list-style: none;
                            border-bottom: 1px solid $color-gray34;
                            font-size: 1.8rem;
                            margin: 0rem;
                            a{
                                color: $color-white;
                                text-decoration: none;
                                width: 100%;
                                height: 5rem;
                                display: inline-block;
                                padding: 1rem 4rem;
                                @include lib-font-size(16);
                                font-family: $font-family__montserrat;

                                &:before{
                                    @extend %fa-icon;
                                    @extend .fal;
                                    content: fa-content($fa-var-angle-right);
                                    color: $secondary__color;
                                    @include lib-font-size(28);
                                    right: $indent__m;
                                    position: absolute;
                                }
                                &:hover{
                                    text-decoration: none;
                                }
                            }

                           // icon default
                            background-image: url("../../images/protecteur.png");
                            background-repeat: no-repeat;
                            background-position-y: 5px;
                            background-size: 30px;

                            &:hover{
                                border-bottom: 1px solid $secondary__color;
                                background-color: $color-black;
                            }
                            &:first-child{
                                background-image: url("../../images/nettoyant.png");
                                background-repeat: no-repeat;
                                background-position-y: 5px;
                                background-size: 30px;
                            }
                            &:nth-child(2){
                                background-image: url("../../images/protecteur.png");
                                background-repeat: no-repeat;
                                background-position-y: 5px;
                                background-size: 30px;
                            }
                            &:last-child{
                                //background-image: url("../../images/restaurateur.png");
                                background-image: url("../../images/etoire-categorie-vedette.png");
                                background-repeat: no-repeat;
                                background-position-y: 7px;
                                background-size: 25px;
                            }
                        }

                        &.not-icon{
                            li{
                                background-image: none;
                                &:first-child,
                                &:nth-child(2),
                                &:last-child{
                                    background-image: none;
                                }
                                a{
                                    padding: 1rem;
                                }
                            }

                        }
                    }

                    ul{
                        &.block-0{
                            li{
                                &:nth-child(2){
                                    background-image: url("../../images/restaurateur.png");
                                    background-repeat: no-repeat;
                                    background-position-y: 5px;
                                    background-size: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }

        // mobile
        @include max-screen($screen__m) {
            .content{
                .block{
                    &__category:nth-child(1){
                        order: 1;
                    }
                    &__category:nth-child(2){
                        order: 2;
                    }
                    &__category:nth-child(3){
                        order: 3;
                    }
                }
            }

            .item-0{
                order: 1;
            }
            .item-1{
                order: 2;
            }
            .item-2{
                order: 3;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .home{
        // block promo
        &__block-promo {

            &__row {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }

            &__item {
                p{
                    margin-top:0;
                }
            }
        }
        // block home newlwtter
        &__newsletter{
            .content{
                row-gap: 0;
                column-gap: $indent__l;
                grid-template-columns: 1fr 1fr;
                justify-content: space-between;
                align-items: center;
                text-align: center;
                .newsletter{
                    &__content{
                        h2{
                            @include lib-font-size(32);
                        }
                        p{
                            @include lib-font-size(18);
                        }
                    }

                }
            }
        }

    }
}

@include min-screen($screen__l) {
    .home{
        // block Instagram
        &__block_instagram{
            &__picture{
                grid-template-columns: 1fr;
                column-gap: $indent__s;
                row-gap: $indent__s;
                p{
                    margin:0;
                    &:nth-child(3){
                        grid-column: 2 / 4;
                        grid-row: 1 / 3;
                    }
                    img{
                        height: 100%;
                    }
                }
            }
        }
    }
}


.cms-home{
    .section.block.widget{
        margin-top: 8rem;
        .section__header{
            @include lib-font-size(32);
        }
        .link{
            @include lib-font-size(16);
            text-align: right;
        }

        @media (max-width: $screen__m) {
            .section__header{
                width: 70%;
            }
            .link{
                width: 30%;
                padding: 4rem 1rem 0 0;
            }
        }

    }
    .featured-posts{
        &__title{
            h2{
             @include lib-font-size(32);
            }
         }
         &__blog-link{
            @include lib-font-size(16);
        }

        .post-item-details{
            margin-top: $indent__m !important;
        }

        .post-item-info{
            img{
                width: auto;
                height: 371px;
                @media (max-width: $screen__l) {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .cms-home {
        .home__block-promo__row {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
