.aw-rbslider-container{
    .uk-position-bottom{
        bottom: 60px;
    }
    .uk-overlay-panel{
        height: 500px !important;
    }

    .aw-rbslider-content-wrapper > div{

        display: grid;
        grid-template-columns: 1fr 1fr;
        top: 0rem;
        position: absolute;

        >.bg-img-slide{
            justify-self: center;
            img{
                &.img-width{
                max-width: 600px;
                top: 0rem;
                    @media (min-width: $screen__l) {
                        max-width: 600px;
                        top: 0rem;
                    }
                    @media (min-width: $screen__xl) {
                        max-width: 600px;
                        top: 0rem;
                    }
                }
                /*@media (max-width: $screen__m) {
                    position: initial;
                    max-height: 250px;
                    width: auto;
                    margin-left: 30%;
                    margin-top: 5rem;
                }*/

                @media (max-width: $screen__m) {
                    position: initial;
                    max-height: 300px;
                    width: auto;
                    margin-top: 5rem;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;

                }
                @media (max-width: 375px) {
                    max-height: 200px;
                }
            }
            picture{
                width: initial;
                margin-top: initial;
                position: initial;
                clip: initial;
            }
        }
        >.block-contenu{
            padding: 10rem 2rem 2rem 4rem;
            @media (max-width: $screen__l) {
                padding: 2rem;
                max-height: auto;
            }
            @media (max-width: $screen__m) {
                max-height: 320px;
                overflow: auto;
            }
        }

        // Mobile
        @media (max-width: $screen__m) {
            display: inline-block;
            grid-template-columns: auto;
            top: initial;
        }

    }
}

.aw-rbslider-container {
    margin-bottom: -2px !important;
    width: 100% !important;
    background-color: $primary__color;
    min-height: 650px !important;
    margin-top: -1px;

    @media (max-width: $screen__xl) {
        min-height: 620px !important;
        padding-top: $indent__xl;
    }

    @media (max-width: $screen__l) {
        min-height: 520px !important;
    }

    @media (max-width: $screen__m) {
        min-height: 700px !important;
    }


    @media (max-width: $screen__xs) {
        min-height: 660px !important;
    }

    .uk-slideshow{
        min-height: 500px !important;
        margin-top: 30px;
        @media (max-width: $screen__m) {
            min-height: 620px !important;
        }
        @media (min-width: $screen__xl) {
            min-height:500px;
        }
        @media (max-width: $screen__xs) {
            margin-top: 0px;
        }
    }
    .uk-dotnav {
        margin-bottom: 0;
        display: grid !important;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: auto;
        top:10%;
        left: auto;
        right: 5%;
        box-sizing: border-box;
        border-radius: 0;

        /*@media (min-width: $screen__xl) {
            right: 5%;
        }*/
        @media (max-width: $screen__xl) {
            right: 15%;
        }
        @media (max-width: $screen__l) {
            right: 5%;
            display: none !important;
        }
        @media (max-width: $screen__m) {
            right: 5%;
            display: none !important;
        }
        @media (max-width: $screen__xs) {
            right: 5%;
            bottom: 20%;
            top: initial;
        }
        li:before {
            display: none !important;
        }
    }

    .uk-dotnav > * {
        padding-left: 0;
        margin:0;
    }

    .uk-dotnav-contrast > * > * {
        box-sizing: border-box;
        background: transparent;
        background: #FFF;
        width:2px;
        height: 45px;
        border-radius: 0;
        @media (min-width: $screen__m) {
            height: 60px;
        }
    }

    .uk-dotnav-contrast > .uk-active > * {
        background: $secondary__color;
        transform: none;
        width: 3px;
        margin-left:-1px;
    }

    .uk-dotnav > * > * {
        box-shadow: none;
    }

    .uk-slidenav-previous,
    .uk-slidenav-next {
        display: block;
        bottom:5%;
        top:auto;
        color:$secondary__color;
        @media (min-width: $screen__l) {
            bottom: 15%;
        }
        @media (max-width: $screen__m){
            bottom: 0;
        }

    }

    .uk-slidenav-previous{
        left:0%;
        &::before{
            @extend %fa-icon;
            @extend .fal;
            content: fa-content($fa-var-angle-left);
        }
        @media (min-width: $screen__m) {
            left:50%;
        }

    }
    .uk-slidenav-next{
        left:10%;
        &::before{
            @extend %fa-icon;
            @extend .fal;
            content: fa-content($fa-var-angle-right);
        }
        @media (min-width: $screen__m) {
            left:55%;
        }
    }
    .aw-rbslider__img,
    .aw-rbslider-img-wrapper picture {
        //display: none !important;
        width: 50%;
        margin-top: 100px;

        @media (max-width: $screen__m) {
            width: 100%;
            margin-top: 20px;
            position: absolute;
            clip: rect(0, 768px, 230px, 0);
        }
        @media (max-width: $screen__xs) {
            position: initial;
            clip: initial;
        }

    }

    .aw-rbslider-img-wrapper {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
    }
    iframe{
        max-width: 100%;
        width: 100%;
    }

    .uk-text-center {
        text-align: left !important;
    }

    .uk-overlay-panel {
        z-index: 2;
        height: 550px;

        @media (min-width: $screen__xl) {
            min-height:450px;
        }
        > div {
            box-sizing: border-box;
            padding-left: $layout__width-xs-indent;
            padding-right: $layout__width-xs-indent;
            width: 100%;

            @media (min-width: $screen__m) {
                max-width: $layout__max-width;
                padding-left: $layout-indent__width;
                padding-right: $layout-indent__width;
            }
        }
        .row{
            @media (min-width: $screen__m) {
                min-height: 350px;
            }
            @media (min-width: $screen__xl) {
                min-height:450px;
            }
        }
        h1,h2,h3,p{
            color:$color-white;
        }
        h1{
            position: relative;
            margin-bottom: 1rem;
            @include lib-font-size(46);
            font-weight: 600;

            @media (max-width: $screen__m) {
                margin-bottom: 1rem;
            }
        }
        p{
            @include lib-font-size(18);
            margin-bottom: 3rem;
        }
        a {
           @include lib-button-secondary();
           border-radius: $button__border-radius;
           padding: 12px 25px;
           border: 2px solid $secondary__color;
           height: 50px;
           margin-top: 20px;

           @media (max-width: $screen__m) {
                margin-top: 0;
            }

           &:hover{
            text-decoration: none;
           }
        }
    }
}

//  Silder Products list in page Home

.slick-prev.slick-disabled,
.slick-next.slick-disabled{
    opacity: 0;
}

.slick-slider .slick-arrow.slick-prev {
    -webkit-transform: rotate(180deg) translate(0, -42px);
    -moz-transform: rotate(180deg) translate(0, -42px);
    -ms-transform: rotate(180deg) translate(0, -42px);
    transform: rotate(180deg) translate(0, -42px);
}

.slick-slider .slick-arrow {
    top:40%;
}

.product-items.slider-products {
    .slick-slide {
        margin-right: 0px;
    }
    .slick-prev,
    .slick-next{
        background-color: transparent;
        &::before{
            @include lib-font-size(20);
            padding: 10px 15px;
            color:$secondary__color;
            background-color: $color-white;
            border:1px solid $secondary__color;
            border-radius: 5px;
            @extend %fa-icon;
            @extend .far;
        }
    }
    .slick-prev{
        left:0;
        &::before{
            content: fa-content($fa-var-chevron-left);
            transform: rotate(180deg);
        }
    }
    .slick-next{
        &::before{
            content: fa-content($fa-var-chevron-right);

        }
    }

    .slick-arrow{
        z-index: 2;
        &.slick-next{
            right: 20px;
            transform:  translate(0,50%);
            @include max-screen($screen__l) {
                right:42px;
            }
            @include max-screen($screen__xs) {
                width: 42px;
                height: 45px;
            }

        }
        &.slick-prev{
            left: 20px;
            @include max-screen($screen__xs) {
                width: 42px;

            }
            @include max-screen($screen__l) {
                left:42px;
            }
        }
        @include max-screen($screen__m) {
            display: none !important;
        }
    }

    .slick-dots {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        li {
            width: 30px;
            height: inherit;
            button {
                width: 30px;
                height: 4px;
                box-sizing: border-box;
                background: $color-white1;
                opacity: 1;
                border-radius: 0px;
                border:1px solid $color-white1;

                &:before{
                    content: none;
                }
            }

            &.slick-active {
                button {
                    height:8px;
                    background: $secondary__color;
                    border:1px solid $secondary__color;
                }
            }
        }
    }
}

.product-items.slick-slider .slick-slide .product-item{
    max-width: 245px;
    .product-image-photo{
        position: relative;
    }
}

.slick-slider{
    .slick-arrow{
        width: 0;
        height: 0;
    }
}

// related
.related, .upsell{
    .product-items.slick-slider .slick-slide .product-item{
        max-width: 245px;
        padding: 0;
        @include max-screen($screen__xs) {
            padding: 0 1rem;
        }

        .product-image-photo{
            max-width: 100%;
        }

        .product-item-info{
            width: 100%;
            display: inline-block;
            z-index: 0;

            .product-item-details{
                padding: 11px 11px 0 11px;
            }

            .product-image-container{
                width: 100% !important;
                height: auto;
            }

            .block-image{
                position: relative;
            }
        }
    }
}

