//
//  FAQ
//  ---------------------------------------------

.cms-faq{
  .breadcrumbs{
    .item.cms_page{
      text-transform: uppercase;
    }
  }
  
  .page-title-wrapper{
    margin-top: 0px;
    .page-title{
      margin-bottom: 1.5rem;
      text-transform: uppercase;
      font-family: $font-family__montserrat;
      font-weight: bold;
    }
  }
}
.faq-block {
    .accordion {
        .block > .question {
            border-bottom: 1px solid $color-gray20 ;
            padding: 1rem 5rem 1rem 4rem;
            cursor: pointer;
            background-color: $color-white1;
            margin: 1.5rem 0 3rem 0;
            @include lib-font-size(16);
            font-weight: 500;
            font-family: $font-family__montserrat;
            line-height: 2.5rem;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            position: relative;

            &:before{
              content: "\f054";
              font-size: 20px;
              line-height: normal;
              font-family: 'Font Awesome 5 Pro';
              font-weight: 400;
              color: $secondary__color;
              position: absolute;
              right: 2rem;
              transform: rotate( 90deg);
            }

            &.open{
                border-bottom: 1px solid $secondary__color ;
                &:before{
                  transform: rotate( 270deg);
                }
            }

        }
    }
}
  .question-answer {
      .content-heading{
          &:before{
            content: none;
          }
        }
    }


@include min-screen($screen__m) {
    .block-content{
        &.column1layout{
            float: left;
            padding-left: 0;
            padding-right: 3%;
            width: 27%;
            margin-top: 1.5rem;
            margin-bottom: 4rem;
            background-color: $color-white1;
          }
    }
    .question-answer.column1layout {
        >.content-heading:first-child{
            float: right;
            width: 70%;
          }
          .faq-block {
            float: right;
            width: 70%;
            margin-bottom: 5rem;
          }
    }
  }
  
  .faq-block h2{
    text-align: center;
  }
  .block-subtitle {
    border-bottom: 1px solid $secondary__color;
    padding: 0rem 1rem 3rem 1rem;
    display: block;
    margin-top: 1.1rem;
    @include lib-font-size(22);
    font-family: $font-family__montserrat;
    line-height: 3rem;
    font-weight: 500;
    
  }
  .block-item{
    padding: 10px;
    cursor: pointer;
    font-family: $font-family__montserrat;
    &.active{
        font-weight: bold;
      }
  }

  .faq-block {
    .answer{
        padding: 0 4rem 0 5rem;
        background-color: $color-white;
        @include lib-font-size(16);
        display: none; 
        
        p{
          padding: 1rem 0;
          line-height: 2.5rem;
        }
      }
 
  }
  

  @include max-screen($screen__m) {
    .faq-block .accordion .block > .question::after {
      top: 20px;
    }

    .cms-faq .block-content.column1layout{
      margin-bottom: 5rem;
      padding: 2rem 0;
      background-color: $color-white1;
    }


  }