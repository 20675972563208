//
//  Base Styles
//  ____________________________________________

//
//  Common
//  ----------------------------------------------
/** FIXE AFTER UPGRADE PLUGIN AM SEARCH ***/
.page-header .amsearch-wrapper-block:before {
    display: none;
}



.amsearch-wrapper-block {
    width: 60%;
    padding: 0;
}

.amsearch-input:focus {
    border: none;
    box-shadow: none;
    height:100%;
    border-bottom: 4px solid #00bb31;
    border-radius: 0;
    padding-left: 30px;
}

.amsearch-input[type="text"] {
    background: #1B1B1B;
    color: #fff;
    padding-left: 30px;
    font-size: 1.8rem;
    height: 99%
}

.block-search .block-content {
    .amsearch-wrapper-block {
        width: 100% !important;
        margin-top: 0;
        padding: 0;

        .amsearch-input-wrapper {
            width: 100%;
        }

        input.amsearch-input {
            padding: 0 70px;
            border: 1px solid $black;
        }

        .amsearch-loupe {
            position: absolute;
            top: 12px;
            left: 20px;
            transform: translateY(-50%);
            border: 0;
            padding: 0;
            box-shadow: none;
            background: none;
            right: inherit !important;

            &:before {
                display: inline-block;
                content: '';
                width: 36px;
                height: 50px;
                background-image: url('../images/icons/search.svg');
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: center;
                margin-top: -10px;
                margin-left: 10px;
                @media screen and (max-width: $screen__s) {
                    width: 26px;
                    height: 34px;
                    margin-top: -3px;
                }
            }
        }

        .amsearch-tab-items {
            .amsearch-link.item-name {
                color: $black;
                text-decoration: underline;
            }
        }

        .amsearch-item-container.page {
            .amsearch-item {
                .item-name {

                }
            }
        }

        .amsearch-item-container.-recent_searches {
            .amsearch-item {
                padding-top: 0;
            }
        }

        .amsearch-products {
            .amsearch-wrapper-content {
                @media screen and (max-width: 767px) {
                    .amsearch-item.product-item {
                        flex-wrap: nowrap !important;
                        max-width: 100%;

                        .product-item-details {
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
                @media screen and (min-width: 768px) {
                    display: flex;
                    flex-wrap: wrap;
                    .amsearch-item.product-item {
                        max-width: 40%;
                        margin: 20px 20px 0 20px;

                        .amsearch-autocomplete-information {
                            padding-left: 0;

                            .product-item-details {
                                &:before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .amsearch-more-results {
                .amsearch-link {
                    color: $black;

                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }
}

/** FIXE AFTER UPGRADE PLUGIN AM SEARCH ***/

.amsearch-results {
    display: flex;
    padding: 1.5rem;
    flex-wrap: wrap;
    flex-direction: column-reverse;

    @media (min-width: $screen__s) {
        flex-wrap: nowrap;
        flex-direction: row;
    }

    & {
        @include lib-css(background-color, #FFF);
    }

    .amsearch-item {
        text-transform: capitalize;
        cursor: pointer;
    }

    .amsearch-item .item-name {
        color: #000;
    }
}

.amsearch-form-container .control {
    min-height: 50px !important;
}

.amsearch-products.-waste {
    padding: 16px $amsearch__indent__xl $amsearch__indent__xl;
}

.amsearch-form-container.-opened .input-text {

    &::-ms-clear {
        display: none;
    }

    &::-webkit-input-placeholder {
        @include lib-css(color, $amxsearch-placeholder__color);
        font-style: italic;
    }

    &::-moz-placeholder {
        @include lib-css(color, $amxsearch-placeholder__color);
        font-style: italic;
    }

    &:-ms-input-placeholder {
        @include lib-css(color, $amxsearch-placeholder__color);
        font-style: italic;
    }
}

.amsearch-form-container .search-autocomplete .amsearch-results .amsearch-leftside {
    background-color: #F3F3F3;
}

.amsearch-form-container .search-autocomplete .amsearch-leftside .amsearch-products {
    @include lib-css(background-color, #FFF);
}

.amsearch-wrapper-input {
    position: relative;
    transition: none;
}

.amsearch-wrapper-content {
    > .amsearch-item {
        & {
            position: relative;
            display: flex;
            padding: 0 !important;
            border: 1px solid #BFBFBF;
            margin: 0 0 2rem 0;
            width: 100%;

            @media (min-width: $screen__s) {
                margin: 0 0 3rem 2rem;
            }

            @media (min-width: $screen__m) {
                width: auto;
            }
        }

        &:first-child:before,
        &:last-child:after {
            display: none;
        }
    }

    .product-item-details {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100px;
        padding: 2rem;

        &:before {
            width: 1px;
            height: 55px;
            left: 0;
            top: 1.5rem;
        }
    }

    .amsearch-wrapper-inner {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-box-align(flex-end);
        @include lib-vendor-prefix-flex-wrap(wrap);
        margin-top: auto;
    }

    .action.towishlist {
        margin-right: 5px;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 1.9rem;
    }

    .product-item-sku {
        font-size: 1.2rem;
        line-height: 2rem;
    }

    .product-item-link {
        margin: 0 0 5px;
        color: #000;
        text-transform: uppercase;
        word-break: keep-all;
        font-size: 1.6rem;
        line-height: 1.9rem;
        font-weight: 800;
    }

    .product-item .price-box {
        margin: 3rem 0 0 0;
    }

    .product-item-actions {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-box-align(flex-end);
        margin-top: 6px;
    }
}

.block-search .search .control {
    position: relative;
}

.amsearch-form-container.-opened .search .control {
    z-index: 99;
}

.amsearch-close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    display: none;
    padding: 16px;
    background: $amxsearch-autocomplete__background-image no-repeat center;
    cursor: pointer;
}

.amsearch-products .amsearch-highlight {
    padding: 0 2px;
    @include lib-css(background-color, $amxsearch-product-sought__background);
    color: #fff;
    word-wrap: normal;
}

.amsearch-leftside .amsearch-item {
    padding: 15px;
}

.amsearch-autocomplete-information {
    & {
        padding-left: 2rem;
        flex-grow: 1;
    }

    .action.view {
        font-weight: 300;
        color: #000;
    }
}

.amasty-xsearch-loader {
    position: absolute;
    z-index: 1000;
    top: 40%;
    right: 40px;
    width: 16px;
    height: 11px;
    margin-right: 5px;
    @include lib-css(background, $amxsearch-autocomplete-loader__background-image no-repeat center);
}

.amasty-message.message.info {
    margin: 0;
}

.search-autocomplete {
    overflow-y: auto;
    max-height: 90vh;
}

.amasty-xsearch-num-results {
    float: right;
}

.amsearch-more-results {
    & {
        margin-top: 3rem;
        margin-bottom: 3rem;
        text-align: center;
    }

    .amsearch-link {
        @include lib-button-primary();
        padding: 9px 20px;
        background: #fff;

        &:after {
            content: '';
            width: 25px;
            height: 25px;
            display: inline-block;
            vertical-align: middle;
            background-image: url('../images/icons/arrow-red.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: -1rem;
            transform: rotate(-90deg);
        }

        &:hover, &:focus {
            text-decoration: none;

            &:after {
                background-image: url('../images/icons/arrow-white.svg');
            }
        }
    }
}

.actions-secondary {
    display: flex;
    padding-left: 6px;
    flex-wrap: nowrap;
}

.block-search .action.search.amasty-xsearch-hide,
.amasty-xsearch-hide {
    display: none;
}

.amasty-xsearch-block-header {
    margin-bottom: 0;
    padding: 0 0 2rem 0;
    font-style: normal;
    font-weight: 800;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    @include lib-css(color, #000);

    @media (min-width: $screen__s) {
        padding: 2rem $amsearch__indent__xl 2rem 2rem;
    }
}

.amasty-xsearch-block-header {
    word-break: break-word;
    word-wrap: break-word;
}

.amsearch-item-container {
    & {
        position: relative;
        padding: 15px 15px $amsearch__indent__xl;
    }

    &:first-child {
        padding-top: 17px;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #FFF;
    }

    &:last-child:after {
        display: none;
    }

    > .amasty-xsearch-block-header {
        position: relative;
        padding: 0;
    }

    .amsearch-item {
        & {
            padding: 11px 0 0;
            cursor: inherit;
        }

        &:hover {
            background-color: inherit;
        }
    }

    .item-name {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.9rem;
        display: block;
    }

    .amsearch-highlight {
        font-weight: 600;
        font-size: 1.6rem;
        color: $blue;
    }
}

.amsearch-item-container.recent_searches {
    .amasty-xsearch-num-results {
        display: none;
    }
}

.amsearch-item-container.popular_searches {
    .amasty-xsearch-num-results {
        display: none;
        font-size: 12px;
    }
}

.amsearch-item-container.-brands {
    .amasty-xsearch-num-results {
        display: inline-block;
        float: none;
        color: #ccc;
    }
}

.amasty-xsearch-preload {
    display: none;
}

.search-autocomplete .amsearch-results div:not([data-click-url]) {
    cursor: default;
    background: none;
}

.amsearch-category-tab .amsearch-item {
    & {
        margin-bottom: $amsearch__indent;
        font-size: 1.6rem;
    }

    .am-item-link:not(:last-of-type):after {
        content: '';
        display: inline-block;
        width: $amsearch__indent;
        height: $amsearch__indent;
        margin: 0 $amsearch__indent;
        @include lib-css(background, $amxsearch-arrow-right__background);
        background-size: contain;
    }
}

.block.block-search,
.form .search-autocomplete {
    z-index: 99;
}

@media only screen and (max-width: 767px) {

    .amsearch-leftside,
    .amsearch-products {
        width: 100% !important;
    }

    .amsearch-products.-waste {
        width: auto !important;
    }

    .form .search-autocomplete {
        overflow-y: auto;
        max-height: 70vh;
    }
}

.amsearch-item .product-item-description,
.amsearch-item .item-description {
    word-break: keep-all;
}

.amsearch-clone-position {
    position: absolute;
    z-index: 0 !important;
    right: 0;
    top: 25px;
    padding-top: 25px;
    box-shadow: none;
}

.amsearch-autocomplete-image {
    & {
        flex-grow: 0;
        align-items: center;
        width: auto;

        img {
            width: 100%;
            display: block;
        }
    }

    .product-image-container {
        display: block;
        width: 100% !important;
    }

    .product-image-photo {
        box-sizing: border-box;
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
    }
}

#amsearch-result-tabs {
    & {
        margin-top: 5px;
    }

    .data.title {
        & {
            margin-right: 5px;
        }

        &.active {
            background: #d8d8d8;
        }

        &.last {
            border-bottom: 1px solid #ccc;
            margin-bottom: 8px;
        }
    }

    .title.active .amsearch-results-count {
        display: none;
    }

    .data.content {
        display: none;
    }

    .data.content[aria-hidden="false"] {
        display: block;
    }
}

.amsearch-related-terms {
    & {
        display: flex;
        flex-wrap: wrap;
        margin: $amsearch__indent__xl 0;
    }

    > .amsearch-title {
        margin: 0 0 $amsearch__indent__l 0;
        width: 100%;
        font-weight: bold;
    }

    > .amsearch-item {
        & {
            margin: 0 $amsearch__indent__l $amsearch__indent 0;
            padding: 5px $amsearch__indent__l;
            border-radius: $amsearch__indent__xl;
            background: #d8eeff;
            color: #252525;
            font-size: 12px;
            line-height: 1.3;
            transition: .1s;
        }

        &:hover {
            background: #b7e0ff;
        }

        &:hover,
        &:active {
            text-decoration: none;
        }
    }

    .amsearch-count {
        color: #757575;
    }
}


//
//  Tablet
//  --------------------------------------------

@include max-screen($screen__m) {
    .minisearch .actions .action.search {
        z-index: 1000;
    }

    .product-item-actions .actions-primary + .actions-secondary > .action {
        margin: 0 5px;
        border: none;
    }

    .amasty-xsearch-loader {
        right: 30px;
    }

    .search-autocomplete {
        top: 37px;
    }

    .amsearch-close,
    .amasty-xsearch-loader,
    .search .amsearch-loupe {
        &:hover {
            -webkit-filter: brightness(0%);
            filter: brightness(0%);
        }
    }

    .amsearch-results {
        & {
            @include lib-vendor-prefix-display();
            overflow: auto;
        }

        .amsearch-leftside,
        .amsearch-products {
            width: inherit;
        }
    }

    .amsearch-wrapper-content {
        > .amsearch-item {
            & {
                position: relative;
                padding: $amsearch__indent__xl 0 16px 15px;
            }

            &:last-child {
                padding-bottom: $amsearch__indent__xl;
            }

            &:first-child {
                padding-top: 15px;
            }

            &:first-child:before {
                height: 0;
            }
        }

        .amsearch-autocomplete-information {
            padding-left: $amsearch__indent;
        }

        .product-item-details {
            min-height: 100px;
            padding-right: 14px;
        }

        .product-reviews-summary {
            margin-bottom: 0;
        }
    }

    .search .amsearch-autocomplete-information {
        .tocart {
            font-size: 12px;
        }

        .price {
            font-size: 14px;
        }
    }

    .amsearch-item-container:after {
        left: $amsearch__indent;
    }

    .amsearch-form-container.-opened {
        .action.search {
            display: none;
        }

        .amsearch-wrapper-input {
            transition: all .3s ease-in-out;
        }
    }

    .amsearch-products {
        & {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
        }

        .amsearch-more-results {
            margin-top: auto;
            padding-top: 30px;
        }
    }

    .amsearch-form-container.-small {
        .amsearch-results {
            @include lib-vendor-prefix-flex-direction(column);
            overflow-x: hidden;
        }

        .amsearch-products .amsearch-item {
            padding-right: 15px;
        }

        .amsearch-item-container .amsearch-item {
            padding-right: 15px;
        }
    }

    .amsearch-form-container.-large .amsearch-results .amsearch-more-results {
        padding-top: 0;
    }

    .amsearch-form-container.-large .amsearch-item-container {
        & {
            padding-left: 25px;
        }

        &:after {
            left: $amsearch__indent__xl;
            width: 85%;
        }

        .amsearch-item {
            padding-top: 6px;
        }


        .recent_searches .amsearch-item {
            padding-top: 8px;
        }
    }

    .amsearch-form-container.-large .amsearch-wrapper-content {
        & {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-wrap(wrap);
            margin-bottom: 0;
        }

        .amsearch-autocomplete-information {
            @include lib-vendor-prefix-flex-grow(1);
            padding-left: 0;
        }

        .product-reviews-summary {
            margin-bottom: 7px;
        }

        .amsearch-autocomplete-image {
            @include lib-vendor-box-align(center);
        }

        .amsearch-wrapper-inner {
            @include lib-vendor-prefix-flex-wrap(wrap);
            padding-left: 0;
        }

        .product-item-details {
            height: 100%;
        }

        .price-box {
            margin: 0 0 2px;
        }

        .price {
            font-size: 16px;
        }

        .product-item-actions {
            margin: 0;
        }

        .tocart {
            font-size: 12px;
        }

        .product-item-actions {
            display: flex;
            max-width: 100%;
            align-items: center;
        }

        .actions-secondary {
            display: flex;
            min-width: 50px;
            padding-left: 6px;
        }

        .rating-summary {
            display: block;
        }

        .reviews-actions {
            display: block;
            margin-bottom: 1px;
        }
    }
}

//
//  Desktop
//  --------------------------------------------

@include min-screen($screen__l) {
    #amsearch-result-tabs {
        .data.title {
            &.active {
                background: none;
            }

            &.last {
                border-bottom: none;
                margin-bottom: 0;
            }
        }
    }
}

#store-selector-popup > div > div.actions > button.search {
    right: 20px;
    left: inherit;
}

.ampromo-overlay:not(.-show) {
    display: none;
}

//
//  AmSearch
//  --------------------------------------------
.amsearch-wrapper-input.amsearch-left-position,
.search-autocomplete.amsearch-left-position {
    width: 100% !important;
    right: unset;
    left: 0;
}

.amsearch-close {
    display: none !important;
}

.amsearch-form-container.-opened .input-text {
    padding-left: 55px !important;
}

.amsearch-wrapper-content {
    .amsearch-wrapper-inner {
        margin-top: 20px !important;
    }
}

.amsearch-products .amsearch-highlight {
    background-color: red !important;
}

.amsearch-item-container:not(.recent_searches) .amsearch-highlight,
.amsearch-item-container.page a.item-name {
    color: red !important;
}

.search .amsearch-loupe {
    background: none !important;
}

.checkout-cart-index, .checkout-index-index {
    .ampromo-items-add {
        display: none;
    }
}

/** fix after upgrade **/
.amsearch-form-block {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    position: relative;
    transition: .3s
}

.amsearch-form-block.-opened {
    z-index: 98
}

.header.content {
    flex-wrap: wrap
}

.amsearch-loader-block {
    background: url(data:image/gif;base64,R0lGODlhEAALAPQAAP///wAAANra2tDQ0Orq6gYGBgAAAC4uLoKCgmBgYLq6uiIiIkpKSoqKimRkZL6+viYmJgQEBE5OTubm5tjY2PT09Dg4ONzc3PLy8ra2tqCgoMrKyu7u7gAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCwAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQJCwAAACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQJCwAAACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQJCwAAACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAkLAAAALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkECQsAAAAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAkLAAAALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkECQsAAAAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA) no-repeat center;
    bottom: 0;
    height: 11px;
    margin: auto 5px auto 0;
    position: absolute;
    right: 40px;
    top: 0;
    width: 16px;
    z-index: 1000
}

.amsearch-message-block {
    align-items: center;
    background: #fafafa;
    display: flex;
    font-size: 18px;
    justify-content: center;
    line-height: 25px;
    margin: 20px;
    padding: 20px;
    text-align: center;
    width: 100%
}

.amsearch-message-block b {
    padding: 0 0 0 5px
}

.amsearch-link {
    color: #1979c2;
    text-decoration: none
}

.amsearch-link:hover {
    color: #006bb4;
    text-decoration: underline
}

.amsearch-link:active {
    color: #004c80
}



.nav-sections .navigation {
    z-index: 2
}

.page-header .amsearch-wrapper-block:before {
    background: #ccc;
    content: '';
    height: 1px;
    margin: 0 -15px;
    position: absolute;
    top: 0;
    width: calc(100% + 15px * 2)
}

.amsearch-emptysearch-cms {
    background: #fbfcfe;
    color: #1f1b1b;
    padding: 30px
}

.amsearch-emptysearch-cms > .amsearch-content {
    word-break: break-word;
    word-wrap: break-word
}

.ie11 .amsearch-emptysearch-cms > .amsearch-content {
    word-break: break-all
}

.amsearch-emptysearch-cms .amsearch-content > .amsearch-title {
    font-size: 38px;
    font-weight: 800;
    margin: 0 0 20px
}

.amsearch-emptysearch-cms .amsearch-text {
    font-size: 20px;
    font-weight: 300;
    line-height: 28px
}

.amsearch-emptysearch-cms .amsearch-contacts-block {
    font-size: 14px;
    margin-top: 30px
}

.amsearch-emptysearch-cms .amsearch-image-block {
    margin-top: 30px
}

.amsearch-emptysearch-cms .amsearch-contacts-block > .amsearch-title {
    margin-bottom: 5px
}

.amsearch-highlight {
    color: var(--amsearch-color-highlight, #3ac39c);
    font-weight: 700
}

@-webkit-keyframes amsearch-animation__fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-moz-keyframes amsearch-animation__fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-ms-keyframes amsearch-animation__fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-o-keyframes amsearch-animation__fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes amsearch-animation__fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

.amsearch-button {
    cursor: pointer;
    display: inline-block;
    transition: .3s
}

.amsearch-button.-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 13px
}

.amsearch-button.-icon:hover, .amsearch-button.-icon:focus {
    filter: brightness(.5)
}

.amsearch-button.-clear {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0
}

.amsearch-button.-clear:active, .amsearch-button.-clear:hover, .amsearch-button.-clear:focus {
    border: none;
    box-shadow: none
}

.amsearch-button.-close {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMWwxMCAxMG0wLTEwTDEgMTEiIHN0cm9rZT0iI0IyQjJCMiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvc3ZnPg==);
    border: 0;
    padding: 16px
}

.amsearch-button.-loupe:before {
    content: "\f002";
    font-size: 20px;
    line-height: normal;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    color: #fff;
    top: 8px;
    position: absolute;
    right: 20px;
}

.amsearch-button.-wishlist, .amsearch-button.-compare {
    background-size: 18px
}

.amsearch-button.-wishlist {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAyMCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjA2MTIgMC40NzczMUMxNS43NjIgLTAuMTAzNzYzIDE0LjQ0OTcgLTAuMTU0MjkxIDEzLjEyNDUgMC4zMjU3MjZDMTEuNzk5MiAwLjc4MDQ3OSAxMC44Mzc3IDEuNjM5NDYgMTAuMjQwMSAyLjkwMjY2QzEwLjE4ODEgMy4wMDM3MiAxMC4xMzYxIDMuMTA0NzcgMTAuMDg0MiAzLjIwNTgzQzEwLjA1ODIgMy4zMDY4OCAxMC4wMzIyIDMuNDIwNTcgMTAuMDA2MiAzLjU0Njg5QzkuOTgwMjMgMy40MjA1NyA5Ljk0MTI1IDMuMzA2ODggOS44ODkyOCAzLjIwNTgzQzkuODYzMyAzLjEwNDc3IDkuODI0MzIgMy4wMDM3MiA5Ljc3MjM1IDIuOTAyNjZDOS4xNzQ2OCAxLjYzOTQ2IDguMjEzMjMgMC43ODA0NzkgNi44ODc5NyAwLjMyNTcyNkM1LjU2MjcyIC0wLjE1NDI5MSA0LjI1MDQ2IC0wLjEwMzc2MyAyLjk1MTE5IDAuNDc3MzFDMS42Nzc5MSAxLjA4MzY1IDAuNzk0NDA5IDIuMDQzNjggMC4zMDA2ODggMy4zNTc0MUMtMC4xNjcwNDkgNC42NzExNCAtMC4wODkwOTI2IDUuOTU5NjEgMC41MzQ1NTYgNy4yMjI4MUMwLjgyMDM5NSA3Ljg1NDQxIDEuNDcwMDMgOC43MjYwMiAyLjQ4MzQ2IDkuODM3NjRDMy41MjI4NyAxMC45MjQgNC42MDEyNiAxMS45OTc3IDUuNzE4NjMgMTMuMDU4OEM2LjgzNiAxNC4xMTk5IDcuODIzNDUgMTUuMDQyIDguNjgwOTYgMTUuODI1MkM5LjU2NDQ2IDE2LjYwODQgMTAuMDA2MiAxNyAxMC4wMDYyIDE3QzEwLjAwNjIgMTcgMTAuMDA2MiAxNi45ODc0IDEwLjAwNjIgMTYuOTYyMUMxMC4wMDYyIDE2Ljk4NzQgMTAuMDA2MiAxNyAxMC4wMDYyIDE3QzEwLjAwNjIgMTcgMTAuNDM1IDE2LjYwODQgMTEuMjkyNSAxNS44MjUyQzEyLjE3NiAxNS4wNDIgMTMuMTc2NCAxNC4xMTk5IDE0LjI5MzggMTMuMDU4OEMxNS40MTEyIDExLjk5NzcgMTYuNDc2NiAxMC45MjQgMTcuNDkgOS44Mzc2NEMxOC41Mjk0IDguNzI2MDIgMTkuMTkyIDcuODU0NDEgMTkuNDc3OSA3LjIyMjgxQzIwLjEwMTUgNS45NTk2MSAyMC4xNjY1IDQuNjcxMTQgMTkuNjcyOCAzLjM1NzQxQzE5LjIwNSAyLjA0MzY4IDE4LjMzNDUgMS4wODM2NSAxNy4wNjEyIDAuNDc3MzFaIiBmaWxsPSIjNkU3MTZFIi8+Cjwvc3ZnPgo=);
    height: 20px;
    width: 20px
}

.amsearch-button.-compare {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAyMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgMTRWOS4zNjk3OUgwVjE0SDVaTTEyLjQ2MDkgMEg3LjVWMTRIMTIuNDYwOVYwWk0yMCA0LjcwMzEySDE1VjE0SDIwVjQuNzAzMTJaIiBmaWxsPSIjNkU3MTZFIi8+Cjwvc3ZnPgo=);
    height: 20px;
    width: 20px
}

.amsearch-button.-disabled {
    opacity: .7;
    pointer-events: none
}

.amsearch-button.-primary {
    background: #1979c2;
    border: 1px solid #1979c2;
    border-radius: 0;
    color: #1979c2;
    letter-spacing: -.015em;
    transition: .3s
}

.amsearch-button.-primary:hover {
    background: #1979c2
}

.amsearch-button.-primary:active {
    background: #1979c2
}

.amsearch-button.-search {
    background-color: var(--amsearch-color-search_button, #1979c2);
    border-color: var(--amsearch-color-search_button, #1979c2);
    color: var(--amsearch-color-search_button_text, #fff)
}

.amsearch-button.-search:hover {
    background: var(--amsearch-color-search_button-hover, #166bab);
    border-color: var(--amsearch-color-search_button-hover, #166bab)
}

.amsearch-button.-search:active {
    background: var(--amsearch-color-search_button-focus, #135d95);
    border-color: var(--amsearch-color-search_button-focus, #135d95)
}

.amsearch-button.-full-close {
    align-items: center;
    background-color: #f5f5f5;
    border-color: #f5f5f5;
    border-radius: 50%;
    display: flex;
    font-size: 10px;
    height: 32px;
    justify-content: center;
    padding: 0;
    width: 32px
}

.amsearch-button.-full-close:after {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMWwxMCAxMG0wLTEwTDEgMTEiIHN0cm9rZT0iI0IyQjJCMiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    height: 12px;
    width: 12px
}

.amsearch-button.-full-close:hover {
    background-color: #e8e8e8
}

.amsearch-button.-full-close:active {
    background-color: #dcdcdc
}

.amsearch-slider-section {
    box-sizing: border-box;
    display: flex
}

.amsearch-slider-section .amsearch-item:hover, .amsearch-slider-section .amsearch-item.selected {
    background-color: transparent
}

.amsearch-slider-section .amsearch-item .amsearch-image {
    pointer-events: auto;
    width: 100%
}

.amsearch-slider-section.slick-slider {
    padding: 0
}

.amsearch-slider-section .slick-dots {
    background: inherit;
    border: none;
    top: 100%
}

.amsearch-slider-section .slick-dots > li[role='presentation'] {
    align-items: center;
    border: none;
    display: inline-flex;
    height: 6px;
    justify-content: center;
    margin: 0 6px;
    padding: 0;
    text-align: inherit;
    white-space: inherit;
    width: 6px
}

.amsearch-slider-section .slick-dots > li[role='presentation']:hover, .amsearch-slider-section .slick-dots > li[role='presentation'].selected {
    background: inherit
}

.amsearch-slider-section .slick-dots > li[role='presentation'].slick-active button {
    background: #ff5502;
    border-color: #ff5502
}

.amsearch-slider-section .slick-dots > li[role='presentation'] button {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 100%;
    height: 100%;
    padding: 0;
    width: 100%
}

.amsearch-slider-section .slick-dots > li[role='presentation'] button:before {
    content: none
}

.amsearch-slider-section button.slick-arrow {
    background: #fff url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxMSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjU0NTQgOS4zNzY2OEwyLjE5ODk1IDAuNDA5MThMMC43NDIyNjEgMS44NDg5Mkw3Ljc1MDEzIDkuMzc2NjhMMC41NDU0MSAxNy4xNTEzTDEuOTYyNzMgMTguNTkxTDEwLjA3MyA5LjkxMTQ0TDEwLjU0NTQgOS4zNzY2OFoiIGZpbGw9IiM5OTk5OTkiLz4KPC9zdmc+Cg==') center no-repeat !important;
    border-radius: 0;
    bottom: 0;
    height: 40px;
    margin: auto 0;
    top: 0;
    width: 40px;
    z-index: 2
}

#amasty-shopby-product-list {
    max-width: 100%;
    order: 10
}

.amsearch-products-section {
    align-content: flex-start;
    border-left: none;
    border-top: 1px solid #ccc;
    box-sizing: border-box;
    padding: 20px 0 0;
    position: relative;
    transition: .1s;
    width: 100%
}

.amsearch-products-section .amsearch-sku-block {
    padding: 0 0 10px
}

.amsearch-products-section .amsearch-link {
    color: var(--amsearch-color-text, #3ac39c)
}

.amsearch-products-section .amsearch-link:hover {
    color: var(--amsearch-color-text-hover, #3ac39c)
}

.amsearch-products-section .amsearch-block-header {
    padding: 0 20px 25px
}

.amsearch-products-section .amsearch-item .product-item-description, .amsearch-products-section .amsearch-item .item-description {
    word-break: keep-all
}

.amsearch-products-section .product-item-description {
    color: #999;
    font-size: 1.2rem;
    margin: 0;
    padding: 0 0 10px
}

.amsearch-products-section .amsearch-product-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0
}

.amsearch-products-section .amsearch-item {
    align-content: flex-start;
    box-sizing: border-box;
    display: flex;
    margin: 0;
    padding: 20px;
    position: relative
}

.amsearch-products-section .amsearch-item:hover {
    background: var(--amsearch-color-hover, #f4f5fa);
    z-index: 8
}

.amsearch-products-section .amsearch-item:not(:hover) .dropdown {
    display: none
}

.amsearch-products-section .product-item-details {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    min-height: 100px
}

.amsearch-products-section .amsearch-wrapper-inner {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap
}

.amsearch-products-section .product-item-link {
    padding: 0 0 10px;
    word-break: keep-all
}

.amsearch-products-section .action.tocart {
    word-break: break-word;
    word-wrap: break-word;
    border-radius: 0;
    font-size: 1.2rem;
    line-height: 1.2;
    min-height: 35px;
    padding: 10px;
    white-space: inherit
}

.ie11 .amsearch-products-section .action.tocart {
    word-break: break-all
}

.amsearch-products-section .product-item [data-role='priceBox'] {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    padding: 0 0 10px
}

.amsearch-products-section .product-item [data-role='priceBox'] p, .amsearch-products-section .product-item [data-role='priceBox'] span {
    flex-basis: 100%;
    padding: 0 0 5px
}

.amsearch-products-section .product-reviews-summary {
    margin: 0;
    padding: 0 0 10px
}

.amsearch-products-section .actions-secondary {
    align-items: center;
    display: flex;
    padding: 5px
}

.amsearch-products-section .actions-secondary .amsearch-button.-icon {
    margin: 5px
}

.amsearch-products-section .actions-secondary > .action {
    flex-grow: inherit;
    line-height: normal;
    margin-right: 5px
}

.amsearch-products-section.-list .amsearch-item {
    width: 100%
}

.amsearch-products-section.-list .amsearch-image {
    max-width: 132px;
    width: 40%
}

.amsearch-products-section.-list .actions-secondary {
    padding: 0 0 0 5px
}

.amsearch-products-section.-list .amsearch-description {
    padding-left: 10px
}

.amsearch-products-section.-grid .amsearch-image {
    padding-bottom: 10px;
    width: 100%
}

.amsearch-products-section.-grid:not(.-slider) .amsearch-item {
    flex-wrap: wrap;
    max-width: 50%
}

.amsearch-collapsible-section[data-collapsible].-opened .amsearch-collapsible-title:after {
    transform: rotate(225deg) translate(-5px, 0)
}

.amsearch-collapsible-section[data-collapsible] .amsearch-collapsible-title {
    align-items: center;
    cursor: pointer;
    display: flex
}

.amsearch-collapsible-section[data-collapsible] .amsearch-collapsible-title:after {
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    content: '';
    display: inline-block;
    height: 10px;
    transform: rotate(45deg) translate(0, -5px);
    transition: .3s all;
    width: 10px
}

.amsearch-block-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    padding: 0 20px 2px;
    width: 100%
}

.amsearch-block-header .amsearch-title {
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: capitalize;
    word-break: break-word;
    word-wrap: break-word
}

.amsearch-block-header .amsearch-link {
    line-height: 26px;
    min-width: 90px
}

.amsearch-block-header .amsearch-link.-view-all .amsearch-count {
    font-weight: inherit
}

.amsearch-block-header .amsearch-link .amsearch-count:before {
    content: '(+'
}

.amsearch-block-header .amsearch-count {
    font-weight: 300
}

.amsearch-block-header .amsearch-count:before {
    content: '('
}

.amsearch-block-header .amsearch-count:after {
    content: ')'
}

.amsearch-category-tab .amsearch-item {
    font-size: 1.6rem;
    margin-bottom: 10px
}

.amsearch-category-tab .amsearch-item .am-item-link:not(:last-of-type):after {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNDUxLjg0NiA0NTEuODQ3Ij48cGF0aCBkPSJNMzQ1LjQ0MSAyNDguMjkyTDE1MS4xNTQgNDQyLjU3M2MtMTIuMzU5IDEyLjM2NS0zMi4zOTcgMTIuMzY1LTQ0Ljc1IDAtMTIuMzU0LTEyLjM1NC0xMi4zNTQtMzIuMzkxIDAtNDQuNzQ0TDI3OC4zMTggMjI1LjkyIDEwNi40MDkgNTQuMDE3Yy0xMi4zNTQtMTIuMzU5LTEyLjM1NC0zMi4zOTQgMC00NC43NDggMTIuMzU0LTEyLjM1OSAzMi4zOTEtMTIuMzU5IDQ0Ljc1IDBsMTk0LjI4NyAxOTQuMjg0YzYuMTc3IDYuMTggOS4yNjIgMTQuMjcxIDkuMjYyIDIyLjM2NiAwIDguMDk5LTMuMDkxIDE2LjE5Ni05LjI2NyAyMi4zNzN6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIGRhdGEtb2xkX2NvbG9yPSIjYTNhM2EzIiBmaWxsPSIjYTNhM2EzIi8+PC9zdmc+) no-repeat center;
    background-size: contain;
    content: '';
    display: inline-block;
    height: 10px;
    margin: 0 10px;
    width: 10px
}

.amsearch-result-tabs {
    margin-top: 5px;
    z-index: initial
}

.amsearch-result-tabs > .data.item.content:not([aria-hidden='false']) {
    display: none
}

.amsearch-result-tabs .title {
    box-sizing: border-box;
    position: relative
}

.amsearch-result-tabs .title.active .amsearch-results-count {
    display: none
}

.amsearch-result-tabs .title.active .switch {
    padding-bottom: 1px
}

.amsearch-result-tabs .data.content {
    display: none
}

.amsearch-result-tabs .data.content[aria-hidden='false'] {
    display: block
}

.amsearch-result-tabs .data.title {
    margin-right: 5px
}

.amsearch-result-tabs .data.title.active {
    background: #d8d8d8;
    box-sizing: border-box
}

.amsearch-result-tabs .data.title.last {
    margin-bottom: 8px
}

.page-header:not(.amsearch-full-width) .amsearch-result-section {
    -webkit-animation: amsearch-animation__fadeIn .3s forwards;
    animation: amsearch-animation__fadeIn .3s forwards
}

.page-header .amsearch-result-section {
    right: 0
}

.amsearch-result-section {
    overflow: hidden;
    overflow-y: auto;
    scrollbar-color: #adadad rgba(255, 255, 255, 0.2);
    scrollbar-width: thin;
    background-color: #fff;
    border: 0 solid transparent;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(84, 95, 125, 0.12), 0 5px 8px rgba(155, 159, 174, 0.1);
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0;
    max-height: 90vh;
    position: absolute;
    top: 100%;
    transition: .3s;
    width: 100%;
    z-index: 99
}

.amsearch-result-section::-webkit-scrollbar {
    background: #f0f0f0
}

.amsearch-result-section::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #adadad;
    cursor: pointer
}

.amsearch-result-section ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff
}

.amsearch-result-section::-webkit-scrollbar {
    width: 4px
}

.amsearch-result-section::-webkit-scrollbar-thumb {
    width: 4px
}

.amsearch-result-section ::-webkit-scrollbar-track {
    width: 4px
}

.amsearch-result-section:empty {
    display: none
}

.amsearch-result-section .amsearch-result-block {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%
}

.amsearch-result-section .amsearch-result-block:empty {
    display: none
}

.amsearch-result-block.-preload {
    display: flex;
    flex-wrap: wrap
}

.amsearch-result-block.-preload:empty {
    display: none
}

.amsearch-result-block.-preload .amsearch-item:first-child:last-child {
    max-width: 100%;
    width: 100%
}

.amsearch-result-block.-preload .amsearch-slider-section {
    width: 100%
}

.amsearch-result-block.-preload .amsearch-products-list {
    display: flex;
    flex-direction: column;
    width: 100%
}

.page-header .amsearch-input-wrapper {
    width: 100%
}

.amsearch-input-wrapper {
    max-width: 100%;
    position: relative;
    transition: .3s
}

.amsearch-input-wrapper.-typed .amsearch-button.-loupe {
    display: none;
}

.amsearch-input-wrapper.-typed .amsearch-input {
    padding-left: 33px;
    padding-right: 33px
}




.amsearch-input-wrapper .amsearch-input:focus::placeholder, .amsearch-input-wrapper .amsearch-input:focus::-webkit-input-placeholder {
    color: transparent
}

.amsearch-input-wrapper .amsearch-input::placeholder, .amsearch-input-wrapper .amsearch-input::-webkit-input-placeholder {
    color: #999
}

.amsearch-input-wrapper .amsearch-button.-close {
    bottom: 0;
    font-size: 1rem;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0
}

.amsearch-input-wrapper .amsearch-button.-loupe {
    border: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    right: 5px;
    top: 0
}

body.-amsearch-overlay-opened .logo, body.-amsearch-overlay-opened .nav-sections {
    z-index: 0
}

.page-header.amsearch-full-width.-opened {
    z-index: 12
}

.amsearch-overlay-block {
    background: rgba(0, 0, 0, 0.19);
    bottom: 0;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 11
}

.amsearch-overlay-block .amsearch-overlay {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%
}

.amsearch-items-section {
    padding: 25px 0 0 0;
    width: 100%
}

.amsearch-items-section:empty {
    display: none
}

.amsearch-item-container {
    flex-basis: 100%;
    padding: 0 0 20px;
    position: relative
}

.amsearch-item-container.-opened {
    padding: 0 0 32px
}

.amsearch-item-container.-page a.amsearch-link {
    color: var(--amsearch-color-text-hover, #3ac39c);
    font-weight: 600
}

.amsearch-item-container.-recent_searches:not(.-closed) .amsearch-block-header {
    padding: 0 20px 15px
}

.amsearch-item-container.-recent_searches .amsearch-tab-items {
    padding: 0 20px
}

.amsearch-item-container.-recent_searches .amsearch-item {
    background: #f5f5f5;
    border: 1px solid transparent;
    border-radius: 25px;
    box-sizing: border-box;
    display: inline-block;
    margin: 0 8px 10px 0;
    order: 1;
    text-decoration: none
}

.amsearch-item-container.-recent_searches .amsearch-item.-match {
    background-color: var(--amsearch-color-hover, #f4f5fa)
}

.amsearch-item-container.-recent_searches .amsearch-item .amsearch-link {
    padding: 6px 15px
}

.amsearch-item-container.-recent_searches .amasty-xsearch-num-results {
    display: none
}

.amsearch-item-container.-popular_searches .amasty-xsearch-num-results {
    display: none;
    font-size: 12px
}

.amsearch-item-container.-brands .amasty-xsearch-num-results {
    color: #ccc;
    display: inline-block;
    float: none
}

.amsearch-item-container .amsearch-tab-items {
    list-style: none;
    margin: 0;
    padding: 0
}

.amsearch-item-container .amsearch-description {
    padding: 8px 20px
}

.amsearch-item-container .amsearch-item {
    cursor: pointer;
    margin: 0;
    overflow: hidden;
    text-transform: capitalize
}

.amsearch-item-container .amsearch-item .amsearch-link {
    color: var(--amsearch-color-text, #3ac39c);
    display: block;
    font-size: 1.4rem;
    font-weight: 400;
    outline: none;
    padding: 8px 20px;
    text-decoration: none
}

.amsearch-item-container .amsearch-item .amsearch-link:hover {
    background: var(--amsearch-color-hover, #f4f5fa);
    color: var(--amsearch-color-text-hover, #3ac39c)
}

.amsearch-item-container .amsearch-item .amsearch-link:focus {
    background: var(--amsearch-color-hover-focus, #f4f5fa);
    color: var(--amsearch-color-text-focus, #3ac39c)
}

.amsearch-related-terms {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0
}

.amsearch-related-terms > .amsearch-title {
    font-weight: 700;
    margin: 0 0 15px 0;
    width: 100%
}

.amsearch-related-terms > .amsearch-item {
    background: #d8eeff;
    border-radius: 20px;
    color: #252525;
    font-size: 12px;
    line-height: 1.3;
    margin: 0 15px 10px 0;
    padding: 5px 15px;
    transition: .1s
}

.amsearch-related-terms > .amsearch-item:hover {
    background: #b7e0ff
}

.amsearch-related-terms > .amsearch-item:hover, .amsearch-related-terms > .amsearch-item:active {
    text-decoration: none
}

.amsearch-related-terms .amsearch-count {
    color: #757575
}

.amsearch-button.-full-close {
    display: none
}

.page-header .amsearch-button.-loupe-trigger {
    background-size: 20px;
    filter: brightness(75%);
    float: right;
    height: 20px;
    margin: 14px;
    width: 20px
}

.page-header.amsearch-full-width .header.content {
    transition: none
}

.page-header.amsearch-full-width .amsearch-full_width-title {
    flex-basis: 100%;
    font-size: 2.6rem;
    font-weight: 300;
    margin: 0;
    padding: 0 0 20px 0;
    z-index: 15
}

.page-header.amsearch-full-width .amsearch-input-wrapper {
    flex-grow: 10;
    width: initial
}

.page-header.amsearch-full-width .amsearch-button.-search {
    flex-grow: 1
}

.page-header.amsearch-full-width .amsearch-wrapper-block {
    display: none
}

.page-header.amsearch-full-width .amsearch-form-block {
    flex-wrap: wrap;
    height: auto;
    justify-content: flex-start;
    min-width: 100%;
    padding: 20px 15px;
    transition: none
}

.page-header.amsearch-full-width .amsearch-button.-full-close {
    bottom: 0;
    margin: 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 98
}

.page-header.amsearch-full-width .amsearch-result-section {
    border-radius: 0;
    border-top: 1px solid #ccc;
    margin: 0;
    transition: none;
    width: 100%
}

.page-header.amsearch-full-width .amsearch-slider-section {
    max-width: none;
    width: 100%
}

.page-header.amsearch-full-width .amsearch-wrapper-block {
    background: #f5f5f5;
    height: 100%;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 14
}

.page-header.amsearch-full-width.-opened {
    height: 134px;
    transition: .1s
}

.page-header.amsearch-full-width.-opened .panel.wrapper {
    display: none
}

.page-header.amsearch-full-width.-opened .header.content {
    align-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    max-width: 100%;
    padding: 0
}

.page-header.amsearch-full-width.-opened .minicart-wrapper {
    display: none
}

.page-header.amsearch-full-width.-opened .amsearch-wrapper-block {
    display: inline-block
}

.page-header.amsearch-full-width.-opened .amsearch-button.-full-close {
    display: inline-flex
}

@media screen and (min-width: 768px) {
    @-webkit-keyframes amsearch-animation__fadeIn {
        0% {
            opacity: 0
        }
        100% {
            opacity: 1
        }
    }
    @-moz-keyframes amsearch-animation__fadeIn {
        0% {
            opacity: 0
        }
        100% {
            opacity: 1
        }
    }
    @-ms-keyframes amsearch-animation__fadeIn {
        0% {
            opacity: 0
        }
        100% {
            opacity: 1
        }
    }
    @-o-keyframes amsearch-animation__fadeIn {
        0% {
            opacity: 0
        }
        100% {
            opacity: 1
        }
    }
    @keyframes amsearch-animation__fadeIn {
        0% {
            opacity: 0
        }
        100% {
            opacity: 1
        }
    }


    .page-header .amsearch-wrapper-block:before {
        content: none
    }
    .amsearch-loader-block {
        right: 30px
    }
    .amsearch-emptysearch-cms {
        padding: 40px
    }
    .amsearch-emptysearch-cms .amsearch-content > .amsearch-title {
        font-size: 54px
    }
    .amsearch-emptysearch-cms .amsearch-text {
        font-size: 22px
    }
    .amsearch-button.-close:hover, .amsearch-button.-close:hover {
        filter: brightness(0%)
    }
    .amsearch-slider-section {
        max-width: 575px
    }
    .amsearch-slider-section.slick-slider {
        padding: 0 40px
    }
    .amsearch-result-section .amsearch-products-section {
        height: 100%
    }
    .amsearch-products-section {
        border-top: none;
        display: flex;
        flex-wrap: wrap
    }
    .amsearch-products-section:not(:first-child) {
        border-left: 1px solid #ccc
    }
    .amsearch-products-section .amsearch-description {
        width: auto
    }
    .amsearch-products-section.-list .amsearch-image {
        max-width: 125px
    }
    .amsearch-products-section.-list .product-item-actions {
        align-content: flex-start;
        display: flex
    }
    .amsearch-products-section.-list .amsearch-description {
        padding-left: 20px
    }
    .amsearch-products-section.-grid:not(.-slider) .amsearch-item {
        max-width: calc(33.33333333%)
    }
    .amsearch-result-tabs .title:hover:before, .amsearch-result-tabs .title.active:before {
        background: #fff;
        bottom: -1px;
        content: '';
        height: 2px;
        left: 1px;
        position: absolute;
        width: calc(100% - 2px)
    }
    .amsearch-result-section {
        min-width: 100%;
        width: inherit
    }
    .amsearch-result-section:not(.-small) {
        flex-wrap: nowrap
    }
    .amsearch-result-block.-preload {
        flex-wrap: nowrap
    }
    .amsearch-result-block.-preload .amsearch-products-list:not(:first-child) {
        border-left: 1px solid #ccc;
        width: calc(100% - 283px)
    }
    .page-header:not(.amsearch-full-width) .amsearch-input-wrapper.-dynamic-width {
        width: 100%;
        height: 100%;
    }

    .amsearch-item-container {
        padding: 0 0 25px
    }
    .amsearch-item-container .amsearch-link {
        padding: 5px 20px
    }
    .amsearch-items-section {
        padding: 25px 0
    }
    .amsearch-items-section:not(:first-child:last-child) {
        max-width: 283px;
        width: 100%
    }
    .amsearch-full_width-title {
        display: none
    }
    .page-header.amsearch-full-width.-opened .ammenu-logo, .page-header.amsearch-full-width.-opened .logo {
        margin: 0 auto 0 4vw;
        z-index: 11
    }
    .page-header.amsearch-full-width.-opened .amsearch-wrapper-block {
        z-index: 10
    }
    .page-header.amsearch-full-width.-opened .amsearch-button.-full-close {
        right: 4vw
    }
    .page-header.amsearch-full-width .amsearch-input-wrapper {
        flex-grow: initial
    }
    .page-header.amsearch-full-width .amsearch-button.-search {
        flex-grow: initial
    }
    .page-header.amsearch-full-width .amsearch-wrapper-block {
        background: #fff;
        display: inline-block
    }
    .page-header.amsearch-full-width:not(.-opened) .amsearch-wrapper-block {
        position: static;
        width: auto
    }
    .page-header.amsearch-full-width .amsearch-form-block {
        height: 100%;
        justify-content: center;
        padding: 0
    }
    .page-header.amsearch-full-width .amsearch-result-section {
        padding: 0 calc(4vw - 15px)
    }
    .page-header.amsearch-full-width .amsearch-products-section.-grid:not(.-slider) .amsearch-item {
        max-width: calc(25%)
    }
    .page-header.amsearch-full-width .amsearch-products-section.-list .amsearch-item {
        width: 50%
    }
    .page-header.amsearch-full-width .amsearch-button.-full-close {
        margin: auto
    }
    .page-header .amsearch-button.-loupe-trigger {
        display: none
    }
    .amsearch-search-page.page-products .columns {
        z-index: 0
    }
    .amsearch-emptysearch-cms {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 40px 70px
    }
    .amsearch-emptysearch-cms > .amsearch-item {
        max-width: 50%
    }
    .amsearch-emptysearch-cms > .amsearch-content {
        padding-right: 10px
    }
    .amsearch-emptysearch-cms .amsearch-content > .amsearch-title {
        font-size: 72px;
        margin: 0 0 20px
    }
    .amsearch-emptysearch-cms .amsearch-text {
        font-size: 28px;
        line-height: 33px
    }
    .amsearch-emptysearch-cms .amsearch-contacts-block {
        font-size: 22px;
        margin-top: 45px
    }
    .amsearch-emptysearch-cms .amsearch-image-block {
        margin-top: 0
    }
    .amsearch-emptysearch-cms .amsearch-contacts-block > .amsearch-title {
        margin-bottom: 10px
    }
    .amsearch-result-block:not(.-preload) .amsearch-products-section {
        width: calc(100% - 283px)
    }
    .amsearch-products-section.-grid:not(.-slider) .amsearch-item {
        max-width: calc(25%)
    }
    .page-header.amsearch-full-width .amsearch-products-section.-grid:not(.-slider) .amsearch-item {
        max-width: calc(20%)
    }
}

.amsearch-form-block {
    border-left: 1px solid #757474;
    height: 92px;
}

.amsearch-button.-compare {
    display: none;
}
