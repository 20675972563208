/*!
 * Webit Theme (https://webitinteractive.ca/)
 * Copyright 2020 Webit interactive Team
 */




//Bootstrap media query mixins : https://eddyerburgh.me/use-bootstrap-4-media-query-mixins
@import "../../../npm/node_modules/bootstrap/scss/functions",
        "../../../npm/node_modules/bootstrap/scss/variables",
        "../../../npm/node_modules/bootstrap/scss/utilities/embed",
        "../../../npm/node_modules/bootstrap/scss/utilities/overflow",
        "../../../npm/node_modules/bootstrap/scss/mixins/_breakpoints";

//Slick Styles
@import "../../../npm/node_modules/slick-carousel/slick/slick.scss",
        "../../../npm/node_modules/slick-carousel/slick/slick-theme.scss";

        //Font Awesome Pro
$fa-font-path: '../webfonts';
@import "../../../npm/node_modules/@fortawesome/fontawesome-pro/scss/fontawesome",
        "../../../npm/node_modules/@fortawesome/fontawesome-pro/scss/regular",
        "../../../npm/node_modules/@fortawesome/fontawesome-pro/scss/light",
        "../../../npm/node_modules/@fortawesome/fontawesome-pro/scss/solid",
        "../../../npm/node_modules/@fortawesome/fontawesome-pro/scss/duotone",
        "../../../npm/node_modules/@fortawesome/fontawesome-pro/scss/brands";


//
// Base Snowdog Mixins
@import "snowdog/styles/mixins/media-queries";

// Vendor libs
@import 'snowdog/styles/vendor/normalize/lib'; // Normalize.css
@import 'vendor/magento-ui/lib'; // Magento UI ------- Override ----------

@import 'variables'; // ------- Override ----------

@import 'blocks/layout'; // ------ Override ----------
@import 'blocks/navigation';  // ------ Override ----------
@import 'blocks/pages'; // ------ Override ----------
@import 'blocks/ampromo';

// Reset default styles with magento-reset
@import 'snowdog/styles/blocks/reset';

// Theme blocks
@import 'snowdog/styles/blocks/actions-toolbar';
@import 'snowdog/styles/blocks/breadcrumbs';
@import 'snowdog/styles/blocks/buttons';
@import 'snowdog/styles/blocks/extends';
@import 'snowdog/styles/blocks/forms';
@import 'snowdog/styles/blocks/icons';
@import 'snowdog/styles/blocks/loaders';
@import 'snowdog/styles/blocks/messages';
@import 'snowdog/styles/blocks/popups';
@import 'snowdog/styles/blocks/price';
@import 'snowdog/styles/blocks/sections';
@import 'snowdog/styles/blocks/tables';
@import 'snowdog/styles/blocks/tooltips';
@import 'snowdog/styles/blocks/typography';

// Components styles (modal/sliding panel)
@import 'snowdog/styles/blocks/components/modals'; // from lib
@import 'snowdog/styles/blocks/components/modals_extend'; // local

// Modules
@import 'snowdog/Magento_AdvancedCheckout/styles/module';
@import 'snowdog/Magento_Braintree/styles/module';
@import 'snowdog/Magento_Bundle/styles/module';
@import 'snowdog/Magento_Catalog/styles/module';
@import 'snowdog/Magento_CatalogEvent/styles/module';
@import 'snowdog/Magento_CatalogSearch/styles/module';
@import 'snowdog/Magento_Checkout/styles/module';
@import 'snowdog/Magento_Customer/styles/module';
@import 'snowdog/Magento_Downloadable/styles/module';
@import 'snowdog/Magento_GiftCard/styles/module';
@import 'snowdog/Magento_GiftCardAccount/styles/module';
@import 'snowdog/Magento_GiftMessage/styles/module';
@import 'snowdog/Magento_GiftRegistry/styles/module';
@import 'snowdog/Magento_GiftWrapping/styles/module';
@import 'snowdog/Magento_GroupedProduct/styles/module';
@import 'snowdog/Magento_Invitation/styles/module';
@import 'snowdog/Magento_LayeredNavigation/styles/module';
@import 'snowdog/Magento_Msrp/styles/module';
@import 'snowdog/Magento_MultipleWishlist/styles/module';
@import 'snowdog/Magento_Multishipping/styles/module';
@import 'snowdog/Magento_Newsletter/styles/module';
@import 'snowdog/Magento_Paypal/styles/module';
@import 'snowdog/Magento_ProductVideo/styles/module';
@import 'snowdog/Magento_Review/styles/module';
@import 'snowdog/Magento_Reward/styles/module';
@import 'snowdog/Magento_Rma/styles/module';
@import 'snowdog/Magento_Sales/styles/module';
@import 'snowdog/Magento_SalesRule/styles/module';
@import 'snowdog/Magento_SendFriend/styles/module';
@import 'snowdog/Magento_Swatches/styles/swatches';
@import 'modules/Magento_Theme/styles/module'; // ------- Override ----------
@import 'snowdog/Magento_Vault/styles/module';
@import 'snowdog/Magento_Weee/styles/module';
@import 'snowdog/Magento_Wishlist/styles/module';
//@import 'snowdog/Magento_PageBuilder/styles/module';

@import 'modules/Magento_Theme/styles/product'; // -


// Widgets
@import 'snowdog/Magento_AdvancedCheckout/styles/widgets';
@import 'snowdog/Magento_Banner/styles/widgets';
@import 'snowdog/Magento_Catalog/styles/widgets';
@import 'snowdog/Magento_CatalogEvent/styles/widgets';
@import 'snowdog/Magento_Cms/styles/widgets';
@import 'snowdog/Magento_MultipleWishlist/styles/widgets';
@import 'snowdog/Magento_VersionsCms/styles/widgets';


@import 'modules/Magento_Theme/styles/header';
// Modules
@import 'modules/Magento_Theme/styles/forms';
@import 'blocks/slider';
@import 'blocks/featured-posts';
@import 'modules/Magento_Theme/styles/home';
@import 'blocks/category-hub';
@import 'modules/Magento_Theme/styles/customer';
@import 'modules/Magento_Theme/styles/faq';
@import 'modules/Magento_Theme/styles/modals';
@import 'modules/Magento_Theme/styles/cart';
@import 'modules/Magento_Theme/styles/checkout';
@import 'modules/Magento_Theme/styles/popup';
@import 'modules/Magento_Theme/styles/blog';
@import 'modules/Magento_Theme/styles/locator';
@import 'modules/Magento_Theme/styles/cms';
@import 'modules/Magento_Theme/styles/formb2b';
//@import 'modules/Magento_Theme/styles/amshopby';
@import 'modules/Amasty_Xsearch/styles/module';
@import 'modules/Magento_Theme/styles/amaffiliate';
@import 'modules/Magento_Theme/styles/print';

