//
//  Navigation variables
//  _____________________________________________

$navigation__background                                  : $primary__color !default;
$navigation__border                                      : '' !default;

$navigation-level0-item__font-size                       : 18 !default;
$navigation-level0-item__font-weight                     : $font-weight__regular !default;
$navigation-level0-item__line-height                     : false !default;
$navigation-level0-item__margin                          : 0 !default;
$navigation-level0-item__padding                         : 8px $indent__xl 8px $indent__m !default;
$navigation-level0-item__text-transform                  : none !default;

$navigation-level0-item__background                      : '' !default;
$navigation-level0-item__border                          : 0 !default;
$navigation-level0-item__color                           : $color-white !default;
$navigation-level0-item__text-decoration                 : none !default;

$navigation-level0-item__active__background              : '' !default;
$navigation-level0-item__active__border-color            : $secondary__color !default;
$navigation-level0-item__active__border-style            : solid !default;
$navigation-level0-item__active__border-width            : 0 0 8px 0 !default;
$navigation-level0-item__active__color                   : '' !default;
$navigation-level0-item__active__text-decoration         : '' !default;

$submenu__background                                     : $color-white !default;
$submenu__border                                         : '' !default;
$submenu__padding-top                                    : $indent__s !default;
$submenu__padding-right                                  : 0 !default;
$submenu__padding-bottom                                 : $indent__s !default;
$submenu__padding-left                                   : 0 !default;
$submenu__font-size                                      : '' !default;
$submenu__font-weight                                    : $font-weight__regular !default;
$submenu-item__line-height                               : 1.3 !default;

$submenu-item__background                                : '' !default;
$submenu-item__border                                    : '' !default;
$submenu-item__color                                     : $primary__color !default;
$submenu-item__text-decoration                           : '' !default;

$submenu-item__active__background                        : '' !default;
$submenu-item__active__border                            : 1px !default;
$submenu-item__active__border-color                      : $secondary__color !default;
$submenu-item__active__border-style                      : solid !default;
$submenu-item__active__border-width                      : 0 0 $submenu-item__active__border 0  !default;
$submenu-item__active__color                             : '' !default;
$submenu-item__active__text-decoration                   : '' !default;

//
//  Desktop navigation
//  ---------------------------------------------

$navigation-desktop__background                          : $navigation__background !default;
$navigation-desktop__border                              : '' !default;
$navigation-desktop__font-size                           : '' !default;
$navigation-desktop__font-weight                         : $font-weight__bold !default;

$navigation-desktop-level0-item__line-height             : 53px !default;
$navigation-desktop-level0-item__margin                  : 0 0 0 0 !default;
$navigation-desktop-level0-item__padding                 : 0 !default;

$navigation-desktop-level0-item__background              : '' !default;
$navigation-desktop-level0-item__border                  : '' !default;
$navigation-desktop-level0-item__color                   : $color-white !default;
$navigation-desktop-level0-item__text-decoration         : none !default;

$navigation-desktop-level0-item__hover__background       : '' !default;
$navigation-desktop-level0-item__hover__border-color    : $secondary__color !default;
$navigation-desktop-level0-item__hover__border-style    : solid !default;
$navigation-desktop-level0-item__hover__border-width    : 0 0 3px !default;
$navigation-desktop-level0-item__hover__color            : $color-white !default;
$navigation-desktop-level0-item__hover__text-decoration  : $navigation-desktop-level0-item__text-decoration !default;

$navigation-desktop-level0-item__active__background      : '' !default;
$navigation-desktop-level0-item__active__border-color    : $secondary__color !default;
$navigation-desktop-level0-item__active__border-style    : solid !default;
$navigation-desktop-level0-item__active__border-width    : 0 0 3px !default;
$navigation-desktop-level0-item__active__color           : $navigation-desktop-level0-item__hover__color !default;
$navigation-desktop-level0-item__active__text-decoration : $navigation-desktop-level0-item__text-decoration !default;

$submenu-desktop__background                             : $color-white !default;
$submenu-desktop__border-width                           : $border-width__base !default;
$submenu-desktop__border-style                           : solid !default;
$submenu-desktop__border-color                           : $border-color__base !default;
$submenu-desktop__box-shadow                             : 0 $indent__xs $indent__xs rgba(0, 0, 0, 0.19) !default;
$submenu-desktop__font-size                              : '' !default;
$submenu-desktop__font-weight                            : $font-weight__bold !default;
$submenu-desktop__min-width                              : 100% !default;
$submenu-desktop__padding                                : 3rem 15vw !default;

$submenu-desktop-arrow                                   : true !default; // [true|false]
$submenu-desktop-arrow__size                             : 10px !default;
$submenu-desktop-arrow__left                             : 20px !default;

$submenu-desktop-item__padding                           : 8px $indent__base !default;
$submenu-desktop-item__background                        : '' !default;
$submenu-desktop-item__border                            : '' !default;
$submenu-desktop-item__color                             : $navigation-desktop-level0-item__color !default;
$submenu-desktop-item__text-decoration                   : '' !default;

$submenu-desktop-item__hover__background                 : '' !default;
$submenu-desktop-item__hover__border                     : '' !default;
$submenu-desktop-item__hover__color                      : $secondary__color !default;
$submenu-desktop-item__hover__text-decoration            : $navigation-desktop-level0-item__text-decoration !default;

$submenu-desktop-item__active__background                : '' !default;
$submenu-desktop-item__active__border-color              : $secondary__color !default;
$submenu-desktop-item__active__border-style              : solid !default;
$submenu-desktop-item__active__border-width              : 0 0 0 3px !default;
$submenu-desktop-item__active__color                     : '' !default;
$submenu-desktop-item__active__text-decoration           : '' !default;
