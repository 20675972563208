//
//  Variables
//  _____________________________________________

$active-nav-indent: 54px !default;

.panel.header {
    .links,
    .switcher {
        display: none;
    }
}

.nav-sections {
    @include lib-css(background, $navigation__background);
}

.nav-toggle {
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size        : 28px,
        $_icon-font-color       : $header-icons-color,
        $_icon-font-color-hover : $header-icons-color-hover
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    left: 15px;
    position: absolute;
    top: 15px;
    z-index: 14;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {
    .navigation {
        padding: 0;
        li{
            a{
                color:$color-white;
            }
            .submenu{
                li{
                    padding: 0 $indent__m;
                    a{
                        color:$primary__color;
                    }
                }
            }
        }
        .level1{
            a{
                font-family: $heading__font-family__base;
                color:$color-white;
                padding: $indent__m 0;                
                border-bottom:1px solid $secondary__color;
            }
            .submenu{
                display: none;
                li{
                    padding: 0;
                    a{
                        color:$primary__color;
                        border-bottom:0 solid $secondary__color;
                        padding: $indent__s 0;         
                    }
                }
            }
        }
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        @include lib-css(transition, top 0.3s, 1);
        overflow: auto;
        position: absolute;
        width: 100%;
        display: none;

        .switcher {
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
            display: inline-block;
            
            color:$color-white;

            .label {
                display: block;
                margin-bottom: $indent__xs;
                color:$color-white;
            }

        }

        .switcher-trigger {
           /* strong {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content  : $icon-down,
                    $_icon-font-size     : 42px,
                    $_icon-font-position : after,
                    $_icon-font-display  : block
                );

                &:after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }*/
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
            position: absolute;
        }

    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            @include lib-css(transition, top 0.3s, 1);
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
         //   left: 80%;
         //   left: calc(100% - #{$active-nav-indent});
        }

        .nav-sections {
            display: block;
       //     $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

      //      @include lib-css(box-shadow, $_shadow, 1);
            z-index: 99;
        }

        .nav-toggle {
            &:after {
             //   background: rgba(0, 0, 0, $overlay__opacity);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
        z-index: 1;
        height: 80%;
    }

    .nav-sections-item-content {
        @include lib-clearfix();
        box-sizing: border-box;
        color: $color-white;
        width: 100%;
        &.active {
            display: block;
        }
        padding: $indent__m 0;
    }

    @include lib-main-navigation();

    .custom-menu-mobile{
        border-top:1px solid $color-gray;
        padding:$indent__s $indent__m;
        /*display: flex;*/
        justify-content: space-between;
        align-items: center;
        width: 100%;
        a{
            text-transform: uppercase;
            color:$color-white;
            font-weight: 700;
        }
        .switcher{
            text-transform: uppercase;
        }
    }

    // switch langue in mobile 
    .page-header .switcher .options.active ul.dropdown, .page-footer .switcher .options.active ul.dropdown{
        display: block !important;
        border-top: 2px solid $secondary__color;
        top: -100px;
        left: -10px;
        li{
            padding: 5px 10px;
        }
    }
    .nav-before-open body{
        overflow: auto !important;
    }
    .navigation{
        height: 420px;
        overflow: visible;
    }

}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    .nav-toggle {
        display: none;
    }

    .nav-sections {
        display: inline-block;
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content > * {
        display: none;
    }

    .nav-sections-item-content {
        > .navigation {
            display: block;
        }
    }

    @include lib-main-navigation-desktop();

    .navigation {
        padding: 0;

        li{
            .submenu{
                left: 0 !important;
                li{
                    margin-right: $indent__s;
                    a{
                        font-family: $font-family__base;
                        color:$primary__color;
                        padding: $indent__s 0;                
                        border-bottom:2px solid $secondary__color;
                    }            
                    .submenu{
                        top: 0 !important;
                        padding-top:$indent__s;
                        li{
                            margin-right:0;
                            a{
                                font-family: $heading__font-family__base;
                                color: $color-black01;
                                padding: $indent__s 0;                
                                border-bottom:0 solid $secondary__color;
                                font-weight: 400;
                                &:hover{
                                    color:$secondary__color;
                                }
                            }
                            &.active{
                                a {
                                    border-bottom: 1px solid $secondary__color;
                                    border-left: none;
                                }
                            }
                        }
                    }
                }
            }
            >.level-top{
                font-weight: 400;
            }
        }

        ul{
            &.level0{
                display: none;
            }
        }
    }
}


.navigation {
    li .submenu{
        z-index: 999;

        .active>a{
            border-bottom: 4px solid $secondary__color;
            border-left: none;
        }

        li a{
            border-bottom: 2px solid $secondary__color;
        }
    }
}