// add Width libre
@mixin lib-width-block1280() {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
}

body.formcreateprod_fr-index-index, body.formcreateprod-index-index {
    .breadcrumbs, .about__header {
        background: $color-white-dark-smoke;
    }
    .breadcrumbs {
        li strong {
            color: $color-black;
        }
        .items {
            a {
                color: $color-black;
            }
        }
    }
    .about__header {
        .block-contenu {
            display: flex;
            align-items: center;
            h1 {
                padding-top: 0;
                margin-bottom: 0;
            }
        }
    }
    .block-info {
        @media screen and (max-width: 1024px){
            padding-top: 0 !important;
        }
    }
}

.formcreateprod_fr-index-index,
.formcreateprod-index-index {
    .breadcrumbs {
        max-width: 100%;
        padding: 3.3rem;
        margin: 0;
        font-family: $font-family__montserrat;

        .items {
            @include lib-width-block1280();
            padding-left: 2rem;
            padding-right: 2rem;
            width: auto;
            color: $color-white;
            @include lib-font-size(16);

            a {
                color: $color-white;
            }
        }
    }

    h1 {
        padding-top: 5rem;
    }

    .about__header {

        .vitrine {
            //@include lib-width-block1280();
            padding: 3rem 0 6rem;
            @media (max-width: $screen__l) {
                padding: 3rem 0rem 3rem;
            }
            @media (max-width: $screen__m) {
                padding: 2rem 2rem 2rem;
            }


            .block-contenu {

                a.btn.btn-default {
                    @include lib-button-secondary();
                    border-radius: 1rem;
                    padding: 1.2rem 2.5rem;
                    border: 2px solid $secondary__color;
                    height: 5rem;
                    margin-top: 2rem;

                    &:hover {
                        text-decoration: none;
                        padding: 1.3rem 2.6rem;
                    }
                }
            }

            .block-img {
                background-image: url("../../images/silverwax_a-propos_image_bg-entete.jpg");
                background-repeat: no-repeat;
                background-size: 80%;
                background-position-y: 100%;
                background-position-x: 100%;

                img {
                    padding-right: 20px;
                    padding-bottom: 20px;

                    @media (max-width: $screen__l) {
                        max-width: 100%;
                        margin-left: 0;
                    }

                }
            }
        }
    }
}


.cms {
    &-about.cms-page-view,
    &-page-view.cms-a-propos {
        .breadcrumbs {
            max-width: 100%;
            background-color: $primary__color;
            padding: 3.3rem;
            margin: 0;
            font-family: $font-family__montserrat;

            .items {
                @include lib-width-block1280();
                padding-left: 2rem;
                padding-right: 2rem;
                width: auto;
                color: $color-white;
                @include lib-font-size(16);

                a {
                    color: $color-white;
                }
            }
        }

        h1 {
            padding-top: 5rem;
        }
    }


    //page About, page A propos
    &-about,
    &-a-propos,
    &-formcreateprod_fr-index-index {
        .about__header {
            background-color: $primary__color;

            .vitrine {
                //@include lib-width-block1280();
                padding: 3rem 0 6rem;

                @media (max-width: $screen__m) {
                    padding: 3rem 2rem 6rem;
                }

                .block-contenu {
                    color: $color-white;

                    a.btn.btn-default {
                        @include lib-button-secondary();
                        border-radius: 1rem;
                        padding: 1.2rem 2.5rem;
                        border: 2px solid $secondary__color;
                        height: 5rem;
                        margin-top: 2rem;

                        &:hover {
                            text-decoration: none;
                            padding: 1.3rem 2.6rem;
                        }
                    }
                }

                .block-img {
                    background-image: url("../../images/silverwax_a-propos_image_bg-entete.jpg");
                    background-repeat: no-repeat;
                    background-size: 80%;
                    background-position-y: 100%;
                    background-position-x: 100%;

                    img {
                        padding-right: 20px;
                        padding-bottom: 20px;

                        @media (max-width: $screen__l) {
                            max-width: 100%;
                            margin-left: 0;
                        }

                    }
                }
            }
        }

        .about__mission {
            background-image: url("../../images/bg-3-cat-interieur.jpg");
            background-repeat: no-repeat;
            background-size: cover;

            .mission {
                @include lib-width-block1280();
                padding: 10rem 2rem;

                color: $color-white;

                h2 {
                    margin-bottom: 4rem;
                }
            }
        }

        .histoire-content {
            padding-top: 8rem;
            @media (max-width: $screen__m) {
                padding-top: 0rem;
            }

            .contenu-header {
                text-align: center;
                @media (max-width: $screen__m) {
                    text-align: left;
                }

                h2 {
                    font-weight: 500;
                    @media (max-width: $screen__m) {
                        margin-top: 3rem;
                        margin-bottom: 3rem;
                    }
                }
            }

            .histoire {
                padding-top: 10rem;
                @media (max-width: $screen__m) {
                    padding-bottom: 10rem;
                }

                .item {
                    height: 22rem;

                    @media (max-width: $screen__xs) {
                        height: auto;
                        border-left: 4px solid $color-white1;
                        margin-left: 9.5rem;
                        padding-bottom: 5rem;

                        &:after {
                            content: "";
                            background-color: $primary__color;
                            width: 4px;
                            height: 5rem;
                            position: absolute;
                            margin-left: -5px;
                        }
                    }

                }

                .separateur-left {
                    border-left: 4px solid $color-white1;

                    &:after {
                        content: "";
                        background-color: $primary__color;
                        width: 4px;
                        height: 5rem;
                        position: absolute;
                        margin-left: -19px;
                        top: 0;
                        @media (max-width: $screen__xs) {
                            width: 0px;
                        }
                    }

                    @media (max-width: $screen__xs) {
                        border: none;
                    }
                }

                .separateur-right {
                    border-right: 4px solid $color-white1;

                    &:after {
                        content: "";
                        background-color: $primary__color;
                        width: 4px;
                        height: 5rem;
                        position: absolute;
                        margin-left: 0;
                        right: -4px;
                        top: 0;
                        @media (max-width: $screen__xs) {
                            width: 0px;
                        }

                    }

                    @media (max-width: $screen__xs) {
                        border: none;
                    }
                }

                .year {
                    @media (max-width: $screen__xs) {
                        // order: 1;
                        &.col-md-6 {
                            height: 0px;
                        }
                    }

                    .right {
                        text-align: right;
                        @media (max-width: $screen__xs) {
                            text-align: left;
                        }
                    }

                    .left {
                        text-align: left;
                    }

                    p {
                        color: $primary__color;
                        font-family: Montserrat;
                        @include lib-font-size(24);
                        font-weight: 500;

                        @media (max-width: $screen__xs) {
                            margin-left: -80px;
                        }
                    }
                }

                .contenu-img {
                    display: flex;
                    padding-left: 15px;

                    @media (max-width: $screen__xs) {
                        order: 2;
                        display: contents;
                    }

                    picture {
                        width: 60rem;
                        @media (max-width: $screen__m) {
                            width: auto;
                        }
                    }

                    img {
                        width: 15rem;
                        height: 15rem;
                        @media (max-width: $screen__xs) {
                            order: 1;
                            padding-left: 15px;
                        }
                    }

                    p {
                        padding: 0px 12px;
                        @media (max-width: $screen__xs) {
                            order: 2;
                            padding: 15px;
                        }
                    }

                }
            }
        }
    }


    // page 404

    &-noroute-index {
        header,
        footer,
        .header__shipping,
        .page-title-wrapper {
            display: none;
        }

        &#html-body {
            background-image: url(../../images/silverwax_404@3x.png);
            background-repeat: no-repeat;
            background-size: cover;

            @media (max-width: $screen__l) {
                background-image: url(../../images/silverwax_404@2x.png);
                background-size: 100%;
                background-position: bottom;
            }

            @media (max-width: $screen__xs) {
                background-image: url(../../images/silverwax_404.png);
                background-size: 100%;
                background-position: bottom;
            }

        }

        background-color: $primary__color;

        .column.main {
            width: 100%;
        }

        .page-404 {

            margin: 0 auto;
            padding: 10rem 0;
            color: $color-white;


            .contenu {

                text-align: center;
                font-family: $font-family__montserrat;

                .logo {
                    margin-bottom: 15.5rem;
                }

                h1 {
                    font-weight: bold;
                }

                p {
                    @include lib-font-size(22);
                }

                .links {

                    a {
                        padding: 0 10px;
                        text-decoration: underline;
                        @include lib-font-size(16);
                        font-weight: bold;

                        @media (max-width: $screen__xs) {
                            // display: inline-block;
                            width: 40%;
                            display: inline-block;
                            text-align: right;

                            &:first-child {
                                border-right: 1px solid $color-gray-middle1;
                                padding-right: 20px;
                            }
                            &:last-child {
                                border-right: 0;
                                text-align: left;
                            }
                        }


                        &:hover {
                            color: $secondary__color;
                        }

                        &:first-child:after {
                            font-size: 30px;
                            line-height: 16px;
                            content: "|";
                            font-family: "icons-blank-theme";
                            margin-left: 20px;
                            display: inline-block;
                            font-weight: normal;
                            color: $color-gray-middle1;

                            @media (max-width: $screen__xs) {
                                content: none;
                                // margin-left: 0;
                            }

                        }

                    }

                }

            }


        }

    }

}

.cms-page-view {
    p {
        margin-bottom: 1.5rem;
    }

    h3 {
        margin-bottom: 2rem;
    }
}

.formreturnrequest-index-index {
    textarea {
        border: 1px solid $primary__color;
        border-radius: 0;
    }
}

// block contact  bas page cms
.cms-block-contact {
    background-color: $secondary__color;
    padding: $indent__l 0;

    @media (max-width: $screen__xs) {
        padding: 5rem 2.5rem;

    }

    .content {
        @include lib-width-block1280();

        .newsletter {
            &__content {
                h2 {
                    color: $color-white;
                    margin-bottom: 1.5rem;
                    @include lib-font-size(32);
                    font-weight: 500;
                    margin-top: 0;
                }

                p {
                    color: $color-white;
                }

                a {
                    @include lib-button-primary();
                    background: $color-black;
                    border-radius: 1rem;
                    padding: 15px 50px;
                    height: 5.5rem;
                    margin-top: 2rem;

                    &:hover {
                        border: 2px solid $color-white;
                        background: $secondary__color;
                        color: $color-white;
                        text-decoration: none;
                    }
                }
            }

        }
    }
}
