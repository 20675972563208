.modal-popup{
    z-index: 999;

    &.confirm{
        .action{
            &-accept{
                background: $secondary__color;
                border: 1px solid $secondary__color;
                color: $color-white;
                &:hover{
                    background: $color-white;
                    border: 1px solid $secondary__color;
                    color: $primary__color;
                }
            }
            &-dismiss{
                background: $color-white;
                border: 1px solid $primary__color;
                color: $primary__color;
            }
        }

    }



    .modal-inner-wrap{

        .product-current{
            h3{
                @include lib-font-size(32);
                font-family: $font-family__montserrat;
                font-weight: 500;
            }
            .attrib-format{
                display: block;
            }
            .bg-image{
                background-color: $color-gray95;
                padding: 2rem;
            }
            .product-item-shortDescription{
                @include lib-font-size(18);
                color: $primary__color;
                font-weight: lighter;
                font-family: $font-family-name__base;
            }
            .reference{
                @include lib-font-size(16);
                color: $color-gray;
                font-family: $font-family-name__base;
                padding: 10px 0 25px;
            }
            .total{
                @include lib-font-size(18);
                font-family: $font-family__montserrat;
                font-weight: bold;
                
                &.cart{
                    padding-bottom: 2rem;

                    .subTotal{
                        display: block;
                    }
                }
            }
        }

        width: 60%;
        margin: 1rem auto;

        @media (max-width: $screen__xl) {
            width: 70%;
        }
        @media (max-width: $screen__l) {
            width: 80%;
        }
        @media (max-width: $screen__m) {
            width: 100%;
            height: 100%;
            overflow: auto;
        }
        

        .action{
    
            &.primary{
                padding: 14px;
                height: auto;
                min-height: 50px;
                width: 100%;
                text-align: center;
    
            }
    
            &.secondary{
                background: $color-white;
                border: 2px solid $secondary__color;
                color: $color-black;
                margin-top: $indent__m;
        
                &:hover{
                    background: $color-black;
                    color: $color-white;
                }
            }
    
        }

        .modal-header{
            padding-top: 4.8rem;
            .modal-title{
                border-bottom: 0;
            }
        }

        .action-close:before{
            color: $color-black;
            @include lib-font-size(50);
        }

    }

    
    .cart-items-count{
        margin-top: 3rem;
    }
    
    &.popup-add-cart{

        pointer-events: initial;

        .modal-content{
            overflow-y: auto;
            max-height: 600px;
            padding-bottom: 3rem;
        }
    }

    .mini-cart-list{
        border: 1px solid $color-white1;
        padding: $indent__m;
        height: 240px;
        overflow: auto;
        margin-bottom: 2rem;



        table{
            h4{
                font-weight: bold;
                margin: 0 0 0.5rem 0;
            }
            .product-item-shortDescription{
                @include lib-font-size(14);
                padding-bottom: 1.5rem;
                color: $color-gray40;

            }
            tr{
                border-bottom: 1px solid $color-white1; 
            }
            img{
                width: 100px;
            }

            .qty{
                .price{
                    @include lib-font-size(16);
                    color: $color-gray-darken4;
                    font-weight: bold;
                }
                input[type="number"]{
                    border: 1px solid $color-gray60 ;
                    border-radius: 5px;
                    @include lib-font-size(14);
                    width: 30%;
                }
                input[type="number"]::-webkit-inner-spin-button {
                    opacity: 1
                  }
            }

            .block-action{
                .price{
                    @include lib-font-size(20);
                    font-weight: bold;
                    padding: 0 $indent__s;
                    text-align: right;
                }
                .action{
                    margin-top: 4rem;
                    text-align: right;
                    span{
                        margin-top: $indent__m;
                        @include lib-font-size(12);
                        padding: 0 $indent__s;
    
                        &:first-child{
                            border-right: 1px solid $color-gray80 ;
                        }
                        a{
                            color: $color-gray20 ;
                            font-weight: 500;
                            @include lib-font-size(14);
                            font-family: $font-family__montserrat;
                            text-decoration: underline;

                        }
                    }
                }
                
            }

        }

    }

    .footer-table{
        text-align: center;
        padding-top: $indent__m;
        text-decoration: underline;
        font-weight: bold;
    }

    .Modal-related{
        margin: 0;
        .products{
            margin: 0;
        }
        .block-image{
            position: relative;
        }

    }

    .products-Modal-related{
        .product{
            &-item-category{
                @include lib-font-size(14);
                padding-bottom: 0.5rem;
                color: $color-gray40;
                font-weight: 600;
            }
            &-item-name{
                @include lib-font-size(14);
                font-weight: 500;
                font-family: $font-family__montserrat;
            }
            &-item-shortDescription{
                @include lib-font-size(12);
                color: $color-gray40;
            }
            .price-box{
                margin: 5px 0 10px;
                display: flex;
                .special-price{
                    padding-right: 1rem;
                }
            }
        }
    }



    @media (max-width: $screen__m) {
        &._show{

            .modal-inner-wrap{
                height: initial;
            }

            /**
            * popup mobile
            **/
            &.popup-add-cart{

                .modal-title{
                    @include lib-font-size(25); 
                    font-weight: 500;
                    width: 90%;
                }
                .modals-overlay{
                    background-color: transparent;
                }
                .modal-inner-wrap{

                    bottom: 0;
                    margin: 0;

                    .modal-header{
                        padding-top: 3.8rem;
                    }

                    .action-close{
                        top: 20px;
                        &:before{
                            @include lib-font-size(80);
                        }
                    }

                    .modal-content{
                        overflow-y: auto;
                        max-height: 380px;

                        .product-current{
                            h3{
                                @include lib-font-size(20);  
                            }
                            .attrib-format{
                                margin-top: 1.5rem;
                            }
                            .reference{
                                padding: 0px 0 10px;
                            }

                            .bg-image{
                                width: 50%;
                            }
                            .block-name{
                                width: 50%;
                            }
                            .subTotal{
                                display: initial;
                            }
                            .block-action{
                                width: 100%;
                                padding: 3rem 0;
                                margin-top: 2rem;
                                border-top: 1px solid $color-gray80;

                                .action.primary{
                                    width: 100%;
                                    text-align: center;
                                }
                                .action.primary.secondary{
                                    display: none;
                                }
                            }

                        }
                        .product-item-shortDescription,
                        .restRes,
                        .cart-items-count,
                        .mini-cart-list,
                        .Modal-related{
                            display: none;
                        }
                    }
                }
                
            }
        }
    }

}