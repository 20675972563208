#store-pickup.selected-store-pickup~#shipping,
#store-pickup.selected-store-pickup~#opc-shipping_method {
    visibility: hidden;
    height: 0;
    overflow: hidden;
}

#opc-store-selector-popup {
    display:none;
}

.ampromo-overlay.-hide {
    display:none;
}

#registration { clear: both; }

.opc-block-summary{
    border: 1px solid $color-gray79;
    padding: 25px 20px;
    .title{
        border-bottom: 0;
        margin: 0;
        font-weight: bold;
        font-family: $font-family__montserrat;
        @include lib-font-size(18);
    }
    .totals .mark strong{
        font-family: $font-family-name__base;
        font-weight: bold;
    }

}
.opc-progress-bar{
    margin: 40px 0 22px;
    &-item{
        width: 260px;
        &>span:after{
            margin-left: -40%;
        }
        &>span:before{
            margin-left: -42%;
        }
    }
}


.opc-progress-bar-item>span{
    @include lib-font-size(18);
    font-weight: 600;
    font-family: $font-family-name__base;
}

.opc-progress-bar-item._active:before,
.opc-progress-bar-item._active>span:before{
    background: $primary__color;
}
.opc-progress-bar-item._active>span:before{
    height: 37px;
    width: 37px;
}
.opc-progress-bar-item._active>span:after{
    content: counter(i);
    counter-increment: i;
    @include lib-font-size(22);
    font-weight: 600;
    font-family: $font-family__montserrat;
    padding: 4px;
    height: 27px;
    width: 26px;
    top: 5px;
    @include max-screen($screen__m) {
        width: 28px;
    }
}


.opc-progress-bar-item._complete>span:before{
    background: $secondary__color;
}
.opc-progress-bar-item._complete>span:after{
    content: "\f00c";
    @include lib-font-size(18);
    color: $secondary__color;
    font-family: 'Font Awesome 5 Pro';
    padding: 4px;
    height: 28px;
    width: 27px;
    top: 5px;
    @include max-screen($screen__m) {
        width: 30px;
    }
}
.opc-progress-bar-item>span:after{
    @include lib-font-size(22);
    font-weight: 600;
    font-family: $font-family__montserrat;
    padding: 1px;
    height: 31px;
    width: 28px;
    top: 3px;
}

.opc-progress-bar-item:before{
    background: $color-gray60;
    height: 3px;
}
.shipping-address-items{

    button.action.action-select-shipping-item{
        &:hover{
            background: $secondary__color;
        }
    }
}
.checkout-shipping-address{
    .action-show-popup{
        background: $color-white;
        border: 1px solid $color-black;
        color: $color-black;
        @include lib-font-size(14);
    }
    .shipping-address-item.selected-item{
        border-color: $primary__color;
        &:after{
            background: $primary__color;
        }
    }
}

.checkout-shipping-method{
    .step-title{
        font-weight: bold;
    }
}

#checkout-step-shipping_method{
    .action.primary{
        background: $color-white;
        border: 1px solid $secondary__color;
        color: $primary__color;

        &:hover{
            background: $secondary__color;
            border: 1px solid $secondary__color;
            color: $color-white;
        }
    }

}

#checkoutSteps{
    form {
        &.form-login{
            max-width: 100%;
            .actions-toolbar{
                .secondary{
                    margin-right: 20px;
                    @include max-screen($screen__m) {
                        margin-right: 0;
                    }
                }
            }
            .field.required{
                .label{
                    display: block !important;
                }
                input[type="email"]{
                    width: 50%;
                    @include max-screen($screen__m) {
                        width: 80%;
                    }
                }
                .field-tooltip.toggle{
                    right: 40%;
                    @include max-screen($screen__m) {
                        right: 0;
                    }
                }
            }
        }
        &.payments{
            .billing-address-form{
                max-width: 100%;
            }

        }

        .fieldset .field:not(.choice)>.label{
            display: none;
        }

        #shipping-new-address-form{
            flex-wrap: wrap;
        }

        &.form-shipping-address{
            max-width: 100%;

            .field{
                &[name="shippingAddress.street.2"]{
                    display: none;
                }
            }

        }

    }



    #shipping-new-address-form{

        display: flex;

        .field{
            &[name="shippingAddress.firstname"],
            &[name="shippingAddress.lastname"],
            &[name="shippingAddress.city"],
            &[name="shippingAddress.postcode"],
            &[name="shippingAddress.telephone"],
            &[name="shippingAddress.fax"]{
                width: 50%;
                padding: 0 10px;
                @include max-screen($screen__m) {
                    width: 100%;
                }
            }
            &[name="shippingAddress.company"],
            &[name="shippingAddress.street.0"],
            &.street{
                width: 100%;
            }
            &[name="shippingAddress.region_id"]{
                margin-left: 20px;
                width: 50%;
                @include max-screen($screen__m) {
                    width: 100%;
                }
            }


        }

    }

    .actions-toolbar{
        .action{
            &-update{
                background: $secondary__color;
                border: 1px solid $secondary__color;
                color: $color-white;
                &:hover{
                    background: $color-white;
                    border: 1px solid $secondary__color;
                    color: $primary__color;
                }
            }

        }

    }

    .payment-option{
        &-title{
            @include lib-font-size(18);
        }
        .action-apply{
            background: $color-white;
            border: 1px solid $secondary__color;
            color: $primary__color;
        }
    }

}

.table-checkout-shipping-method{
    width: 100%;
    tr.row{
        margin-left: 0px;
    }
}

#checkout-step-shipping_method{

    input[type='radio']:after {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: transparent;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid $color-black;
        padding: 0;
        box-shadow: inset 0px 0px 0px 8px $color-white;
    }

    input[type='radio']:checked:after {
        background-color: $secondary__color;
        box-shadow: inset 0px 0px 0px 3px $color-white;
    }

}


// Page : multishipping/checkout/addresses/

.multishipping-checkout-addresses{

    table#multiship-addresses-table{

            .col{
                max-width: 20%;
                width: 20%;
                @include max-screen($screen__m) {
                    max-width: 100%;
                    width: 100%;
                }
                select{
                    background-position: center right 0rem;
                    background-size: 1.5rem;
                    padding: 5.5px 20px 4px 10px;
                }
                &.actions{
                    text-align: right;
                    @include max-screen($screen__m) {
                        text-align: left;
                    }
                }
            }

            @include max-screen($screen__m) {
                tbody {
                    tr{
                        border-bottom: 1px solid $color-gray80;
                    }
                }
            }


    }

    .actions-toolbar{
        display: grid;

        .primary{
            width: 50%;
            @include max-screen($screen__m) {
                width: 100%;
            }
            .action.continue {
            padding: 11px 25px !important;
                @include max-screen($screen__m) {
                    height: 7rem;
                }
            }
            button.action.continue{
                width: 100%;
            }
        }
        .secondary{
            width: 50%;
            float: none;
            display: inline-block;
            text-align: left;
            @include max-screen($screen__m) {
                width: 100%;
            }
            button{
                &:hover{
                    background: $color-white;
                    border: 1px solid $color-black;
                    color: $color-black;
                }
            }
            .action{
                margin-left: 3px;
                &.back{
                    text-align: center;
                }
            }

        }
    }

}

.checkout-index-index{

    .form.form-shipping-address {
        #shipping-new-address-form {
            select.select {
                background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhciIgZGF0YS1pY29uPSJjaGV2cm9uLWRvd24iIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jaGV2cm9uLWRvd24gZmEtdy0xNCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik00NDEuOSAxNjcuM2wtMTkuOC0xOS44Yy00LjctNC43LTEyLjMtNC43LTE3IDBMMjI0IDMyOC4yIDQyLjkgMTQ3LjVjLTQuNy00LjctMTIuMy00LjctMTcgMEw2LjEgMTY3LjNjLTQuNyA0LjctNC43IDEyLjMgMCAxN2wyMDkuNCAyMDkuNGM0LjcgNC43IDEyLjMgNC43IDE3IDBsMjA5LjQtMjA5LjRjNC43LTQuNyA0LjctMTIuMyAwLTE3eiI+PC9wYXRoPjwvc3ZnPg==");
                background-repeat: no-repeat;
                background-position: center right 1rem !important;
                background-size: 20px !important;
            }
        }
    }

    .pc-wrapper{
        width: 75%;
    }
    .opc-sidebar{
        width: 25%;


    }

    .header__block-wrapper-menu-bottom.fixed{
        display: none;
    }

    .modal-popup {

        .form-shipping-address {
            max-width: 100%;
        }

        .modal-inner-wrap{
            .modal-footer{
                .action{
                    &.action-hide-popup{
                        margin-top: 0;
                    }
                    &.action-save-address{
                        height: 40px;
                        padding: 10px;
                    }
                }
            }
        }
    }

    #opc-sidebar{
        .opc-block-shipping-information{
            padding: 25px 0px;

            .ship-to,
            .ship-via{
                .shipping-information-title{
                    border-bottom: 0;
                    margin: 0;
                    font-weight: bold;
                    font-family: $font-family__montserrat;
                    @include lib-font-size(18);
                }
            }
            .action.action-edit{
                &:before{
                    color:$primary__color;
                }
            }
        }
    }

    #checkoutSteps #shipping-new-address-form .field[name="shippingAddress.firstname"],
    #checkoutSteps #shipping-new-address-form .field[name="shippingAddress.lastname"],
    #checkoutSteps #shipping-new-address-form .field[name="shippingAddress.city"],
    #checkoutSteps #shipping-new-address-form .field[name="shippingAddress.postcode"],
    #checkoutSteps #shipping-new-address-form .field[name="shippingAddress.telephone"],
    #checkoutSteps #shipping-new-address-form .field[name="shippingAddress.fax"]{
        padding: 0 10px 0 0;
    }

    #tooltip-label {display:none;}

   .block-payment-option{
        border: 1px solid $color-gray79;
        padding: 25px 20px;

        .payment-option-title{
            font-weight: bold;
            font-family: $font-family__montserrat;
            @include lib-font-size(18);
        }

        .form-discount{
            display: flex;
            padding-top: 20px;

            .payment-option-inner{
                width: 50%;

                label{
                    display: none;
                }

                input[type="text"]{
                    border: 1px solid $color-gray-middle4;
                    border-radius: 10px 0 0 10px;
                    height: 5rem;
                    &::placeholder{
                        font-size: 14px !important;
                    }
                }
            }

            button.action-apply{
                background: $secondary__color;
                border: 1px solid $secondary__color;
                color: $color-white;
                padding: 10px 20px !important;
                height: 5rem;
                border-radius: 0 10px 10px 0;
            }

            button.action-cancel{
                border-radius: 0 10px 10px 0;
                padding: 11px 0 !important;
                &:hover{
                    border: 1px solid $secondary__color;
                    color: $primary__color;
                }
            }
        }
   }

    #checkout-step-payment {
        .payment-option{
            display: none;

            &.um-ordercomment {
                display:block;
                padding: 0px;
                border:none;
                margin-bottom: 25px;
                background-color: transparent;

                .payment-option-title {
                    display:none;
                }

                .payment-option-content {
                    padding:0;
                }

                .form-discount {
                    display:block;
                    padding:0;

                    .payment-option-inner {
                        width:100%;

                        .um-order-comment {
                            border-bottom: 1px solid $color-gray19;
                            border-radius: 0;
                            padding: 0 5.5px;
                            max-height: 40px;
                            line-height: 2;
                            resize: none;
                            font-size: 1.8rem;
                            background:transparent;


                        }
                    }
                }
            }
        }
    }

    /// Formulaire

    .modal-popup{
        .form-shipping-address,
        .form-shipping-method{

            .fieldset .field:not(.choice)>.label {
                display: none;
            }
            .field.additional{
                &[name="shippingAddress.street.2"]{
                    display: none;
                }
            }
            .field{
                &[name="shippingAddress.postcode"],
                &[name="shippingAddress.city"],
                &[name="shippingAddress.region_id"]{
                    width: 100% !important;
                }
            }

            #shipping-new-address-form{
                .field[name="shippingAddress.country_id"]{
                    display: none;
                }
                .field[name="shippingAddress.region_id"]{
                    select{
                        border: none;
                        border-bottom: 1px solid $color-gray19;
                        border-radius: 0;
                    }
                }
            }
        }

        // footer modal
        .modal-footer{
            .action-save-address{
                width: auto;
            }
            .action-hide-popup{
                height: 5rem;
            }
        }
    }

    #shipping, #payment{

        .form{
            &-shipping-address,
            &-shipping-method{

                .field{
                    &[name="shippingAddress.country_id"]{
                        display: none;
                    }
                    &[name="shippingAddress.region_id"]{
                        margin-left: 1px;
                    }
                    //order
                    &[name="shippingAddress.firstname"]{
                        order: 1;
                    }
                    &[name="shippingAddress.lastname"]{
                        order: 2;
                    }
                    &[name="shippingAddress.company"]{
                        order: 3;
                    }
                    &.street{
                        order: 4;
                    }
                    &[name="shippingAddress.postcode"]{
                        order: 5;
                        width: 33% !important;
                        @include max-screen($screen__m) {
                            width: 100% !important;
                        }
                    }
                    &[name="shippingAddress.city"]{
                        order: 6;
                        width: 33% !important;
                        @include max-screen($screen__m) {
                            width: 100% !important;
                        }
                    }
                    &[name="shippingAddress.region_id"]{
                        order: 7;
                        width: 33% !important;
                        margin-left: 0 !important;
                        @include max-screen($screen__m) {
                            width: 100% !important;
                        }

                        select{
                            border: 0;
                            border-bottom: 1px solid $color-black;
                            border-radius: 0;
                            padding: 0 25px 0 0;
                            background-position: center right 0rem;
                            background-size: 1.5rem;
                            color: $color-gray;
                        }

                    }
                    &[name="shippingAddress.telephone"]{
                        order: 8;
                    }
                    &[name="shippingAddress.fax"]{
                        order: 9;
                    }
                }


            }

        }
    }



    .modal-custom{
        &.authentication-dropdown{

            form{
                .actions-toolbar{
                    .primary,
                    .secondary{
                        width: 100%;
                    }
                }

                .action-login{
                    &:hover{
                        border: 2px solid $secondary__color;
                        color: $secondary__color;
                    }
                }
            }

        }
    }


    /*** payment-methods ***/
    .payment-methods{

        .payment-method,
        .payment-method._active{

            .payment-method{
                &-title{
                    display: flex;

                    label{
                        margin-top: -5px;
                        padding-left: 10px;
                        display: flex;
                        .credit-card-types{
                            order: 2;
                        }
                        >span{
                            order: 1;
                            font-weight: bold;
                            @include lib-font-size(18);
                            padding: 0 20px 0 10px;
                        }
                    }
                }
                &-content{
                    form{

                        .rootways_bamboratoken_option{

                            display: grid;
                            grid-template-columns: 1fr 1fr;

                            @include max-screen($screen__m) {
                                display: block;
                                margin-bottom: 5rem;
                            }



                            .field{
                                /*label{
                                    display: block !important;
                                    color: $color-gray37;
                                }*/
                                &.type{
                                    order: 2;
                                    .control{
                                        margin-left: 0;
                                    }
                                }
                                &.number{
                                    order: 1;
                                    input{
                                        width: 370px;
                                    }
                                }
                                &.date{
                                    order: 3;
                                }
                                &.cvv{
                                    order: 4;
                                    input{
                                        width: 100px;
                                    }
                                }
                            }

                            select{
                                width: 120px;
                                background-position: center right 0.5rem;
                                background-size: 1.2rem;
                                border: none;
                                border-bottom: 1px solid $color-gray19;
                                border-radius: 0;
                            }

                        }


                    }

                    .actions-toolbar{
                        .primary {
                            float: initial;
                        }
                    }


                    .payment-method-billing-address{

                        .billing-address-details{
                            width: 40%;
                            border: 2px solid $primary__color;
                            padding: 10px;
                            @include max-screen($screen__m) {
                                width: 90%;
                            }

                            button.action.action-edit-address{
                                background: $secondary__color;
                                border: 1px solid $secondary__color;
                                color: $color-white;
                                &:hover{
                                    background: $color-white;
                                    border: 1px solid $secondary__color ;
                                    color: $primary__color;
                                }
                            }
                        }

                        select[name="billing_address_id"]{
                            padding: 5.5px 35px 4px 15px;
                            background-size: 1.2rem;
                            border: none;
                            border-bottom: 1px solid $color-gray19;
                            border-radius: 0;
                            width: initial;
                        }
                        .actions-toolbar{
                            .primary{
                                display: flex;
                                float: none;
                                .action{

                                    @include max-screen($screen__m) {
                                        width: 50%;
                                    }

                                    &-update{

                                    }
                                    &-cancel{
                                        background: $color-white;
                                        border: 1px solid $secondary__color;
                                        color: $primary__color;
                                        text-align: center;
                                        margin: 0 10px;
                                        border-radius: 10px;
                                    }
                                }
                            }
                        }

                        .billing-address-form{
                            .field{
                                &[name="billingAddressrootways_bamboratoken_option.country_id"],
                                &[name="billingAddressrootways_bamboratoken_option.street.2"]{
                                    display: none;
                                }
                                &[name="billingAddressrootways_bamboratoken_option.region_id"]{
                                   select{
                                        border: none;
                                        border-bottom: 1px solid $color-gray19;
                                        border-radius: 0;
                                   }
                                }

                            }
                        }
                    }
                }


            }

        }

    }

    .form-login{
        .field-tooltip .field-tooltip-content{
            left: 40px;
            top: 16px;
        }
    }

    .form-shipping-address{
        .field-tooltip .field-tooltip-content{
            left: 90px;
            top: -6px;
        }
    }

    .action{
        &.action-select-shipping,
        &.action-select-store-pickup{
            &:hover,&:focus,&:active{
                border: 1px solid $secondary__color;
                color: $primary__color;
            }
        }
    }
    #checkout-step-store-selector {
        form.form-continue{
            .actions-toolbar{
                .secondary{
                    .action{
                        height: 5rem;
                        padding: 1.1rem 3.3rem;
                    }
                }
                .action{
                    &:hover,&:focus,&:active{
                        border: 1px solid $secondary__color;
                        color: $primary__color;
                    }
                }
            }
        }
    }

    /** store pickup **/

    #store-pickup{
        #delivery-method-selector{
            .action{
                &.action-select-shipping,
                &.action-select-store-pickup{
                    height: 5rem;
                    padding: 1.1rem 3.3rem;
                    background: $color-gray40;
                    @include max-screen($screen__xs) {
                        width: 100%;
                        margin-bottom: 1rem;
                    }
                }
                &.selected{
                    background: $color-white;
                    color: $primary__color;
                    border: 2px solid $secondary__color;
                }
            }
        }
    }


}


/** Mobile **/
@include max-screen($screen__m) {

    .checkout-index-index{

        .opc-progress-bar{
            &-item{
                width: 50%;
            }
        }

        .opc-estimated-wrapper .minicart-wrapper{
            display: none;
        }
        .modal-popup.modal-slide{
            left: 0;
        }

        .field-tooltip .field-tooltip-content{
            left: auto !important;
            top: auto !important;
            &:before,
            &:after{
                content: none;
            }
        }

        .modal-custom {
            &.authentication-dropdown{
                width: 100%;
                max-width: 100%;
                left: 0;
                .action-close:before{
                    color: $color-black;
                    font-size: 50px;
                }
            }
        }

    }
}
// pickup
#opc-store-selector-popup{
    .store-selector-search{
        input[id="store-selector-search-input"]{
            border: 1px solid $secondary__color;
        }
    }

}
// message checkout success
.checkout-onepage-success{
    .message_suuccess{
        color: $color-red11 ;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        position: relative;
        padding: 2rem 1rem;
        margin:3rem 0;
        border: 1px solid $color-red10;
        border-radius: 0.25rem;
        p{
            margin: 0;
        }
    }
}
@media (max-width: $screen__l) and (min-width: $screen__m) {
    .checkout-cart-index {
        .cart-container {
            .form-cart {
                width: 71%;
            }
            #shopping-cart-table {
                .item-actions {
                    .actions-toolbar {
                        margin-bottom: 0;
                    }
                }
                .cart.item {
                    .item-info {
                        .input-text.qty {
                            width: 50px;
                        }
                        .product-image-container {
                            width: 80px !important;
                        }
                    }
                }
            }
            .cart-view-container-summary {
                width: 28%;
            }
        }
    }
}
