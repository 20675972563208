//Const
$popup_top : 155px !default;

.block-popup{
    &-login{
        .links.account{
            padding: $indent__l 24px 38px 24px;
            color: $color-white;
            border-right: 1px solid $color-gray46;

            &:before{
                content: "\f007";
                @include lib-font-size(20);
            }
            &.active{
                color: $secondary__color;
                border-bottom: 2px solid $secondary__color;
                padding: 33px 24px 36px 24px;
            }
        }

        .form-popup {
            display: none;
            position: absolute;
            top: $popup_top;
            right: 18%;
            border: 1px solid $color-gray82;
            z-index: 100;
            background: $color-white;
            padding: $indent__l $indent__m;
            width: 360px;
            @include max-screen($screen__l) {
                right: 10%;
                top: auto;
            }
          }

    }



    &-wishlist{

        .links.wishlist{
            padding: $indent__l 24px 38px 24px;
            color: $color-white;
            border-right: 1px solid $color-gray46;
            @include lib-font-size(20);

            &.active{
                color: $secondary__color;
                border-bottom: 2px solid $secondary__color;
                padding: 33px 24px 36px 24px;
            }
        }

        .wishlist-popup {
            display: none;
            position: absolute;
            top: $popup_top;
            right: 20%;
            border: 1px solid $color-gray82;
            z-index: 9;
            background: $color-white;
            padding: $indent__l $indent__m;
            width: 400px;
            @include max-screen($screen__l) {
                right: 15%;
                top: auto;
            }
        }



    }

    &-search{
        .links.search{
            padding: $indent__l 24px 35px 24px;
            color: $color-white;
            border-right: 1px solid $color-gray46;

            &:before{
                @extend %fa-icon;
                @extend .far;
                content: fa-content($fa-var-search);
                font-size: $font-size__l;
                line-height: normal;
            }
            &.active{
                color: $secondary__color;
                border-bottom: 2px solid $secondary__color;
                padding: 33px 24px 33px 24px;
            }
        }

        .search-popup{
            display: none;
            position: absolute;
            top: $popup_top;
            right: 0;
            z-index: 9;
            background: $color-white;
            padding: $indent__l $indent__m ;
            width: 100%;
            margin: 0;
            .contenu{
                margin: 0 auto;
                padding: $indent__m 0px 50px 0px;
                width: 768px;
                @include max-screen($screen__m) {
                    width: 100%;
                }

                input#search{
                    border-bottom: 1px solid $color-black ;
                    border-radius: 0;
                    margin-bottom: 10px;
                    background: transparent;
                    padding-left: 40px;

                    @include max-screen($screen__m) {
                        width: 90%;
                        margin: 0 15px;
                        left: 0;
                        padding-left: 60px;
                    }



                    &:before{
                        @extend %fa-icon;
                        @extend .far;
                        content: fa-content($fa-var-search);
                        font-size: $font-size__l;
                        line-height: normal;
                    }
                }
                .block-content{
                    margin-bottom: 40px;
                }
                .block-title{
                    padding-bottom: 0;
                    margin-bottom: 0;

                }
                ul.sugg{
                    display: flex;
                    flex-wrap: nowrap;
                    margin-left: - $indent__m ;
                    li{
                        border-right: none !important;
                        padding: $indent__m  ;
                        a{
                            color: $secondary__color;
                            @include lib-font-size(14);
                            font-weight: normal;
                            text-decoration: underline;
                        }
                    }
                }
                .fa-search{
                    color: $secondary__color;
                    @include lib-font-size(30);
                    font-weight: 100;
                    position: absolute;
                    padding: $indent__xs 0 0 $indent__xs;
                }
                a{
                    &.adv-search{
                        display: block;
                        text-align: right;
                        @include lib-font-size(12);
                        text-decoration: underline;
                        color: $color-black;

                    }
                    &.cancel{
                        display: block;
                        text-align: right;
                        @include lib-font-size(25);
                        text-decoration: none;
                        color: $color-black;
                        margin-bottom: 6rem;
                    }
                }
            }
        }
    }

}

.block-popup-login,
.block-popup-wishlist,
.block-popup-search{

    .block-customer-login{
        margin-bottom: 0;
    }

    .block-title{
        padding-bottom: $indent__m;
        @include lib-font-size(18);
    }
    ul{
        padding: 0;
        li{
            list-style: none;
            a{
                color: $primary__color;
                @include lib-font-size(16);
                font-weight: bold;
                font-family: Karla;
            }
        }
    }

    .footer{
        margin-top: 15px;
        border-top: 1px solid $color-gray55;
        padding-top: 15px;

    }
    .primary{
        width: 100%;
        }
    a{
        &.link{
            font-weight: bold;
            text-decoration: underline;
            padding-top: 15px;
            display: block;

            &:hover{
                color: $primary__color;
            }
        }
        &.right{
            text-align: right;
        }
    }

    .btn-secondary{
        background: $color-white;
        border: 2px solid $secondary__color;
        color: $primary__color ;
        text-align: center;
        padding: 15px;

        &:hover{
            background: $secondary__color;
            color: $color-white;
            border: 2px solid $secondary__color;
        }
    }

    button{
        &.cancel{
        background-image: none;
        background: $color-white;
        border: none;
        color: $color-black;
        right: 10px;
        display: block;
        position: absolute;
        top: 0;
        }
    }

    .content-wishlist{
        overflow: auto;
        padding: 0px 15px;

        .item{
            border-bottom: 1px solid $color-gray82;
            padding-bottom: $indent__m;
        }
        .name{
            @include lib-font-size(16);
            margin-bottom: 0;
            color: $primary__color;
            font-weight: bold;
            font-family: $font-family-name__base;
        }
        .category{
            @include lib-font-size(12);
            color: $color-gray;
            margin-bottom: 1.2rem;
            font-family: $font-family-name__base;
            font-weight: bold;
        }
        .sous-title{
            @include lib-font-size(14);
            color: $primary__color;
            font-weight: lighter;
            font-family: $font-family-name__base;
        }

        .primary{
                margin-top: 1rem;
            .action{
                padding: 5px 10px !important;
                height: 4rem;
            }
        }

        .g-price{
            .price{
                font-weight: bold;
                margin: 0;
            }
            .priceOld{
                color: $color-gray-middle5;
                font-family: "Karla";
                text-decoration: line-through;
                @include lib-font-size(14);
            }
        }
        a{
            &.link{
                font-weight: bold;
                text-decoration: underline;
                padding-top: 15px;
                display: block;
                color: $secondary__color;
            }

        }
    }


}



// pop minicart
.minicart-wrapper{
    #minicart-content-wrapper{
        .actions{
            .secondary:last-child{

                    //@include lib-button-secondary();
                    //border-radius: 10px;

               /*
                background: #fff;
                border: 2px solid #25B24A;
                color: #000;
                height: 50px;
                padding: 10px 30px;
                border-radius: 10px;
                */

            }
        }
    }
}




//------------- Mobile Popup -----
@include max-screen($screen__m) {
    //.block-popup-login,
    .popup-wishlist{
        display: none !important;
    }

    .popup-login{
        order: 1;
        border-left: 1px solid $color-gray;
    }

    .header .menutop-container .links{
        li{
            &:last-child{
                display: none;
            }
        }
    }


    .block-popup-login{
        .form-popup{
            background: $primary__color;
            width: 100%;
            left: 0px;
            margin-top: 10px;
            color: $color-white;
            border: 0;
            z-index: 99;
            border-bottom: 1px solid $color-white;
        }
        button.btn.cancel{
            background: $primary__color;
            color: $color-white;
            font-weight: 100;
            line-height: 1.8rem;
            right: 22px;
            font-size: 3rem;
        }
        ul li{
            a{
                color: $color-white;
            }
        }
    }

    .search-popup{
        a{
            &.adv-search{
                top: 4.5rem;
                position: relative;
            }
        }
    }


}


