.amblog-index-index,
.amblog-index-post{

    &.page-layout-2columns-right .column.main{
        width: 100%;
    }
    .page-title-wrapper{
        margin-bottom: -25px;
    }

    .page-title{
        font-family: $font-family__montserrat;
        font-size: 4.6rem;
        font-weight: 500;
    }

}


.category-hub {
    &__nav {
        padding: 0;
        margin: 4rem 0 3rem 0;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid $color-gray19;

        li {
            list-style: none;
            position: relative;
            margin-bottom: -2px;

            &.active{

                button {
                    border-bottom: 4px solid $secondary__color;
                }
            }

            .button-nav-hub,
            button {
                background: transparent;
                border: 0;
                border-radius: 0;
                padding: 0;
                //padding-left: 0 !important;
                font-size: 1.6rem;
                line-height: 2rem;
                font-weight: 800;
                margin: 0rem 2rem;
                color: $primary__color;

                &:hover, &:focus, &:active {
                    color: $primary__color;
                    box-shadow: none;
                }
            }

            /*&:first-child {
                .button-nav-hub,
                button {
                    padding-left: 0;
                }
            }*/

            &:last-child {
                .button-nav-hub,
                button {
                    padding-right: 0;
                }

                &:after {
                    display: none;
                }
            }

            &.active {
                .button-nav-hub,
                button {
                    color: $primary__color;
                }

                &:after {
                    background: $primary__color;
                }
            }

            a.button-nav-hub{
                display: block;
                margin: -1rem 2rem 0.5rem 2rem;
                text-align: center;
                font-family: $font-family__montserrat;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    &__content {
        margin-bottom: 4rem;

        &-item {
            display: none;
            flex-wrap: wrap;

            &.active {
                display: flex;
            }

            @media (min-width: $screen__s) {
                flex-wrap: block;
            }
        }
    }

    &__post {
        width: 100%;
        margin-bottom: 3rem;
        background: $color-white;

        &--feature {
            @media (min-width: $screen__s) {
                flex-wrap: nowrap;
                display: flex;
            }
        }

        &-list {
            width: 100%;

            @media (min-width: $screen__s) {
                flex-wrap: nowrap;
                display: flex;
            }
        }

        .amblog-wrapper {
            a, img {
                display: block;
            }
            width: calc(50% - 20px);

            @media (max-width: $screen__s) {
                width: 100%;
                margin-left: 0;
            }
        }

        .amblog-content {
            padding: 3rem;
            width: calc(50% - 20px);

            @media (max-width: $screen__s) {
                padding: 0;
                width: 100%;
                margin-left: 0;
            }

            .short_description{
                @include lib-font-size(16);
                color: $primary__color;
                padding: 0 0 3rem;
            }
            .amblog__button{
                text-decoration: underline;
                font-family: $font-family__montserrat;
            }
        }

        .amblog__meta, .amblog__meta a {
            font-size: 1.6rem;
            line-height: 2rem;
            margin: 2.2rem 0 2rem;
            color: $color-gray-middle5;
            font-family: $font-family__montserrat;
        }

        .amblog-title {
            margin-top: 0.5rem;
            margin-bottom: 2rem;

            a {
                @include lib-font-size(32);
                color: $primary__color;
                font-family: $font-family__montserrat;
                line-height: 42px;
                font-weight: 500;

                &:hover, &:focus {
                    text-decoration: none;
                }
            }
        }

        .amblog__button {
            &:hover, &:focus, &:active {
                color: $primary__color;
                //text-decoration: none;
            }
            &:active{
                text-decoration: none;
            }
        }

        &--small {

            width: 33%;
            img{
                width: 97%;
                height: 371px;

                @media (max-width: $screen__m) {
                    width: 225px;
                    height: 225px;
                    max-width: 100%;
                }

                @media (max-width: $screen__s) {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                }
            }

            @media (max-width: $screen__s) {
                width: 100%;
            }


            .amblog-content {
                padding: 0;
                @media (max-width: $screen__s) {
                    padding: 0;
                }
            }

            .amblog-title {
                margin-top: 0.5rem;
                margin-bottom: 2rem;

                a {
                    @include lib-font-size(22);
                    color: $primary__color;
                    font-family: $font-family__montserrat;
                    line-height: 30px;
                    font-weight: 500;

                    &:hover, &:focus {
                        text-decoration: none;
                    }
                }
            }

            .amblog__meta{
                margin: 1.4rem 0 2rem;
                a{
                    @include lib-font-size(12);
                }

            }

            .amblog-content{
                width: 100%;
            }
        }

        &--bottom-hub {
            display: flex;
            @media (max-width: $screen__xs) {
                display: block;
            }
            .category-hub__post--small{
                width: 25%;
                @media (max-width: $screen__s) {
                    width: 100%;
                }
                .amblog__meta{
                    a{
                        color: $color-gray64;
                        font-size: 1.4rem;
                        line-height: 2rem;

                        &:hover{
                            text-decoration: none;
                        }
                    }
                }
                .amblog-title{
                    a{
                        color: $color-black;
                        font-size: 2rem;
                        line-height: 2.4rem;

                        &:hover{
                            text-decoration: none;
                        }
                    }
                }
                img{
                    height: 300px;
                }
            }
            .category-hub__post--small{
                .amblog-content{
                    padding-right: 1rem;
                    img{
                        width: 371px;
                        height: 300px;

                        @media (max-width: $screen__m) {
                            width: 225px;
                            height: 225px;
                            max-width: 100%;
                        }

                        @media (max-width: $screen__s) {
                            width: auto;
                            height: auto;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }

    .featured-posts__link{
        display: block;
        width: 100%;
        margin-top: 0;
        a{
            text-decoration: underline;
            font-family: "Montserrat";
            font-size: 1.6rem;
            font-weight: 700;
            &:hover, &:focus, &:active {
                color: $primary__color;
                //text-decoration: none;
            }
            &:active{
                text-decoration: none;
            }
        }
    }

}
.amblog-container-list{
    .amblog-post-container{
        .amblog-title{
            a{
                color: $secondary__color;
                &:hover{
                    color: $secondary__color;
                }
            }
        }

    }
}


@media (max-width: $screen__m) {


    .amblog-index-index,
    .amblog-index-post{

        .breadcrumbs{
            display: none;
        }

    }

}
.catalog-category-view, .catalogsearch-result-index {
    .amasty-catalog-topnav {
        #menu-filter-mobile {
            display: none;
        }
        .btn.cancel-filter {
            display: none;
        }
    }
    .message.notice {
        > div {
            font-size: 20px;
            dl.block {
                display: none;
            }
        }

        .amsearch-related-terms {
            display: flex;
            flex-wrap: wrap;
            margin-top: 15px;
            .amsearch-block-header {
                width: 100%;
            }
            .amsearch-item {
                margin-bottom: 15px;
                color: $color-white;
                padding: 10px;
                margin-right: 10px;
            }
        }
    }
}
