.sales-order-view {


    .actions-toolbar .secondary a.action {
        margin-top:30px;
    }
}

.account{
    .columns{
        flex-wrap: initial;
        @include max-screen($screen__m) {
            display: block;
        }
        .column.main{
            width: 100%;
            float: none;
        }
        .sidebar{
            &.sidebar-main{
                width: 100%;
                float: none;
            }
            &.sidebar-additional{
                display: none;
            }
        }
        .my-compte{
            clear: both;
            margin-bottom: 8rem;
            display: grid;
            grid-template-columns: 2fr 2fr 1fr;
            @include max-screen($screen__m) {
                grid-template-columns: 2fr 2fr;
            }
            @include max-screen($screen__xs) {
                grid-template-columns: none;
            }
            .block-compte{
                border: 1px solid $color-gray80 ;
                padding: $indent__m;
                margin: 0px $indent__xl 0px 0px;

                @include max-screen($screen__xs) {
                    margin: 0px 0px $indent__m 0px;
                }
                .title{
                    border-bottom: 1px solid $color-gray80 ;
                    margin-bottom: $indent__m;
                    padding-bottom: $indent__m;
                    font-size: $indent__l ;
                    &:before{
                        content: "\f007";
                        font-size: $indent__l*2 ;
                        color: $secondary__color ;
                        font-family: 'Font Awesome 5 Pro';
                        font-weight: 500;
                        display: block;
                    }
                    &.commande{
                        &:before{
                            content: "\f07a";
                        }
                    }
                }
                ul.items{
                    .item{
                        @include lib-font-size(18);
                        padding: $indent__xs 0px;
                        a{
                            color: $color-black;
                        }
                    }
                    span[class^="delimiter"]{
                        display: none;
                    }
                }
                &:last-child{
                    display: none;
                }
            }

            .block-compte:first-child{
                .items{
                    .item:nth-child(2),
                    .item:nth-child(5){
                        display: none;
                    }
                }
            }
        }
    }

    // pagination order
    .pager{
        .limiter{
            .limiter-options{
                margin: 0 $indent__s  0 $indent__s ;
                padding: 0 $indent__l 0 $indent__s ;
                background-position: center right 0rem;
                background-size: 1.5rem;
            }
        }
    }
    // table
    table{
        &.table-order-items.history .col,
        &.table-order-items.recent .col {
            width: initial;
        }

        tbody{
            .col.actions{
                .action{
                    display: block;
                }
            }
        }
        // table review customer
        &.table-reviews .col{
            width: initial;
        }


    }

    &.wishlist-index-index{
        .actions-toolbar{
            display: none;
        }
    }

    // page wishlist
    .products-grid{
        &.wishlist{
            .product-item{
                padding: 1rem;
                textarea{
                    border: 1px solid $color-gray76 ;
                    border-radius: 0;
                    margin-top: 2.5rem;
                    height: 7rem;
                    @include lib-font-size(14);
                }
                .box-tocart{
                    input.input-text.qty{
                        border: 1px solid $color-gray76;
                        margin-top: 1rem;
                        height: 4rem;
                        width: 6rem;
                    }
                }
            }
        }
    }

    // --- form : fr/customer/address/edit
    &.customer-account-index{
        .main{
            .block-title{
                strong{
                    margin-bottom: $indent__m !important;
                    padding-bottom: $indent__m;
                    font-size: $indent__l !important;
                }
                a{
                    text-decoration: underline;
                }
            }
        }
    }

    &.customer-account-edit{

            .form{

                &.form-edit-account{
                    .info,
                    .password{
                        .legend {
                            margin-bottom: $indent__m;
                            padding-bottom: $indent__m;
                            font-size: $indent__l ;
                        }
                    }

                    .field-name-firstname{
                        width: 48%;
                        padding-right: 2%;
                        float: left;
                        @include max-screen($screen__m) {
                            width: 100%;
                        }
                    }
                    .field-name-lastname{
                        width: 50%;
                        float: left;
                        @include max-screen($screen__m) {
                            width: 100%;
                        }
                    }

                    .customer-dob{
                        width: 50%;
                        float: left;
                        @include max-screen($screen__m) {
                            width: 100%;
                        }
                    }
                    .gender{
                        width: 50%;
                        float: left;
                        @include max-screen($screen__m) {
                            width: 100%;
                        }
                    }

                    .fieldset.password{
                        width: 50%;
                        @include max-screen($screen__m) {
                            width: 100%;
                        }
                    }

                }

            }

            #ui-datepicker-div{
                .ui-datepicker-title{
                    select{
                        background-position: center right 0.2rem;
                        background-size: 1.2rem;
                        padding: 4px;
                    }
                }
            }
    }


    &.customer-address-form{

        .form-address-edit{
            .field-name-firstname,
            .field-name-lastname,
            .company,
            .telephone,
            .fax{
                width: 48%;
                float: left;
                padding-right: 2%;
                @include max-screen($screen__m) {
                    width: 100%;
                }
            }
            .country{
                display: none !important;
            }

            .block-adress{
                display: flex;
                margin-bottom: 3rem;

                @include max-screen($screen__m) {
                    display: inline-block;
                }

                .region,
                .city,
                .zip{
                    width: 33%;
                    padding: 0 10px 0 0;

                    @include max-screen($screen__m) {
                        width: initial;
                    }

                }
                .zip{order: 1;}
                .city{order: 2;}
                .region{order: 3;}

                .region{
                    .label{
                        display: none;
                    }
                    select{
                        border: 0;
                        border-bottom: 1px solid $color-black;
                        border-radius: 0;
                        padding: 0 25px 0 0;
                        background-position: center right 0rem;
                        background-size: 1.5rem;
                        color: $color-gray;
                    }
                }
            }
        }
    }
}

.tracking {
    .col {
        &.label {
        width:30%;
        }
    }
}
