.amblog-index-post{

    .category-hub{
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: 1280px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }

    .page-title-wrapper{
        margin-bottom: 0;
        margin-top: 4.5rem;
    }

    .category-hub{
        &__nav{
            margin: 2.5rem 0 3rem 0;
            li{
                margin-bottom: 8px;
                height: 20px;

                .button-nav-hub{
                    margin: 0px 20px -10px 20px;

                }

                &.active{
                    .button-nav-hub{
                        border-bottom: 4px solid $secondary__color;
                        margin: 0px 20px 0px 20px;
                        height: 30px;
                    }

                }
            }
        }
    }

    .column.main{
        width: 100%;
        display: flex;
        @media (max-width: $screen__s) {
            display: block;
        }
        .amblog-post-container{
            width: 75%;
            padding-right: 50px;
            @media (max-width: $screen__s) {
                width: 100%;
                padding-right: 0;
            }

            .amblog-content{
                border-bottom: none;
                padding: 4.5rem 0 3rem;

                h2{
                    @include lib-font-size(22); 
                    letter-spacing: 0.1rem;
                    strong{
                        font-weight: 500;
                    }
                }
            }

            .amblog-wrapper{
                &__title{
                    margin-top: $indent__xl;
                    font-weight: bold;
                    letter-spacing: 2px;
                }
                &__header{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }
                
                &__meta{
                    a{
                        color: $color-gray96 ;
                        @include lib-font-size(20);
                        &.amblog-ref{
                            @include lib-font-size(16);
                        } 
                    }
                }
                &__share{
                    text-align: end;
                    a{
                        @include lib-font-size(16);
                        font-weight: bold;
                        text-decoration: underline;
                        font-family: $font-family__montserrat;
                         
                        &:hover, &:focus, &:active {
                            color: $primary__color;
                        }
                        &:active{
                            text-decoration: none;
                        }

                        @media (max-width: $screen__m) {
                            span{
                                display: none;
                            }
                        }
                    }

                    
                }

            }

            .amblog-item-author{
                color: $color-gray96 ;
                @include lib-font-size(16);
                padding-top: 0.5rem;
                font-weight: 600;
            }
            
            .btn-share{
                &:after{
                    content: "\f14d";
                    @include lib-font-size(20);
                    color: $secondary__color;
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 400;
                    padding-left: 10px;
                }
                &:hover{
                    color: $secondary__color;
                }
                @media (max-width: $screen__m) {
                    span{
                        display: none;
                    }
                }
                 
            }
            .amblog__footer{
                .share{
                    text-decoration: underline;
                    font-weight: bold;
                    a{
                        
                    }
                }
            }
        }
        .featured-posts--related{
            width: 25%;
            @media (max-width: $screen__s) {
                width: 100%;
            }
            h2{
                @include lib-font-size(22);
            }
            .post-item{
                img{
                    width: 100%;
                    @media (max-width: $screen__s) {
                        width: 100%;
                    }
                }
            }
        }

    }

    .sidebar-additional{
        padding: 0px;
    }
    .amblog-post-container{
        border: none;
    }

    .amblog_related_products{

        @media (max-width: $screen__s) {
            .slick-track {
                margin-left: 60px;
            }

            
        }
        .slick-slide .product-item-slider{
            border: 1px solid transparent;
            padding: 10px;
            &:hover{
                border: 1px solid $secondary__color;
            }
        }
        .amblog-details{
            .product-item{
                &-name{
                    @include lib-font-size(16);
                    margin-bottom: 0;
                    color: $primary__color;
                    a{
                        font-weight: bold;
                        font-family: $font-family-name__base;
                    }
                }
                &-category{
                    @include lib-font-size(12);
                    color: $color-gray; 
                    margin-bottom: 1.2rem;
                    font-family: $font-family-name__base;
                    font-weight: bold;
                }
                &-shortDescription{
                    @include lib-font-size(14);
                    color: $primary__color;
                    font-weight: lighter;
                    font-family: $font-family-name__base;
                }
            }
            
        }

    }


    .page-bottom{
        .product-items.slick-slider .slick-slide .product-item{
            .product-image-photo{
                background-color: $color-gray95;       
            }
        }
    }

}


