.block-subtitle.filter-current-subtitle {
    padding: 0;
}

.product-info-price {
    &:after {
        clear:both;
        content:"";
    }
}


.gallery-placeholder:not(._block-content-loading) .gallery-placeholder__image {
    display: none;
}

.product-info-main .price-box {clear: both;}
.column.main{


    .product{
        &-items {
            padding: 0;
            margin: 0;
        }
        &-image{
            &-container{
                background-color: $color-white-smoke;
            }
        }
        &-item {
            position: relative;
            max-width: 100%;
            padding: 0;
            margin: 0;
            border: 1px solid transparent;
            &-tags{
                &__item{
                    color:$color-white;
                    @include lib-font-size(10);
                    text-transform: uppercase;
                    position: absolute;
                    padding:4px 8px 3px 8px;
                    top:2em;
                    right: 0;
                    z-index:10;
                    &--new{
                        background-color: $primary__color;
                    }
                    &--promo{
                        background-color: $color-promo ;
                    }
                    &--promoAndNew{
                        top:5em;
                    }
                }
            }
            &-flx-box-actions{
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                .tocart {

                    background:none;
                    border: none;
                    color : $primary__color;
                    font-size: 1.3rem;
                    &:before {
                        content: "\f07a";
                        font-size: 20px;
                        font-family: 'Font Awesome 5 Pro';
                        font-weight: 400;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        font-weight: normal;
                        line-height: 1;
                        vertical-align: middle;
                    }

                    &:hover {
                        color : $secondary__color;
                    }

                    span {display:none;}
                    &.disabled {
                        span {display:inline-block;}
                    }
                }
            }
            &-details{
                padding:$indent__s $indent__s 0 $indent__s;
            }
            &-name{
                @include lib-font-size(16);
                margin-bottom: 0;
                color: $primary__color;
                a{
                    font-weight: bold;
                    font-family: $font-family-name__base;
                }
            }
            &-category{
                @include lib-font-size(12);
                color: $color-gray;
                margin-bottom: 1.2rem;
                font-family: $font-family-name__base;
                font-weight: bold;
            }
            &-shortDescription{
                @include lib-font-size(16);
                color: $primary__color;
                font-weight: lighter;
                font-family: $font-family-name__base;
                min-height: 46px;
            }
            &-sku{
                @include lib-font-size(14);
            }
            &:hover{
                border: 1px solid $secondary__color;
            }
            .price-box{
                .price{
                    @include lib-font-size(16);
                    color: $primary__color;
                    font-family: $font-family-name__base;
                }
            }
            .price-box.price-final_price{
                display: flex;
                .old-price{
                    padding-left: 15px;
                    font-size: 1.4rem;
                    .price{
                        @include lib-font-size(14);
                    }
                }
            }

        }
        &-info-main{
            width: 50%;
            @media (max-width: $screen__xs) {
                width: 100%;
            }

            .page-title-wrapper{
                display: flex;
                padding-top: 2rem;
                .page-title{
                    @include lib-font-size(32);
                    width: 80%;
                }
            }

            .product-info-stock-sku{
                .sous-title{
                    @include lib-font-size(20);
                    margin: 1rem 0 2rem 0;
                }
                .availability.configurable-variation-qty{
                    display: none !important;
                }
            }

            .box-tocart{
                .qty{
                    .label{
                        display: none;
                    }
                    input{
                        border: 1px solid $color-gray60;
                        border-radius: $indent__xs;
                    }
                }

                .actions{
                    padding-top: 0px;
                    .action{
                        &.primary{
                            padding: 26px $indent__m;
                            line-height: 0;
                            @include lib-font-size(18);
                            &:focus{
                                background: $secondary__color;
                            }
                        }
                    }

                }

            }
            .icon-share{
                &:before{
                    content: "\f14d";
                    @include lib-font-size(20);
                    color: $secondary__color;
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 400;
                }

            }

            .info.detailed {
                .data.switch {
                    border: 0;
                    background: transparent;
                    height: 100%;
                }
            }

            .price-wrapper {
                .price{
                    @include lib-font-size(34);
                }
            }
            .old-price{
                .price-wrapper {
                    .price{
                        @include lib-font-size(24);
                        color: $color-gray40;
                    }
                }
            }

            // --- popover info detaild
            .info.detailed .data.switch{
                color: $color-black ;
                padding: 5.5px 20px 5.5px 20px;

            }

            .border{
                border-bottom: 1px solid $color-black ;
                @include max-screen($screen__m) {
                    display: flex;
                }
            }

            .new-windows{
                position: absolute;
                z-index: 2;
                top: 0px;
                margin-top:-5px;
                background: $color-white ;
                height: 100%;
                width: 100%;

                .product.data.items>.item.title{
                    margin: -2px 5.5px 0 0;

                    &.active .switch{
                        border-bottom: 4px solid $secondary__color ;
                        @include max-screen($screen__m) {
                            width: 100%;
                        }
                    }
                }

                .close{
                    width: 3rem;
                    height: 5rem;
                    z-index: 999;
                    margin-bottom: $indent__xl;
                    @include max-screen($screen__m) {
                        height: 0;
                    }

                    span{
                        position: absolute;
                        right: $indent__l;
                        &:before{
                            color: $color-black ;
                            content: "\f00d";
                            font-family: 'Font Awesome 5 Pro';
                            font-size: 4rem;
                            font-weight: 100;
                            position: absolute;
                            top: 0;
                            cursor: pointer;
                            }
                    }
                }

                @include max-screen($screen__m) {
                    .product.data.items{
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        border-bottom: 1px solid $color-gray62;

                        .item.title{
                            order: 1;
                        }
                        .item.content{
                            position: absolute;
                            top: 100px;

                            @include max-screen($screen__m) {
                                border: none;
                            }
                        }
                    }
                }
            }
            // --- end popover
            .info-stock{
                margin: $indent__m 0 $indent__xl;
                color: $secondary__color;
                @include lib-font-size(18);
                font-weight: bold;
            }

        }
        &-social-links{
            width: 10%;
        }
        &-addto-links{
            margin: 0;

            .action{
                background: transparent;
                border: none;
                span{
                    display: none;
                }
                &:before{
                    content: "\f004";
                    font-size: 20px;
                    color: $secondary__color;
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 400;
                }

                &.inwishlist{
                    &:before{
                        font-weight: 700;
                    }
                }
            }

        }


        &.media{
            width: 50%;
                @media (max-width: $screen__xs) {
                    width: 100%;
                }
                .fotorama__loaded.fotorama__active{
                    background-color: $color-gray95 ;

                    &:after {
                        display: block;
                        width: 5.5rem;
                        height: 5.5rem;
                        position: absolute;
                        bottom: 20px;
                        right: 25px;
                        color: $color-black;
                        padding: .625rem;
                        content: "\f00e";
                        font-family: 'Font Awesome 5 Pro';
                        font-size: 4rem;
                        @media (max-width: $screen__xs) {
                            display: none;
                    }
                }
                    &.fotorama-video-container {
                        &:after {
                            display: block;
                            width: 5.5rem;
                            height: 5.5rem;
                            position: absolute;
                            bottom: 20px;
                            right: 25px;
                            color: $secondary__color;
                            padding: .625rem;
                            content: "\f04b";
                            font-family: 'Font Awesome 5 Pro';
                            font-size: 4rem;
                            @media (max-width: $screen__xs) {
                                display: none;
                            }
                        }
                    }
            }

            .fotorama__nav-wrap{
                margin-top: 3rem;

                .fotorama__thumb{
                    background-color: transparent;
                }

            }
        }
    }

}
.video-thumb-icon:after {
    display: block;
    width: 5.5rem;
    height: 5.5rem;
    position: absolute;
    bottom: -40px;
    right: -20px;
    color: $secondary__color;
    padding: .625rem;
    content: "\f04b";
    font-family: 'Font Awesome 5 Pro';
    font-size: 4rem;
    @media (max-width: $screen__xs) {
        display: none;
    }
}

.product-etiquette{
    @include lib-font-size(12);
    position: absolute;
    top: 2rem;
    right: 0;
    display: flex;
    align-items: center;
    padding:0px 10px;
    text-align: center;
    height: 25px;
    z-index: 99;

    &.discount {
        color: $color-white ;
        background-color: $color-promo;
    }
    &.new{
        color: $color-white ;
        background-color: $primary__color;
        top:4.5rem;
    }

}




.page-bottom {
    .crosssell,
    .related,
    .upsell{
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: 1280px;
        padding-left: 20px;
        padding-right: 20px;
        width: auto;
    }


    .block{
        &.related,
        &.crosssell,
        &.upsell{
            border-top: 1px solid $color-gray-middle2;
            padding-top: $indent__xl;
            margin-top: $indent__xl;
            h2{
                padding-bottom: $indent__m;
                font-weight: 500;
            }
            .product-item-slider{
                padding: 0 $indent__m ;
                border: 1px solid $color-white;
                &:hover{
                    border: 1px solid $secondary__color;
                }
            }

            .name{
                @include lib-font-size(16);
                margin-bottom: 0;
                color: $primary__color;
                font-weight: bold;
                font-family: $font-family-name__base;
            }

            button{
                &.slick-arrow{
                    top: 30%;
                }
            }

            .product{
                &.category{
                    @include lib-font-size(12);
                    color: $color-gray;
                    margin-bottom: 1.2rem;
                    font-family: $font-family-name__base;
                    font-weight: bold;
                }
                &.sous_title{
                    @include lib-font-size(14);
                    color: $primary__color;
                    font-weight: lighter;
                    font-family: $font-family-name__base;
                }

                &-item{
                    @media (max-width: $screen__xs) {
                        margin: 0px !important;
                        margin-left: 22% !important;
                        max-width: 100%;

                        .product-image-container{
                            width: 210px !important;
                            img{
                                width: 100%;
                            }
                        }


                    }
                }
            }

            .products-crosssell{
                .items{
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    margin: 0;

                    @media (max-width: $screen__xs) {
                        grid-template-columns: 1fr 1fr;
                    }

                    .product-item{
                        &.item-cross-sell{
                            width: 100%;
                            margin: 0;
                            padding: 0px 10px;
                        }
                        .block-image{
                            position: relative;
                        }
                    }
                }
            }

            .action.somme{
                margin-top: 15vh;
                transform: translateY(-50%);
                @media (max-width: $screen__xs) {
                    margin-top: 2vh;
                }

                h3{
                    margin: $indent__xl 0 $indent__m;
                    font-family: $font-family-name__base;
                    @include lib-font-size(18);
                    font-weight: 400;
                    span{
                        @include lib-font-size(34);
                        font-weight: bold;
                    }
                }

                .action.primary{
                    background: $color-white;
                    border: 2px solid $secondary__color;
                    color: $primary__color;
                    padding: $indent__s $indent__m;
                }
            }
        }

        &.crosssell{
            .product{
                &-item{
                    @media (max-width: $screen__xs) {
                        margin-left: 0 !important;
                    }
                }
            }

        }
    }
}


.swatch-attribute{
    &-label{
        font-weight: 600;
        @include lib-font-size(22);
    }

    .swatch-option{
        &.text{
            border: 1px solid $color-gray-darken3;
            background: transparent;
            color: $color-black;
            @include lib-font-size(16);
            font-weight: 600;
            border-radius: 5px;
            padding: 15px;
            min-width: 100px;
            min-height: 50px;

            &:hover{
                outline: none;
                border: 2px solid $secondary__color;

            }

            &.selected{
                outline: none;
                border: 2px solid $secondary__color;
            }
        }

    }

}

.content-list-views{
    background-color: $primary__color;
    border-bottom: 1px solid $color-gray94;

    .review-list, .review-add{
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: 1280px;
        //padding-left: $indent__m;
        //padding-right: $indent__m;
        width: auto;
        margin-bottom: 0;
    }

    .review-add{
        padding-bottom: 5rem;
    }


        .review-list{
            .review-legend,
            .field label,
            .block-title,
            .review-title,
            .rating-label,
            .review-content,
            .review-details{
                color: $color-white;
            }
            .rating-summary{
                .rating-result>span:before{
                    color: $color-yellow2;
                }
            }
            .comments{
                padding-top: 1.5rem;
            }

            .review-ratings {
                .rating-label{
                    display: none;
                }
            }
            .btn-add-review{
                text-align: end;
                font-size: 18px;
                font-weight: bold;
                text-decoration: underline;
                position: absolute;
                right: 0;
                top: 11rem;

                a{
                    color: $secondary__color;

                }
            }
            .block-content{
                padding-top: 5rem;
                padding-bottom: 0rem;
            }
        }

        .review-list,
        form.review-form {
            padding: 0 20px;

            .fieldset {
                .label{
                    color: $color-white;
                }
                input {
                    border-bottom: 1px solid $color-white;
                    color: $color-white;
                    background-color: $primary__color;
                }
            }
            .action.submit.primary{
                background: $color-white;
                color: $primary__color;
            }
            textarea {
                background: $primary__color;
                color: $color-white;
                border-bottom: 1px solid $color-white;
                border-radius: 0;
            }
            .legend.review-legend{
                display: none;
            }

        }

        .review-list{
            padding: 7rem 2rem 0rem 2rem;
            position: relative;
        }

        .toolbar.review-toolbar{
            border-top: none;
        }

}

.product-info-main {
    .product-info-stock-sku{
        .attribute.sku{
            strong{
                font-weight: 500;
            }
        }
    }
    .product-reviews-summary{
        display: inline-block;
        float: right;
        padding-bottom: 10px;
        text-align: right;
        vertical-align: top;
        margin: 0;

        .rating-summary{
            display: inline-block;
            padding: $indent__s $indent__s 0 $indent__s;

            .rating-result{
                float: left;
            }
            p.action.view{
                float: right;
                margin: 5px 0 0 0;
            }
            .rating-result>span:before{
                color: $color-yellow2;
            }
        }

        .reviews-actions{
            display: block;
            @include lib-font-size(14);
            a.action.add{
                color: $color-black;
                text-decoration: underline;
            }
        }

        &.empty{
            padding-bottom: 3rem;
        }

    }

    .block-additional-attributes{
        margin-top: 3rem;

        .additional-attributes{
            tbody{
                .case-pack-quantity {
                    display:none;
                }
                th{
                    width: 50%;
                }
                .label{
                    @include lib-font-size(18);
                 }
            }
        }
    }
}

.category-formations{
    .home__newsletter{
        background-color: $primary__color;
        margin-bottom: 1px;
    }
}


.fotorama__fullscreen-icon{
    background-image: url(../../images/gallery.png);
}
.fotorama__fullscreen .fotorama__zoom-in,
.fotorama__fullscreen .fotorama__zoom-out{
    background-image: url(../../images/gallery.png);
}


// Mobile : page category list products
@media (max-width: $screen__xs) {
    .column.main{
        .product-item{
            max-width: 70%;
            padding: 0;
            margin: 0px 10%;
        }
    }
}
/*****************************************
//category-view
*****************************************/

.breadcrumbs{
    background-color: $color-white;
    max-width: 100%;
    padding: 3.3rem;
    margin: 0;
    @media (max-width: $screen__m) {
        padding: 3.3rem 1rem;
    }

    .item:not(:last-child):after{
        color: $color-gray60;
    }

    .items {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: 1280px;
        padding-left: 2rem;
        padding-right: 2rem;
        @media (max-width: $screen__m) {
            padding-left: 0;
        }

        width: auto;
        color: $primary__color;
        font-size: 1.6rem;

        a{
            color: $color-gray60;
        }
    }
}


.catalog-category-view{

    #page-title-heading{
        display: none;
    }
}

.block-category-view{
    background-color: $color-white;

    .category-view{

        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: 1280px;
        padding: 5rem 2rem;

        display: flex;
        position: relative;




        .category-image{
            background-color: $color-black;
            height: 236px;
            width: 348px;
            position: relative;



            img{
                width: 200px;
                height: auto;
                position: absolute;
                top: -20px;
                left: 20%;



            }
        }

        .category-description{
            color: $primary__color;
            //position: absolute;
            //left: 348px;
            padding: 0;



            h1{
                margin-bottom: 1rem;
                font-family: $font-family__montserrat;
                @include lib-font-size(46);
                letter-spacing: 3px;
                font-weight: bold;
                line-height: 56px;
            }

            p{
                font-family: $font-family-name__base;
                @include lib-font-size(18);
                font-weight: bold;
            }

        }

    }


    @include max-screen($screen__m) {
        .category-view{
                padding: 2rem;
                display: block;
            .category-image{
                    height: 127px;
                    width: 188px;
                img{
                    width: 120px;
                }
            }
            .category-description{
                position: static;
                padding: 2rem 0;
            }
        }
    }
}


/**************************
//sidebar-main
//page list category
//page resultat search
**************************/

    .catalogsearch-result-index .sidebar-main,
    .catalog-category-view .sidebar-main{

        #menu-filter-mobile{
            height: 43px;
            width: 120px;
            border-radius: 8px;
            text-align: center;
            display: none;
            letter-spacing: 2px;
            font-weight: 500;

            @include max-screen($screen__m) {
                display: block;
                margin: 20px;
            }
        }

        .cancel-filter{
            display: none;
        }

        @include max-screen($screen__m) {

            margin-left: -15px;
            margin-right: -15px;

            &.menu-mobile{
                position: absolute;
                width: 110%;
                background: $color-white;
                top: 0;
                z-index: 999;
                height: auto;

                #menu-filter-mobile{
                    display: none;
                }
                .block.filter{
                    margin-top: 25px;
                }
                .cancel-filter{
                    display: block;
                    margin: 10px;
                    background-image: none;
                    background: $color-white;
                    border: none;
                    color: $color-black;
                    right: 10px;
                    position: absolute;
                    top: 0;
                    font-size: 30px;
                    height: 4.5rem;
                    width: 4rem;
                }
                .filter-content{
                    padding: 0 15px;
                }

                #layered-filter-block{
                    display: block;
                }

            }

            #layered-filter-block{
                display: none;
                margin-left: 20px;
                margin-right: 15px;
            }
            .block-static-block{
                display: none;
            }



        }

    }

/*****************************************
// Mobile
******************************************/

@include max-screen($screen__m) {
    .products{
        &-grid{
            .product{
                &-item{
                    width: 100%;
                }
            }
        }
    }

    .content-list-views .review-list .btn-add-review{
        right: 20px;
    }

    .column.main {
        .product-info-main{
            padding-right: 0;
            padding-left: 0;
            width: 100%;

            .page-title-wrapper{
                .page-title{
                    order: 3;
                }
                .product-social-links{
                    order: 1;
                    width: 15%;
                    z-index: 9;
                }
                .btn-share{
                    order: 2;
                    width: 15%;
                    z-index: 9;
                }
            }

            .product-reviews-summary{
                position: absolute;
                top: 0;
                width:100%;


                .rating-summary .rating-result{
                    margin-left: 30px;
                }
            }

            h1.page-title{
                margin-top: 6rem;
                margin-bottom: 3rem;
                font-weight: 500;
            }
            .product-info-stock-sku{
                .sous-title{
                    font-weight: 500;
                }
            }

            .product-add-form{
                .box-tocart{
                    .fieldset{
                        display: flex;
                        .actions{
                            width: 100%;
                        }
                    }
                }
            }

            .info.detailed .data.switch{
                width: 100%;
                line-height: 20px;
                padding-left: 0;
                font-size: 16px;
            }

        }

        .media{
            .fotorama__loaded.fotorama__active{
                background-color: transparent !important;
            }
            .fotorama__nav--dots .fotorama__nav__frame{
                width: 40px;
                height: 30px;
            }
            .fotorama__nav-wrap{
                .fotorama__dot{
                    background-color: $color-gray80;
                    border:0;
                    border-radius: 0;
                    width: 24px;
                }

                .fotorama__active{
                    .fotorama__dot{
                        background-color: $secondary__color;
                        border: 1px solid $secondary__color;
                        border-radius: 0;
                        width: 24px;
                        top: 11px;
                    }
                }

            }
        }
    }


    .catalogsearch-result-index,
    .catalog-category-view{
        .toolbar-products{
            .toolbar-sorter.sorter{

                position: absolute;
                top: 15px;
                right: 0;
                // mini mobile
                @include max-screen(400px) {
                    position: initial;
                    right: initial;
                    text-align: left;
                    margin-left: -10px;
                }

                .sorter-label{
                    display: none;
                }
            }
            .toolbar-amount{
                display: none;
            }

        }
    }

}


.catalog-category-view{
  .product-item-info{
      width: 100%;
  }

  .column.main .product-item{

      max-width: 100%;
      margin: 0;
      padding: 0 10px;

      border: 1px solid transparent;


      @include max-screen($screen__m) {
        width: 50%;
      }

      &:hover{
          border: 1px solid transparent;
        }

      .product-item-info{
        border: 1px solid transparent;

        &:hover{
            border: 1px solid $secondary__color;
        }
      }





        .product-etiquette{
            right: 1rem;
            @include max-screen($screen__m) {
            right: 3rem;
            }
        }
  }
}


.amsearch-related-terms {
    > .amsearch-item{
        background: $secondary__color ;
        color: $color-white;
        border-radius: 8px;
        @include lib-font-size(18);
        font-weight: 600;

        &:hover{
            background: $secondary__color ;
        }
    }
}

.search.results{
    dd.item{
        a{
            text-decoration: underline;
            font-family: $font-family__montserrat;
            @include lib-font-size(16);
            font-weight: 600;
            &:hover{
                color: $primary__color;
            }
        }
    }
}

.catalogsearch-result-index{
    .column.main{
        .product-item{
            margin: 0px;
        }
    }
}

.catalog-product-view {
    .product-info-main {
        .product-add-form {
            .box-tocart {
                .field.qty {
                    .control {
                        display: flex;
                        #qty {
                            border-radius: 0;
                        }
                        .wb-add, .wb-sub {
                            color: $secondary__color;
                            font-size: 16px;
                            border: 1px solid lightgrey;
                            width: 54px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            background: $form-element-input__background;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 430px) and (min-width: 200px) {
    .product-add-form {
        .box-tocart {
            .fieldset {
                flex-wrap: wrap;
            }
        }
    }
}
@media (max-width: $screen__l) and (min-width: 767px) {
    .catalog-product-view {
        .product-info-main {
            .box-tocart {
                .action.primary.tocart {
                    padding: 14px 10px !important;
                    line-height: inherit !important;
                    font-size: 14px !important;
                    height: 54px !important;
                    span {
                        line-height: 0 !important;
                    }
                }
            }
            .product.info.detailed {
                .data.switch {
                    padding: 5.5px 10px 5.5px 10px;
                }
            }
            .product.info.detailed.new-windows {
                .close {
                    height: 1px;
                }


                .data.items > .item.content {
                    padding: 15px 22px 0 22px;

                    .value {
                        li {
                            margin-bottom: 7px;
                        }
                    }
                }
            }
        }
    }
}
