.featured-posts {
    padding: 0rem 5rem;
    margin-bottom: 0 !important;
    
    
    @include max-screen($screen__m) {
        padding: 20px 0px;
    }

    .featured-posts__wrapper{
        display: flex;
        flex-direction: column;

        .block-title{
            order: 1;
        }
        .featured-posts__link{
            order: 2;
            margin-top: -6rem;
            margin-bottom: 3rem;
            z-index: 1;
            @include max-screen($screen__m) {
                order: 3;
                margin-top: 0;
            }
        }
        .block-content{
            order: 3;
            @include max-screen($screen__m) {
                order: 2;
            }
        }
    }


    @media (min-width: $screen__s) {
        padding: 4rem 0 8rem;
    }

    &__title {
        position: relative;

        @media (min-width: $screen__s) {
            margin-bottom: 2rem !important;
        }

        h3 {
            text-transform: uppercase;
            font-size: 3rem;
            line-height: 3.9rem;
            margin-bottom: 1rem;
            margin-top: 0;

            @media (min-width: $screen__s) {
                font-size: 4rem;
                line-height: 4.9rem;
            }
        }

        p {
            font-size: 1.4rem;
            line-height: 2rem;

            @media (min-width: $screen__s) {
                font-size: 1.8rem;
                line-height: 2.4rem;
            }
        }
    }

    &__subtitle {
        display: none;
        margin-bottom: 4rem !important;

        @media (min-width: $screen__s) {
            margin-bottom: 5rem !important;
        }

        h3 {
            position: relative;
            text-transform: uppercase;
            font-size: 2.6rem;
            line-height: 3.2rem;

            @media (min-width: $screen__s) {
                font-size: 3rem;
                line-height: 3.7rem;
            }

            &:after {
                content: '';
                width: 100%;
                height: 2px;
                background: $color-gray ;
                position: absolute;
                right: 0;
                top: 50%;
                margin-top: -1px;
                z-index: 0;
            }

            span {
                position: relative;
                display: inline-block;
                background: $color-white-smoke ;
                padding-right: 2.5rem;
                z-index: 1;
            }
        }
    }


    &__meta {
        color: $color-gray64;
        font-size: 1.4rem;
        line-height: 2rem;
        margin-bottom: 2rem;
        display: block;

        >span {
            text-transform: capitalize;
            @include lib-font-size(14);
        }
       
    }

    &__button {
        display: inline-block;
        color: $red;
        margin-top: 3rem;
    }

    &__link {
        text-align: right;

        @media (min-width: $screen__s) {
            margin-top: 2rem;
        }
    }

    &__blog-link {
        font-size: 2rem;
        line-height: 2.4rem;
        font-weight: 700;
        color: $secondary__color;
        text-decoration: underline;


        &:visited {
            color: $secondary__color;
        }

        &:hover, &:focus {
            text-decoration: none;
            color: $primary__color;
        }
    }

    .post-items {
        margin: 0 -15px;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
    }

    .post-item {
        list-style: none;
        margin: 0 15px;
        width: 100%;
        margin-bottom: 3rem;

        &:last-child {
            margin-bottom: 0;
        }

        @media (min-width: $screen__xs) {
            width: calc(50% - 30px);
            margin-bottom: 0;
        }

        @media (min-width: $screen__s) {
            width: calc(33.333% - 30px);
            margin-bottom: 0;
        }
    }

    .post-item-photo, .post-item-photo img {
        display: block;
        //width: 100%;
        max-width: 400px;
        height: auto;
        @media (min-width: $screen__xs) {
            width: 100%;
        }
    }

    .post-item-details {
        background: $color-white;
        margin-top: 0 !important;
        text-align: left;

        > a {
            display: block;
            padding: 25px;

            &:hover, &:focus {
                text-decoration: none;
            }
        }

        h4 {
            color: $color-black ;
            font-size: 2rem;
            font-weight: bold;
            line-height: 2.4rem;
            margin-top: 1rem;
        }

        h3 {
            margin-top: 0.5rem;
            margin-bottom: 4rem;

            a {
                text-transform: capitalize;
                color: $color-black ;
                font-size: 2rem;
                line-height: 2.4rem;

                &:hover, &:focus {
                    text-decoration: none;
                }
            }

        }
    }

    &--related {
        padding-bottom: 10rem;
        @media (max-width: $screen__s) {
            padding-bottom: 0;
        }

        .featured-posts__title {
            margin-bottom: 6rem !important;
            padding-left: 0;

            &:before {
                display: none;
            }

            h3 {
                position: relative;
                font-size: 2.6rem;
                line-height: 3.2rem;
                margin-bottom: 0;

                @media (min-width: $screen__s) {
                    font-size: 3rem;
                    line-height: 3.7rem;
                }
            }
        }
        .featured-posts{
            &__meta{
                margin-bottom: 1.3rem;
                margin-top: 1.4rem;
            }
            &__title{
                margin-bottom: 0rem !important;
            }
            &__wrapper{
                padding: 0px;
                ul{
                    padding: 0;
                    >li{
                        list-style-type: none;
                    }
                }
            }
        }
        .post-item-details{
            h3 {
                margin-top: 0.5rem;
                margin-bottom: 4rem;
    
                a {
                    color: $color-black;
                    @include lib-font-size(18);
                    line-height: 2.4rem;
                    font-family: $font-family__montserrat;
                    font-weight: 500;
    
                    &:hover, &:focus {
                        text-decoration: none;
                    }
                }
    
            }

        }
    }
}

.amblog-related-wrapper{
    margin-top: 0px !important;
    border: none;
    .amblog-title{
        border-bottom: none;  
    }
}



.amblog-container-list{
    .amblog-post-container{
        list-style: none;
        border: none;
        padding-left: 5px;
        padding-right: 5px;

        img{
            width: 100%;
            height: 371px;
        }

        .amblog-content{
            border-bottom: none;
            padding: 0;
            .amblog-title{
                font-size: 2.2rem;
                font-family: "Montserrat";
                line-height: 30px;
                font-weight: 500; 
                a{
                    color: $primary__color;
                    &:hover{
                        color: $primary__color;  
                    }
                }
            }
        }
    }
}
   



