//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background           : $color-yellow-light2 !default;
$message-global-note__border-color         : $color-yellow-light3 !default;
$message-global-note__color                : $text__color !default;

$message-global-note-link__color           : $link__color !default;
$message-global-note-link__color-hover     : $link__hover__color !default;
$message-global-note-link__color-active    : $link__active__color !default;

$message-global-caution__background        : $color-red9 !default;
$message-global-caution__border-color      : none !default;
$message-global-caution__color             : $color-white !default;

$message-global-caution-link__color        : $link__color !default;
$message-global-caution-link__color-hover  : $link__hover__color !default;
$message-global-caution-link__color-active : $link__active__color !default;

//  Header
$header__background-color                  : $primary__color !default;
$header-icons-color                        : $color-gray56 !default;
$header-icons-color-hover                  : $color-gray20 !default;

$addto-color                               : $text__color__muted !default;
$addto-hover-color                         : $primary__color !default;

$minicart-icons-color                      : $header-icons-color !default;
$minicart-icons-color-hover                : $header-icons-color-hover !default;

$button__shadow                            : inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop                : $indent__xl !default;

//  Footer
$footer__background-color                  : $primary__color !default;


body {
    @include lib-css(background-color, $page__background-color);
}

//
//  Header
//  ---------------------------------------------

.page-header {
    @include lib-css(background-color, $header__background-color);
    border-bottom: 1px solid $border-color__base;
    margin-bottom: $indent__base;
}

.header {
    &.content {
        @extend .abs-add-clearfix;
        padding-top: $indent__s;
        position: relative;
    }
}



.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, $footer__background-color);
    margin-top: auto;
}
.footer-shippinginfos{
    div{
        display: grid;
        grid-template-columns: 15% 85%;
        align-items: center;
        padding: $indent__m;
        color:$color-white;
        font-family: $font-family-name__base;
        font-weight: 600;
        border-bottom:1px solid $border-color__base;
        @include lib-font-size(18);
        &:before{
            @extend %fa-icon;
            @extend .fal;
            @include lib-font-size(26);
            color:$secondary__color;
            padding-right: $indent__m;
        }
        &:first-child{
            &::before{
                content: fa-content($fa-var-truck);
            }
        }
        &:nth-child(2){
            &::before{
                content: fa-content($fa-var-box-alt);
            }
        }
        &:last-child{
            border-bottom: 0;
            &::before{
                content: fa-content($fa-var-hand-holding-box);
            }
        }
    }
}
.footer {
    .footer{
        border-top: 1px solid $border-color__base;
        padding-top: 25px;
        &__row{
            text-align: center;
        }
        &__navLink{
            border-bottom: 1px solid $border-color__base;
            text-align: left;
            padding: $indent__s $indent__m $indent__m $indent__m;
            display: flex;
            justify-content: space-between;
            @include lib-transition;
            &:after{
                @extend %fa-icon;
                @extend .far;
                content: fa-content($fa-var-angle-down);
                @include lib-font-size(14);
                color:$secondary__color;
            }
            &--open{
                padding: $indent__s $indent__m 0 $indent__m;
                border-bottom: 0;
                &:after{
                    content: fa-content($fa-var-angle-up);
                }
            }
        }
        &__nav{
            text-align: left;
            display: none;
            @include lib-transition;
            &--open{
                display: block;
                padding-bottom: $indent__m;
                border-bottom: 1px solid $border-color__base;
            }
            li{
                a{
                    font-family: $font-family__base;
                    color:$color-gray;
                    font-size: $font-size__s;
                    padding-left: $indent__m;
                }
            }
        }
        &__social{
            margin-top:$indent__s;
            display: flex;
            justify-content: center;
            li{
                padding:0 $indent__s;
                i{
                    @include lib-font-size(16);
                    color:$color-white;
                    background:$secondary__color;
                    border:2px solid $secondary__color;
                    width: 30px;
                    height:  30px;
                    border-radius: 50%;
                    text-align: center;
                    line-height:  27px;
                    vertical-align: middle;
                    padding: 0 .3rem;
                    @include lib-transition(background-color,250ms, ease-in-out);

                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
        &__col{
            h4{
                color: $color-white;
                margin-bottom: $indent__s;
                &.headline{
                    &__social{
                        margin-top:$indent__m;
                    }
                }
            }
            a,.a{
                color: $color-white;
                &__phone{
                    font-size: $font-size__s;;
                }
            }
            &--desktop{
                .headline{
                    &__social{
                        display: none;
                    }
                }
                .footer{
                    &__social{
                        display: none;
                    }
                }
            }
        }
        &__newsletter{
            margin-top: $indent__xl;
            text-align: left;
            p{
                color: $color-gray;
                font-size: $font-size__s;
                text-align: left;
                width: 75%;
            }
            form{
                display: grid;
                grid-template-columns: 2fr 1fr;
                align-items: stretch;
                input{
                    background-color: transparent;
                    border:1px solid $color-gray;
                    font-size: $font-size__s;
                    border-right: 0;
                    border-radius: 0;
                    color: $color-white;

                    &::placeholder {
                        color: $color-gray;
                      }
                }
                button{
                    background-color: transparent;
                    border:1px solid $color-gray;
                    border-radius: 0;
                    color:$color-white;
                    font-family: $heading__font-family__base;
                    font-size: $font-size__s;
                    font-weight: 500;
                }
            }
        }
    }

    ul {
        @extend .abs-reset-list;
        > li {
            margin: 0;
        }
    }

    .links {
        > li {
            margin: 0 0 8px;
            a{
                color: $color-white;
            }
        }
    }

    .switcher-store {
        margin: 0 0 30px;
    }
}

.copyright,
.bugs {
    color: $color-white;
    box-sizing: border-box;
    display: block;
    padding: 10px;
    text-align: center;
}
.copyright{
    border-top: 1px solid $border-color__base;
    margin-top:$indent__l;
    padding-top: $indent__s;
    font-size: $font-size__xs;
    a{
        color:$color-white;
    }
    .payment{
      padding: $indent__l 0 $indent__s;
      .logo{
        &-visa{
            vertical-align: middle;
            width: 50px;
            height: 28px;
        }
        &-mastercard{
            vertical-align: middle;
            width: 60px;
            height: 45px;
        }
        &-paypal{
            vertical-align: middle;
            width: 55px;
            height: 60px;
        }
        &-american{
            vertical-align: middle;
            height: 33px;
        }
      }
    }

}
.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding            : 0,
                $_dropdown-list-item-padding          : 0,
                $_dropdown-toggle-icon-content        : '',
                $_dropdown-toggle-active-icon-content : '',
                $_icon-font-text-hide                 : true,
                $_icon-font-size                      : 22px,
                $_icon-font-line-height               : 22px,
                $_dropdown-list-min-width             : 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

.block.filter {
    margin-bottom: $indent__xl;

    .filter-title {
        display: block;
        margin-bottom: 40px;
        strong{
            font-size: 22px;
            font-family: $font-family__montserrat;
            font-weight: 400;
        }
    }
}

.block-subtitle {
    display: inline-block;
    margin-bottom: $indent__s;
}


.filter-options {

    .filter-options-title {
        @include lib-heading(h4);
        margin: 0 0 $indent__s;
        word-break: break-all;
        border-bottom: 1px solid $secondary__color;
        font-weight: 500;
        padding: 0 $indent__s  20px $indent__s;
    }

    .filter-options-content {
        margin: 0 0 $indent__m;

        .item {
            padding: 5px;
            margin-bottom: 3px;
            color:$primary__color;
            a{
                font-family: $heading__font-family__base;
                color:$primary__color;
                padding: $indent__s;
            }
        }
    }

}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .navigation ul {
        padding: 0 8px;
    }


    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel.wrapper {
            border-bottom: 1px solid $secondary__color;
        }

        .header.panel {
            @extend .abs-add-clearfix-desktop;
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        > .page-title-wrapper {
            margin-top: $indent__xl;
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

        //
        //  border-top
        //  ---------------------------------------------
        .--border-top{
            &:after{
                content:'';
                border-top:2px solid $secondary__color;
                width: 15%;
                height: 2px;
                display: block;
                margin-top:$indent__s;
            }
        }
    .footer-shippinginfos{
        display: flex;
        padding: $indent__m;
        div{
            border-bottom:0;
            border-right:1px solid $border-color__base;
            padding: $indent__s $indent__m;
            &:last-child{
                border-right: 0;
            }
        }
    }
    .footer {
        &.content {
            padding:0;
            max-width: 100%;
            .block {
                float: right;
            }
            .footer{
                &__row{
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    align-items: flex-start;
                    text-align: left;
                }
                &__navLink{
                    display: block;
                    border:0;
                    padding:0;
                    @include lib-font-size(18);
                    &:after{
                        content: '';
                        display: block;
                    }
                }
                &__nav{
                    display: block;
                    padding-bottom: $indent__m;
                    border-bottom:0px solid $border-color__base;
                    margin-top:0;
                    a{
                        padding-left: 0;
                    }
                }
                &__newsletter{
                    margin-top: 0;
                }
                &__col{
                    &--desktop{
                        .headline{
                            &__social{
                                display: block;
                            }
                        }
                        .footer{
                            &__social{
                                display: flex;
                                justify-content: flex-start;
                                li{
                                    padding:0 $indent__m 0 0;
                                }
                            }
                        }
                    }
                    &--mobile{
                        display: none;
                    }
                }
            }


            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }


    }

    .copyright {
        @extend .abs-add-clearfix-desktop;
    }
}

.page-wrapper{
    .page-bottom{
        max-width: 100%;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}

.action.primary {
    height: 50px;
}

// page listings

.page-products {

    .column.main{
        width: 75%;
    }
    .sidebar-main{
        width: 25%;

        //Bloc Promotion

        .block-static-block{
            .block{
                &__promotion{
                    background-image: url(../../images/bg-3-cat-interieur.jpg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    padding: 4rem 3rem;

                    img{
                        width: 100%;
                        @include max-screen($screen__m) {
                            width: 50%;
                        }
                    }

                    h3{
                        color: $color-white;
                        position: relative;
                        @include lib-font-size(22);
                        font-family: $font-family__montserrat;
                        line-height: 3rem;
                        margin-bottom: 3rem;

                        &:after{
                            content: "";
                            border-bottom: 2px solid $secondary__color;
                            width: 4rem;
                            position: absolute;
                            margin-left: 0.5rem;
                            bottom: 0.5rem;
                        }
                    }
                    a.action.primary{
                        padding: 1.5rem 3rem;
                        height: auto;
                        &:hover{
                            background: $color-white;
                            color: $color-black;
                        }
                    }
                }
            }

        }

        .filter-options-title{
            position: relative;
            cursor: pointer;

            &:before{
                content: "\f054";
                transform: rotate(90deg);
                right: 5px;
                font-size: 1em;
                color: $secondary__color;
                position: absolute;
                font-family: 'Font Awesome 5 Pro';
            }

        }
        .filter-options-title[aria-expanded="true"]{
            &:before{
                transform: rotate(270deg);
            }
        }
        .filter-options-content{
            .count{
                display: none;
            }
        }

    }

    .products-grid{
        .product-item{
            width: 33%;
        }
    }
}

.toolbar-products{
    .modes{
        display: none;
    }
    .toolbar-amount{
        color: $color-gray40;
    }
    #sorter{
        border: 2px solid $color-gray80;
        padding: 1rem 2.5rem;
        height: 4.5rem;
    }

}

.sorter-action{
    &:before{
        color: $secondary__color;
    }
}



/** Mobile  : MiniCart**/
@include max-screen(500px) {
    .minicart-wrapper {
        &.active{
            .block-minicart{
                right: 5%;
                .subtotal{
                    text-align: left;
                }
            }
        }
        .action.close:before{
            color: $color-black;
            font-size: 4rem;
        }
    }

    .page-title{
        margin-top: 5rem;
    }

}


.action.primary{
    &:focus-visible{
        background: $secondary__color;
        color: $color-white;
    }
}

button{
    &.action{
        &.captcha-reload{
            &:hover{
                border: 2px solid $secondary__color;
                color: $primary__color;
            }
        }
    }
}


.catalog-product-view {
    .page-title-wrapper.product {
        align-items: center;
        h1.page-title {
            margin-top: 0;
        }
        .product-addto-links {
            .action.towishlist {
                padding: 0;
                height: inherit;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .catalog-product-view {
        .page-title-wrapper.product {
            .product-social-links {
                margin-left: 15px;
            }
        }
    }
}
.page-print {
    #maincontent {
        .logo {
            img {
                width: 220px;
            }
        }
    }
}
