//
//  Header
//  _____________________________________________
.page-header{
    margin-bottom: 0;
}

.only-mobile {
    display:none !important;
    @media (max-width: $screen__m) {
        display:inline-block !important;
    }
}

.header__block-wrapper-menu-top,
.espace-pro,
.header.links,
.popup-wishlist,
.popup-login,
.minicart-wrapper
{
    height: 92px !important;
}


.header{
    &__shipping{
        @include lib-css(background, $secondary__color);
        padding: 17px 0;
        &__content{
            display: flex;
            justify-content: center;
            align-items: center;
            color: $color-white;
            font-weight: bold;
            @include lib-font-size(18);
            p{
                color:$color-white;
                @include lib-css(font-weight, $font-weight__bold);
                margin:0;
                margin-bottom: 0 !important;
            }
            &:after{
                content: "\f054";
                font-size: 1.8rem;
                padding: 0px 10px;
                color: $color-white ;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 300;
            }
        }
    }
    &.content{
        padding:0;
    }
    &__block-wrapper-menu-top{
        border-bottom: 1px solid $color-gray;

        .menutop-container{
            display: flex;
            justify-content: center;
            padding-left: 0;
            padding-right: 0;

            .links{
                display: flex;
                margin:0;
                padding: 0;
                li{
                    border-right: 1px solid $color-gray;
                    margin:0;
                    padding: $indent__base $indent__m;
                    a{
                        color:$color-white;
                        &:hover,&.active{
                            color:$secondary__color;
                        }
                    }
                    &.wishlist{
                        display: none !important;
                        border-right:0;
                        i{
                            font-size: $font-size__l;
                        }
                    }
                    &.lang{
                        border-left: 1px solid $color-gray;
                        padding: 30px 26.4px;
                        .menu__lang{
                            span{
                                text-transform: uppercase;
                                @include lib-font-size(18);
                                font-weight: bold;
                            }
                        }
                    }
                    &.welcome{
                        display: none;
                    }
                    &:nth-child(2){
                        display: none;
                        border-right:0;
                    }

                }
                .switcher-language{
                    display: none;
                    color:$color-white;
                    a{
                        color:$color-white;
                        &:hover{
                            color:$secondary__color;
                        }
                    }
                }
            }
            .espace-pro{
                padding: 3rem;
                font-weight: bold;
                border-left: 1px solid $color-gray;

                @media (max-width: $screen__l) {
                    border-left:none;
                }

                a{
                    color: $color-white;
                    @include lib-font-size(22);

                    &:hover{
                        color: $secondary__color;
                        text-decoration: none;

                    }
                }
            }
        }
    }
    .account{
        &::before{
            @extend %fa-icon;
            @extend .far;
            content: fa-content($fa-var-user);
            font-size: $font-size__l;
        }
    }
    .minicart-wrapper{
        margin:0;
        border-right: 1px solid $color-gray;
        .action.showcart{
            padding: 33px $indent__m;
            &:before{
                @extend %fa-icon;
                @extend .far;
                content: fa-content($fa-var-shopping-cart);
                font-size: $font-size__l;
            }
            &.active{
                &:before{
                    @extend %fa-icon;
                    @extend .far;
                    content: fa-content($fa-var-shopping-cart);
                    font-size: $font-size__l;
                    color:$secondary__color;
                }
            }
        }
        .counter.qty{
            margin: -4px 0 0 !important;
        }
    }

// ----- Search ------

    .block-search{
        width: 60%;
        padding: 0;

        @media (max-width: $screen__l) {
            width: 44%;
        }

        @media (max-width: $screen__m) {
            margin-top: 33px;
            width: 60%;
        }

        .form {
            &.minisearch{
                border-left: 1px solid $color-gray;
                height: 92px;
                @media (max-width: $screen__m) {
                    border-left: 0;
                }
            }
        }

        .block-content{
            @media (max-width: $screen__m) {
                margin-top: -3.2rem;
                margin-left: 0;

                form > .actions{
                    display: none;
                }
            }
        }

        .control{
            @media (max-width: $screen__m) {
                border-top: 0;
            }
        }

        .field.search{
            padding: 25px 0;
            .control{
                padding: 0;
            }

            input[type="text"]{
                background: $primary__color ;
                color: $color-white;
                padding-left: 30px;
                @include lib-font-size(18);

                @media (max-width: $screen__m) {
                    margin-top: -10px;
                    width: 90%;
                }

                &::placeholder{
                    color: $color-white;
                }
                &:focus{
                    border: none;
                    box-shadow: none;
                    padding: 20px 0 44px;
                    border-bottom: 4px solid $secondary__color;
                    border-radius: 0;
                    padding-left: 30px;

                    @media (max-width: $screen__m) {
                        padding: 0 30px 0 15px;
                        width: 90%;
                        margin-left: 15px;
                    }
                    @media (max-width: $screen__xs) {
                        width: 88%;
                    }
                }
            }

        }

        .action.search{
            &:before{
                content: "\f002";
                font-size: 20px;
                line-height: normal;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 400;
                color: $color-white ;
                top: 32px;
                position: absolute;
                right: 20px;

                @media (max-width: $screen__m) {
                    content: " ";
                }

            }
            &[disabled]{
                opacity: 1;
            }



        }
        .form.minisearch.amsearch-form-container .action.search:before{
            color: $secondary__color ;
        }

        .action.search.amasty-xsearch-hide:before{
            color: $secondary__color ;
        }


        .search .amsearch-loupe,
        .amsearch-close{
            display: none !important;
        }

        .amsearch-form-container{
            &.-opened .action.search{
                display: block !important;
            }
            &.-opened .input-text{
                padding-left: 0;
            }

            .search-autocomplete{
                top: 68px;
            }
            //contenu resultat
            #search_autocomplete{
                width: 1260px !important;
                left: -24%;
                //width: 1280px !important;
                //left: 0;

                @media (max-width: $screen__m) {
                    width: 400px !important;
                    left: auto;
                    left: 0;
                    top: 50px;
                }
                @media (max-width: 375px) {
                    width: 300px !important;
                }
            }

        }

        .amsearch-results{
            .amsearch-leftside{
                width: 30% !important;
                @media (max-width: $screen__m) {
                    width: 100% !important;
                }
            }
            .amsearch-products.-columns{
                width: 50% !important;
                @media (max-width: $screen__m) {
                    width: 100% !important;
                }
            }
            .amsearch-wrapper-content{
                .product-item{
                    width: 50%;
                    @media (max-width: $screen__m) {
                        width: 100% ;
                    }
                }
            }

            .amsearch-item{
                &:hover{
                    background-color: $color-white;
                }
            }

            .amsearch-more-results{
                .amsearch-link{
                    border-bottom: 1px solid $secondary__color;
                    color: $secondary__color;
                    &:hover{
                        border-bottom: none;
                        color: $primary__color;
                    }
                }
            }

        }




        .amsearch-item-container{
            font-family: $font-family__montserrat;

            .amasty-xsearch-block-header{
                padding: 0;
                @include lib-font-size(18);
            }
            .amsearch-item{
                .item-name{
                    @include lib-font-size(16);
                }
            }
            .amsearch-highlight{
                @include lib-font-size(18);
            }

            &.recent_searches{
                .amsearch-item{
                    border-radius: 8px;
                    padding: 8px 20px;

                    &:hover{
                        border-color: #e2e4ee;
                    }
                    a{
                        @include lib-font-size(16);

                        &:hover{
                            color: $secondary__color;
                        }
                    }
                }


            }
            &:not(.recent_searches) .amsearch-highlight,
            &.page a.item-name{
                color: $secondary__color ;
            }


        }


        .amsearch-wrapper-content{
            .product-item-link{
                font-weight: bold;
                margin: 0
            }
            .product-item-category{
                @include lib-font-size(12);
                color: $color-gray;
                margin-bottom: 1.2rem;
                font-weight: bold;
            }
            .product-item-shortDescription{
                @include lib-font-size(14);
                color: $primary__color;
                font-weight: lighter;
                margin-bottom: 1rem;
            }
            .price-box {
                display: flex;
                .special-price{
                    padding-right: 1.5rem;
                }
            }
        }


        .amsearch-autocomplete-image .product-image-photo{
            border: 0;
        }

        .amsearch-products {
            .amsearch-highlight {
                background-color: $secondary__color;
            }
            .amasty-xsearch-block-header{
                @include lib-font-size(18);
                font-family: $font-family__montserrat;
            }
        }
    }

    &__menu{
       position: relative;
        background-color: $primary__color;
        padding: $indent__s 0;
        .nav-toggle{
            right: $indent__m;
            top:5px;
            left:auto;
            &:before{
                @include lib-font-size(36);
            }
        }
        .logo{
            padding-left: 0;
            @include max-screen($screen__m) {
                padding-left: 15px;
            }

            img {
                &.logo-desktop {
                    max-width: 50%;
                    @include min-screen($screen__l) {
                        max-width: 85%;
                    }
                }
            }
        }
    }
}
.nav-open{
    header{
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 2147483002;
    }
}
.page-header{
    .switcher{
        .options{
            ul.dropdown{
                background-color: transparent;
                min-width:inherit;
                .action{
                    text-transform: uppercase;
                    &.toggle{
                    font-weight: normal;
                    font-size: $font-size__s;
                    text-transform: uppercase;
                        &:after{
                            content: none;
                        }
                    }
                }
                a{
                    font-weight: normal;
                    font-size: $font-size__s;
                    text-transform: uppercase;
                    padding:0;
                }
            }
        }
    }
}
//
//  Desktop
//  _____________________________________________


@include min-screen($screen__l) {
    .header{
        &.content{
            position: initial;
        }
        &__block-wrapper-menu-top{
            .menutop-container{
                .links{
                    display: flex;
                    margin:0;
                    padding-left: 0;
                    padding-right: 0;
                    li{
                        &.wishlist{
                            display: block;
                            border-right: 1px solid $color-gray;
                            order:3;
                        }
                        &:nth-child(2){
                            display: block;
                            border-right: 1px solid $color-gray;
                            order:1;
                        }
                        &:last-child{
                            order:4;
                        }
                        .espacepro{
                            text-transform: uppercase;
                            font-weight: bold;
                        }
                    }
                    .switcher{
                        display: flex;
                        align-items: center;
                        margin-right: 0;
                        height: 100%;
                        border-left: 1px solid $color-gray;
                        border-right: 1px solid $color-gray;
                        order:2;
                        padding: $indent__base $indent__m;
                        text-transform: uppercase;
                        font-weight: bold;

                        ul.dropdown{
                            background-color: $color-white;
                            li{
                                a{
                                    padding:$indent__s;
                                    color:$color-black;
                                }
                            }
                        }
                    }
                }
            }
        }


        &__menu-bottom{
            &.fixed{
                position: fixed;
                top: -5rem;
                width: 100%;
                max-width: 100%;
                height: 100px;
                z-index: 999;
                right: 0;
                left: 0;
                background-color: $primary__color;

                .header__menu{
                    height: 60px;
                    .logo{
                        //padding-left: 40%;
                         img{
                             height: 45px;
                             padding: 7px 0 3px;
                         }
                    }
                }
                .nav-sections{
                    height: 46px;
                }
                .navigation{
                    li{
                        &.active{
                            margin: -3px 0 0 0 ;
                        }
                    }
                }
            }
        }

        &__block-wrapper-menu-bottom{
            display: grid;
            grid-template-columns: 1fr 3fr;
            align-items: center;
            padding-top: 4rem;
            ul{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

        }

    }

    .nav-sections{
        .espacepro{
            &--mobile{
                display: none;
            }
        }
        .switcher{
            display: none;
        }
    }
}

.navigation{
    .custom-menu-mobile{
        display: none;
    }

    li{
        .link-b2b{
            display: none;
        }
    }
}

//-------------- Mobile -----

@include max-screen($screen__m) {

    .page-header{
        border: 0;
    }

    .header{
        .minicart-wrapper{
            order: 1;
            border-left: 0;
            border-right: 0;
        }
        .menutop-container .links{
            order: 2;
            padding: 0;

            li{
                padding: 30px 26.4px;
            }
        }
        .popup-minisearch{
            order: 3;
        }
        .nav-toggle{
            &:before{
                color: $color-white;
            }
        }
        .espace-pro,
        .link.lang{
            display: none;
        }

        .navigation li.active>a:not(.ui-state-active),
        .navigation li.has-active>a:not(.ui-state-active){
            border-width: 0 0 4px 0;
            padding: 8px 15px 8px 5px;
            margin: 0 20px;
        }
    }

    .nav-before-open{
        .nav-toggle{
            &:before{
                color: $color-white;
                content: "\f00d";
                font-family: 'Font Awesome 5 Pro';
                font-size: 4rem;
                font-weight: 100;
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
            }
        }
    }

    .navigation{
        .custom-menu-mobile{
            display: block;
            position: fixed;
            bottom: 0;
            z-index: 999;
            background-color: $primary__color;
            border-top: 1px solid $color-white;

            > ul{
                display: grid !important;
                grid-template-columns: 1fr 1fr;
                align-items: center;

                .link.wishlist{
                    display: none;
                }

                .switcher-dropdown {
                    .link.wishlist{
                        display: none;
                    }
                    .switcher-option{
                        text-align: left;
                    }
                }
                .switcher.language{
                    text-align: center;
                }
                .link.wishlist{
                    text-align: end;
                }
                .link.lang{
                    display: block;
                    text-align: center;
                }
                .espace-pro-mobile{
                    .espace-pro{
                        display: block !important;
                        height: initial !important;
                        a{
                            font-size: 20px;
                            color: $color-white !important;
                            padding: 0 2rem;
                        }
                    }
                }

            }
        }
    }


}
