.pages {
    @include lib-pager();
    .item{
        &.current{
            strong{
                &.page{
                    border-radius: 0.5rem;
                    padding: 0 1.5rem;
                }
            }
        }
        a.page{
            color: $primary__color ;
        }
    }
    .action {
        width: 3.4rem;

        &.next{
            &:before{
                color: $secondary__color;
            }
        }
    }
}


// Listing, Pagination
.toolbar.toolbar-products{
    
    @include lib-font-size(16);

    .action.sorter-action{

        &:before{
            font-family: 'Font Awesome 5 Pro';
            font-weight: 100;
            padding: 0.5rem 1rem;
        }
    
        &.sort-desc{
            &:before{
                content: "\f063";
            }
        }
    
        &.sort-asc{
            &:before{
                content: "\f062";
            }
        }
    }
}

// btn go top

#btnGotoTop {
    display: none;
    position: fixed;
    bottom: 2rem;
    right: 10%;
    z-index: 99;
    border: none;
    outline: none;
    background-color: $color-white;
    border: 1px solid $secondary__color;
    cursor: pointer;
    padding: 1.2rem;
    @include lib-font-size(25);
    height: 5rem;
    width: 5rem;
    border-radius: 0.5rem;
    color: $secondary__color;

    &:hover {
        background-color: $secondary__color;
        color: $color-white;
      }
    @include max-screen($screen__m) {
        right: initial;
        left: 1rem;
    }
  }


////////@at-root --- //////

// en haut
.toolbar-products{
    
    .toolbar-sorter{
       .sorter-label{
           display: none;
       } 
    }

    .limiter{
        display: block !important;
        position: absolute;
        right: 22%;
        @include max-screen($screen__xl) {
            right: 27%;
        }
        @include max-screen(950px) {
            right: 35%;
        }
        .limiter-text{
            font-weight: 600;
        }
        .limiter-options{
            font-weight: 600;
            margin: 0 11px 0 0 !important;
            padding: 0 15px 0 0 !important;
        }
        /*select{
            &:before{
                font-family: 'Font Awesome 5 Pro';
                font-weight: 100;
                padding: 0.5rem 1rem;
                content: "\f063";
            }
        }*/
    }

    .toolbar-sorter.sorter{
        padding: 0;
        .sorter-options{
            padding: 1rem 4.5rem 1rem 2.5rem !important;
        }
    }

}


// en bas
.products.wrapper ~ .toolbar{
    border-top: 1px solid $color-gray60;
    padding-top: 25px;
    .toolbar-amount {
        display: block !important;
        text-align: center;
        position: absolute;
        left: 55%;
    }
    .limiter{
        display: block !important;
        position: initial;

        .limiter-text{
            color: $color-gray;
        }
        .limiter-options{
            color: $color-gray;
        }
        select.limiter-options{
            background-image: none;   
        }
    }

    .pages{
        .action.next:before,
        .action.previous:before{
            color: $secondary__color;
        }
    }

}

@include max-screen($screen__m) {

    .toolbar-products{
        .field.limiter{
            display: none !important;
        }
        .toolbar-amount{
            display: none;
        }
        .toolbar-sorter.sorter{
            top: 20px !important;
        }
    }




    .products.wrapper ~ .toolbar{
        .toolbar-amount{
            display: none !important;
        }
        .field.limiter{
            display: none !important;
        }
    }


}



 
